import React, {useLayoutEffect, useMemo, useRef} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {HSLToRGB} from "../../../utils/getColor";
import s from './styles.module.css'
import SwitchBox from "./switchBox";

const ChartTrade = ({dataTradersChart}: any) => {
        let chartRef = useRef<any>(null);

        const color: any = {}

        for (let key in dataTradersChart?.traders) {
            const index = Object.keys(dataTradersChart?.traders)?.findIndex((f) => f === key) + 1
            color[key] = HSLToRGB(index * 96)
        }

        const data = useMemo(() => {
            return dataTradersChart['graph'].map((el: any, i: any) => {
                const dateandtime: any = el.d ? el.d.split(' ') : null
                return ({
                    ...el,
                    year: i,
                    d: dateandtime === null ? '' : dateandtime[0]
                })
            })

        }, [dataTradersChart['graph']])

        useLayoutEffect(() => {
                let root = am5.Root.new(`chartdiv-trade`);

                root?._logo?.dispose()
                root.setThemes([
                    am5themes_Animated.new(root)
                ]);

                const chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                }));

                // @ts-ignore
                chartRef!.current = chart;

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "none",
                }));


                cursor.lineY.set("visible", false);
                cursor.lineX.set("stroke", am5.color(0xffffff));
                cursor.lineX.set("strokeWidth", 2);

                const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "year",
                    renderer: am5xy.AxisRendererX.new(root, {}),
                }));

                xAxis.get("renderer").labels.template.adapters.add("text", () => {
                    return '{d}'
                });

                xAxis.get("renderer").labels.template.setAll({
                    fill: am5.color(0x56CCF2),
                    fontSize: "10px",
                    rotation: -40,
                });

                xAxis.data.setAll(data);

                const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    numberFormat: "#'%'",

                    maxPrecision: 0,
                    renderer: am5xy.AxisRendererY.new(root, {
                        minGridDistance: 20,
                        opposite: true,

                    }),

                }));

                yAxis.get("renderer").labels.template.setAll({
                    fill: am5.color(0x56CCF2),
                    fontSize: "10px",
                    rotation: 20,
                });

                xAxis.get("renderer").grid.template.setAll({
                    stroke: am5.color(0xffffff),
                })

                yAxis.get("renderer").grid.template.setAll({
                    stroke: am5.color(0xffffff),
                })

                function createSeries(name: string, field: string, color: any, tooltipValue: any) {

                    const series = chart.series.push(am5xy.LineSeries.new(root, {
                        stroke: color,
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: field,
                        categoryXField: "year",
                    }));

                    let tooltip = am5.Tooltip.new(root, {
                        getFillFromSprite: false,
                        labelText: tooltipValue,
                        pointerOrientation: 'horizontal',
                    });
                    tooltip.label.setAll({
                        fontSize: '12px'
                    });

                    tooltip?.get("background")?.setAll({
                        strokeOpacity: 1,
                        stroke: am5.color(0x3C3C3C),
                        fillOpacity: 1,
                        fill: am5.color(0x1F1F1F),
                    });
                    series.data.setAll(data);
                    series.set("tooltip", tooltip);
                    series.appear(1000);
                }

                for (let i = 0; i < Object.keys(dataTradersChart.traders).length; i++) {
                    createSeries(Object.keys(dataTradersChart.traders)[i], Object.keys(dataTradersChart.traders)[i], am5.color(Number(`0x${color[Object.keys(dataTradersChart.traders)[i]]}`)), `[#${color[Object.keys(dataTradersChart.traders)[i]]}]{valueY}%`);
                }

                chart.appear(1000, 100);

                return () => {
                    root.dispose();
                };
            }, [data]
        );


        const output = [];
        for (let key in dataTradersChart.traders) {
            output.push({...dataTradersChart?.traders[key], key: key});
        }
        output.sort(function (a, b) {
            return (b?.gain - a?.gain);
        });


        return (
            <div>
                <div id={'chartdiv-trade'} style={{width: "100%", minHeight: "420px"}}></div>

                <div className={s.action_box}>
                    {output.map((key, index) => {
                        const arrayIndex = Object.keys(dataTradersChart.traders)
                        const findIndex = arrayIndex.findIndex(a => a === key.key)

                        return <SwitchBox index={findIndex} chartRef={chartRef}
                                          key={index} keyO={key}
                                          color={color}
                                          dataTradersChart={dataTradersChart}/>
                    })}
                </div>
            </div>
        );
    }
;

export default ChartTrade;
