import React, {useEffect} from 'react';
import s from "../stockHeader/styles.module.css";
import {calculateDaysBetween, getDayLabel} from "../stockHeader";
import {useTimer} from "react-timer-hook";
import moment from "moment";

const DateWork = ({valid_to}: any) => {
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({expiryTimestamp: new Date(moment(valid_to).format()), autoStart: false})

    useEffect(() => {
        if (valid_to && calculateDaysBetween(valid_to, 'hours') < 24) {
            restart(new Date(moment(valid_to).format()), true)
        }
    }, [valid_to])
    console.log(isRunning)
    return (
        <h3 className={s.item_r_t_title_s}>
            {valid_to ?
                (isRunning ?
                    `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}` :
                    `${calculateDaysBetween(valid_to)} ${getDayLabel(calculateDaysBetween(valid_to))}`) :
                '-'}
        </h3>
    );
};

export default DateWork;