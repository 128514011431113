import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import CustomButton from "../../../../common/button";
import {spaceBtwHundreds} from "../../../../entities/utils/spaceBetweenSum";

const TradeTop = ({stats, setOpenPaymentBalance}: any) => {
    console.log(stats)
    return (
        <div className={s.main}>
            <div className={s.trade_item}>
                <div className={s.trade_item_left}>
                    <p className={s.trade_item_left_title}>Средства</p>

                    <p className={s.trade_item_left_sub_title}>{`${spaceBtwHundreds(+stats?.balance?.value || 0)}₽`}
                        <span style={{
                            color: +stats?.balance?.gain?.value >= 0 ? '#6FCF97' : '#f65768'
                        }}>&nbsp;{spaceBtwHundreds(+stats?.balance?.gain?.value || 0)}</span></p>
                </div>
                <div className={s.trade_item_right}>
                    Рублевый
                </div>
            </div>
            <div className={s.trade_item}>
                <div className={s.trade_item_left}>
                    <p className={s.trade_item_left_title}>Коммисионный</p>

                    <p className={s.trade_item_left_sub_title}>{`${spaceBtwHundreds(+stats?.balance?.commission?.value || 0)}₽`}</p>
                </div>
                <CustomButton onClick={setOpenPaymentBalance}>
                    Пополнить
                </CustomButton>
            </div>
            <div className={s.trade_item}>
                <div className={s.trade_item_left}>
                    <p className={s.trade_item_left_title}>Кредитное плечо</p>

                    <p className={s.trade_item_left_sub_title}>{`${spaceBtwHundreds(+stats?.balance?.credit?.value || 0)}₽`}</p>
                </div>
            </div>
        </div>
    );
};

export default TradeTop;