import React, {FC, useEffect, useState} from 'react';
import {Chip, FormControl, MenuItem, Select, Stack, useMediaQuery} from "@mui/material";
import IconArrow from "../assets/images/icons/iconArrow";

interface T {
    title?: string;
    changeTime?: any;
    select?: boolean;
    selectTitle?: string;
    defaultValue?: string;
    number?: string;
    isTrader?: boolean;
    currentTime?: boolean;
    icon?: 'bad' | 'good';
}

const HeaderChart: FC<T> = ({
                                currentTime,
                                isTrader,
                                title,
                                changeTime,
                                icon,
                            }) => {
    const [changeTimeBtn, setChangeTimeBtn] = useState((isTrader || currentTime) ? 2 : 1)
    const [monthArr, _] = useState(['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'])
    const [currentMonth, setCurrentMonth] = useState('')
    const [lastMonth, setLastMonth] = useState('')

    const mediaQuery = useMediaQuery('(min-width:980px)');


    useEffect(() => {
        const newDate = new Date();
        const month = newDate.toLocaleString('default', {month: 'long'});
        setCurrentMonth(month);
        setLastMonth(monthArr[newDate.getMonth() - 1]);
    }, [])
    return (
        <Stack
            className="h2 white-90"
            mb={7}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={4}
            sx={{pl: 12, pr: 12}}
        >
            <Stack className="h2 white-90" direction="row" alignItems="center" spacing={4}>
                <span className={'title_chart_dashboard'}>{title}</span>
                {
                    icon === 'bad'
                        ?
                        <Stack sx={{transform: `rotate(-180deg)`}}>
                            <IconArrow color="red"/>
                        </Stack>
                        :
                        icon === 'good' ?
                            <Stack>
                                <IconArrow color="green"/>
                            </Stack>
                            : null
                }

            </Stack>
            {
                (changeTime && mediaQuery) &&
                <Stack direction="row" alignItems="center" spacing={4}>
                    {!isTrader && <Chip
                        onClick={() => {
                            setChangeTimeBtn(1)
                            changeTime('all')
                        }}
                        label="Все время"
                        variant={changeTimeBtn === 1 ? "filled" : "outlined"}
                        color="neutral"
                        sx={{pl: 0, pr: 0}}/>}
                    <Chip
                        onClick={() => {
                            setChangeTimeBtn(2)
                            changeTime('current-month')
                        }}
                        label={currentMonth}
                        variant={changeTimeBtn === 2 ? "filled" : "outlined"}
                        color="neutral"
                        sx={{pl: 0, pr: 0}}/>
                    <Chip
                        onClick={() => {
                            setChangeTimeBtn(3)
                            changeTime('last-month')
                        }}
                        label={lastMonth}
                        variant={changeTimeBtn === 3 ? "filled" : "outlined"}
                        color="neutral"
                        sx={{pl: 0, pr: 0}}/>
                </Stack>
            }

            {(changeTime && !mediaQuery) &&
                <FormControl sx={{
                    maxWidth: '100px'
                }}>
                    <Select
                        value={changeTimeBtn}
                        inputProps={{'aria-label': 'Without label'}}
                        onChange={(e: any) => {
                            setChangeTimeBtn(e?.target?.value)
                            changeTime(
                                (e?.target?.value === 1 && 'all') ||
                                (e?.target?.value === 2 && 'current-month') ||
                                (e?.target?.value === 3 && 'last-month')
                            )
                        }}
                    >
                        <MenuItem value={1}>Все время</MenuItem>
                        <MenuItem value={2}>{currentMonth}</MenuItem>
                        <MenuItem value={3}>{lastMonth}</MenuItem>
                    </Select>
                </FormControl>
            }
        </Stack>
    );
};

export default HeaderChart;
