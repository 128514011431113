export const customMuiSlider = {
    styleOverrides: {
        root: {
            marginBottom: 0,
            padding: 0,
        },
        markLabel: {
            top: '-25px !important',
            color: '#828282'
        },
    }
} as object
