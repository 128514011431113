import React, {FC, useEffect, useState} from 'react';
import Referrals from "../entities/components/referrals";
import {Box, Button, Pagination, Skeleton, Stack, useMediaQuery} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useGetReferralLineUsersQuery, useGetReferralUsersQuery} from "../store/API/referalApi";

interface IType {
    children?: any
}

const ReferralsList: FC<IType> = ({children}) => {
    const [idLine, setIdLine] = useState(1)
    const [accountPage, setAccountPPage] = useState(1);

    const {data, error, isLoading, refetch: refetch2} = useGetReferralUsersQuery(accountPage, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: isDataReferralLine,
        error: isErrorReferralLine,
        isLoading: isLoadingReferralLine,
        refetch
    } = useGetReferralLineUsersQuery(`${idLine}`, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [_, setAlignment] = useState<string | null>('left');
    const [referral, setReferral] = useState([]);
    const [activeBtn, setActiveBtn] = useState(1);

    const handleChangeAccountPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setAccountPPage(value);
    };
    useEffect(() => {
        if (activeBtn === 1 && !isLoading && !error) {
            setReferral(data?.data)
        } else if (!isLoadingReferralLine) {
            setReferral(isDataReferralLine?.data)
        }
    }, [data, activeBtn, isDataReferralLine])

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        setAlignment(newAlignment);
    };
    return (
        <Paper>
            <Stack direction={mediaQuery ? 'row' : "column"} alignItems={mediaQuery ? 'center' : "flex-start"}
                   justifyContent="space-between" flexWrap="wrap" sx={{mb: 7}}>
                <Stack className="h2 white-90" sx={{mb: 7}}>Приглашенные люди</Stack>
                <Stack direction={mediaQuery ? "row" : "column"} alignItems={mediaQuery ? "center" : "flex-start"}
                       justifyContent="space-between" flexWrap="wrap" spacing={2}>
                    <Button
                        onClick={() => {
                            refetch2()
                            setReferral(data?.data)
                            setActiveBtn(1)
                        }}
                        color="neutral"
                        sx={{color: '#BDBDBD'}}>Все приглашенные</Button>
                    <Button
                        onClick={() => {
                            refetch()
                            setIdLine(1)
                            setActiveBtn(2)
                            if (!isLoadingReferralLine && isDataReferralLine) {
                                setReferral(isDataReferralLine.data)
                            }

                        }}
                        color="neutral"
                        sx={{color: '#BDBDBD'}}>Первая линия</Button>
                </Stack>
            </Stack>
            <Stack spacing={5}>
                {
                    isLoading ?
                        <Skeleton variant="rectangular" width={`100%`} height={63}/>
                        : (data && isDataReferralLine) ?
                            referral?.map((item: any, index) =>
                                <Referrals
                                    key={index}
                                    avatar={item.telegram.avatar_url}
                                    name={item.telegram.first_name + ' ' + item.telegram.last_name}
                                    link={item.telegram.username}
                                    lastPayment={item.last_payment_in}
                                    registrationDate={item.reg_date}/>
                            )
                            : <Stack>Ошибка при загрузке данных</Stack>
                }
            </Stack>
            <div style={{
                width: '100%',
                display: 'flex',
                padding: '20px 0',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}>
                {
                    data?.meta?.pagination?.total_pages > 1 &&
                    <Pagination
                        page={accountPage}
                        onChange={handleChangeAccountPage}
                        color="primary"
                        count={data?.meta?.pagination?.total_pages}
                        variant="outlined"
                        shape="rounded"
                        sx={{maxWidth: 'max-content'}}
                    />

                }
            </div>
        </Paper>
    );
};

export default ReferralsList;
