import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {headersAccept, headersAuthorization, headersContent} from "./APIToken";


export const referralApi = createApi({
    reducerPath: 'referralApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}/referral`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
            headers.set("Authorization", headersAuthorization)
        },
    }),
    tagTypes: ['Referral'],
    endpoints: (build) => ({
        getReferralData: build.query({
            query: () => ({
                url: '/main'
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Referral'],
        }),
        getReferralUsers: build.query({
            query: (page) => ({
                url: '/users',
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Referral'],
        }),
        getReferralLineUsers: build.query({
            query: (id) => ({
                url: `/users/line/${id}`
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Referral'],
        }),


    })
});

export const {useGetReferralDataQuery, useGetReferralUsersQuery, useGetReferralLineUsersQuery} = referralApi;

export const {getReferralData} = referralApi.endpoints;
