import React, {FC} from 'react';
import HeaderChart from "../../../shared/components/headerChart";
import Paper from "@mui/material/Paper";

interface T {
    children?: any;
    title?: string;
    changeTime?: any;
    select?: boolean;
    selectTitle?: string;
    defaultValue?: string;
    number?: string;
    isTrader?: boolean;
    currentTime?: boolean;
    icon?: 'bad' | 'good';
}

const Chart: FC<T> = ({
                          currentTime,
                          children,
                          title,
                          isTrader = false,
                          changeTime,
                          select,
                          selectTitle,
                          defaultValue,
                          number,
                          icon
                      }) => {
    return (
        <Paper sx={{
            flexGrow: 1, "@media (min-width:980px)": {
                padding: `14px 4px`,
                minHeight: 300
            }
        }}>
            <HeaderChart isTrader={isTrader} currentTime={currentTime} title={title} changeTime={changeTime}/>
            {children}
        </Paper>
    );
};

export default Chart;
