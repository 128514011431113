import React from 'react';
import s from './styles.module.css'
import {Step, Title} from "../step";
import step3_1 from '../assets/step3_1.png'
import step3_1_s from '../assets/step3_1_s.png'
import step3_2 from '../assets/step3_2.png'
import step4_1 from '../assets/step4_1.png'
import step4_2 from '../assets/step4_2.png'
import step4_3 from '../assets/step4_3.png'
import step5_1 from '../assets/step5_1.png'
import step5_2 from '../assets/step5_2.png'
import step5_3 from '../assets/step5_3.png'
import step6_1 from '../assets/step6_1.png'
import {useMediaQuery} from "@mui/material";

const BoxBorder = ({children, style, type = 1}: any) => {
    return <div style={style}
                className={
                    (type === 1 && s.box_border) ||
                    (type === 2 && s.box_border_v2) ||
                    (type === 3 && s.box_border_v3)
                }>{children}</div>
}

const Step2 = () => {
    const mediaQuery = useMediaQuery('(min-width:769px)');

    return (
        <div>
            <Step step={3}/>

            <div className={s.step_3_title_box}>
                <Title title={'Создание реального счета'}/>
                <p>Для того чтобы открыть реальный счет необходимо пройти простую верификацию. Она нужна так как биржа
                    это тот же банк в котором вы открываете счет и она отвечает за ваши средства.</p>
            </div>

            <div>
                <BoxBorder style={{marginTop: '14px'}}>
                    <>
                        <p className={s.title_gray_white}>Перейдите на блок <span>"Панель Управления"</span></p>
                        <a className={s.link} href="https://account5.forex4you.org/ru/dashboard"
                           target={'_blank'}>https://account5.forex4you.org/ru/dashboard</a>
                    </>
                </BoxBorder>
                <BoxBorder style={{marginTop: '8px'}}>
                    <>
                        <p className={s.title_gray_white}>Нажмите кнопку <span>"Пройдите процесс верификации"</span></p>
                    </>
                </BoxBorder>

                <div className={s.step_3_1_img_box}>
                    <img src={mediaQuery ? step3_1 : step3_1_s} alt={'image'}/>
                </div>

                <BoxBorder style={{marginTop: '8px'}}>
                    <>
                        <p className={s.title_gray_white}>Перед вами откроется окно в котором <span>необходимо заполнить все поля.</span>
                        </p>
                    </>
                </BoxBorder>

                <div className={s.step_3_grid_box}>
                    <div className={s.step_3_grid_box_left}>
                        <BoxBorder type={2}>
                            <>
                                <h3 className={s.title_gray}>ВАЖНО!</h3>
                                <p className={s.important_sub_title}>Все поля заполняются только латинскими буквами.</p>
                            </>
                        </BoxBorder>
                        <BoxBorder type={2}>
                            <>
                                <h3 className={s.title_gray}>ВАЖНО!</h3>
                                <p className={s.important_sub_title}>Адрес указывается тот что указан в прописке в Вашем
                                    паспорте</p>
                            </>
                        </BoxBorder>
                        <BoxBorder>
                            <>
                                <p className={s.title_gray}>После заполнения нажмите на кнопку "Загрузить документы"</p>
                            </>
                        </BoxBorder>
                    </div>

                    <div className={s.step_3_grid_box_right}>
                        <img src={step3_2} alt="image"/>
                    </div>
                </div>
            </div>

            <Step step={4} style={{marginTop: '14px'}}/>

            <Title title={'Загрузите скан двух страниц своего паспорта'} style={{marginTop: '14px'}}/>

            <BoxBorder type={2} style={{marginTop: '8px'}}>
                <>
                    <h3 className={s.title_gray}>ВАЖНО!</h3>
                    <p className={s.important_sub_title}>Паспорт должен быть виден полностью и не должно быть обрезанных
                        углов или страниц. Такие заявки отклоняют!</p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step4_1} alt="step4_1"/>
            </div>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>После загрузки разворота страницы с вашим фото <span>прокрутите страницу чуть
                        ниже и загрузите любой документ подтверждающий адрес проживания</span> (можно разворот паспорта
                        страниц
                        с пропиской) который вы указали выше. Если вы указали не соответствующий адрес, обязательно
                        укажите тот что указан в прописке, иначе заявку отклонят.</p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step4_2} alt="step4_2"/>
            </div>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={`${s.title_gray_white} ${s.title_gray_yellow}`}>После этого шага в течении 1-2 дня
                        (обычно занимает пару часов) вам придёт на почту подтверждение о пройденной верификации,
                        <span> отвечать на него не нужно</span></p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step4_3} alt="step4_3"/>
            </div>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_green}>Теперь вы можете пополнить свой счет реальными деньгами.</p>
                </>
            </BoxBorder>

            <Step step={5} style={{marginTop: '8px'}}/>

            <Title title={'Настройки пополнения'} style={{marginTop: '8px'}}/>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>Перейдите на блок <span>"Панель управления"</span></p>
                    <a className={s.link} href="https://account5.forex4you.org/ru/dashboard"
                       target={'_blank'}>https://account5.forex4you.org/ru/dashboard</a>
                </>
            </BoxBorder>
            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>Нажмите на кнопку <span>"Создать счет"</span></p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step5_1} alt="step5_1"/>
            </div>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>В появившемся окне выбираем <span>Cent Pro</span></p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step5_2} alt="step5_2"/>
            </div>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>Далее выбираем следующие параметры и нажимаем на кнопку <span>"Создать счет"</span>
                    </p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step5_3} alt="step5_3"/>
            </div>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>В появившемся окне появятся <span>данные от вашего торгового счета.</span>
                    </p>
                </>
            </BoxBorder>

            <BoxBorder type={2} style={{marginTop: '8px'}}>
                <>
                    <h3 className={s.title_gray}>ВАЖНО!</h3>
                    <p className={s.important_sub_title}>Обязательно сохраните их в заметки. Так же эти данные будут
                        продублированы на вашу почту указанную при регистрации.</p>
                </>
            </BoxBorder>

            <Step step={6} style={{marginTop: '8px'}}/>

            <Title title={'Пополнение счета'} style={{marginTop: '14px'}}/>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>Перейдите на блок <span>"Панель управления"</span></p>
                    <a className={s.link} href="https://account5.forex4you.org/ru/dashboard"
                       target={'_blank'}>https://account5.forex4you.org/ru/dashboard</a>
                </>
            </BoxBorder>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>
                        <span>Найдите свой счет</span>
                        <br/>
                        (в письме указан номер вашего счета либо ориентируйтесь по данным указанным в шаге создания
                        счета Cent Pro, Swap free, 1:500)</p>
                </>
            </BoxBorder>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>Нажмите кнопку <span>Пополнить.</span></p>
                </>
            </BoxBorder>

            <BoxBorder style={{marginTop: '8px'}}>
                <>
                    <p className={s.title_gray_white}>Выберите любой удобный способ для <span>пополнения торгового счета.</span>
                    </p>
                </>
            </BoxBorder>

            <BoxBorder type={3} style={{marginTop: '8px'}}>
                <>
                    <p className={`${s.important_sub_title} ${s.important_sub_title_green}`}>Можно пополнять как
                        банковской картой, так и использовать платёжные системы.</p>
                </>
            </BoxBorder>

            <div className={s.img_box_step4}>
                <img src={step6_1} alt="step6_1"/>
            </div>

            <BoxBorder style={{marginTop: '14px', padding: '14px 14px'}}>
                <>
                    <p style={{fontWeight: 600}}
                       className={`${s.important_sub_title} ${s.important_sub_title_green}`}>Как пополнить счет выгоднее
                        на 2-5% чем у форекс брокера</p>

                    <p className={s.step_box_6_sub_title}>Для этого нужно зарегистрироваться на криптобирже Binance или
                        OkexПо опыту на Okex чаще выгоднее курс чем у Binance.</p>


                    <div style={{marginTop: '8px'}} className={s.btn_link}><a href="https://okx.cab/join/94779268"
                                                                              target={'_blank'}>Регистрация Okex</a>
                    </div>
                    <div style={{marginTop: '8px'}} className={s.btn_link}><a
                        href="https://www.binance.com/ru/activity/referral-entry/CPA/incremental?ref=CPA_00WN5EK04R"
                        target={'_blank'}>Регистрация Binance</a></div>
                </>
            </BoxBorder>

            <Title title={'И далее делаете все по инструкции из видео ниже'} style={{marginTop: '14px'}}/>

            <iframe style={{marginTop: '8px'}} width="100%" height={mediaQuery ? "432" : "211"}
                    src="https://www.youtube.com/embed/Kk1nsDuttwE"
                    title="Пополнение счета форекс через крипту" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
        </div>
    );
};

export default Step2;
