import React, {FC} from 'react';

interface T {
    active?: boolean
}

const IconSupport: FC<T> = ({active}) => {
    return (
        <>
            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.95214 18H4.18694V15.3H6.95214V18ZM6.95214 13.5H4.18694V13.4937C4.18694 12.0087 4.18694 10.7262 4.80634 9.82802C5.28823 9.23168 5.88784 8.73589 6.56962 8.37003C6.87748 8.17743 7.16783 7.99473 7.41209 7.80483C8.48257 6.99579 8.96389 5.64657 8.63984 4.36324C8.12709 3.16638 6.82242 2.48726 5.51893 2.73872C4.21544 2.99017 3.27329 4.10271 3.2652 5.40003H0.5C0.5 2.41771 2.97605 2.44783e-05 6.03041 2.44783e-05C8.15524 -0.00618414 10.0984 1.1691 11.0391 3.02944C11.8841 4.92495 11.55 7.12481 10.1782 8.69943C9.76112 9.15074 9.29296 9.55442 8.78271 9.90272C8.3362 10.2034 7.92887 10.5561 7.5697 10.953C7.0621 11.7041 6.84331 12.6064 6.95214 13.5Z" fill="#56CCF2"/>
            </svg>
         </>
    );
};

export default IconSupport;