import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {headersAccept, headersContent} from "./APIToken";
import {toast} from "react-toastify";


export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
        },
    }),
    tagTypes: ['Token'],
    endpoints: (build) => ({
        getToken: build.mutation({
            queryFn: async (arg: any, api: any, extraOptions: any, baseQuery: any) => {
                try {
                    // Используем fetchWithBQ для выполнения запроса
                    const result = await baseQuery({
                        url: `/login/hash/${arg}`,
                        method: 'POST',
                    });
                    // Если есть ошибка, возвращаем объект с ошибкой
                    if (result.error) {
                        toast.error(result.error?.data?.message || 'Ошибка')
                        return {error: result.error};
                    }

                    // Возвращаем данные, если запрос успешен
                    return result;
                } catch (error) {
                    // Возвращаем ошибку, если произошла непредвиденная ошибка
                    return {error: {status: 'FETCH_ERROR', error}};
                }
            },
        }),

    })
});

export const {useGetTokenMutation} = userApi;

export const {getToken} = userApi.endpoints;

