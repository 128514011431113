import React, {FC, useEffect, useState} from 'react';
import BalanceChart from "./balanceChart";
import Chart from "../entities/components/chart/chart";
import {
    Divider,
    Grid,
    Stack,
    TextField,
    useMediaQuery
} from "@mui/material";
import Button from "@mui/material/Button";
import SimpleModal from "../entities/components/modal/simpleModal";
import IconTraders from "../shared/assets/images/icons/iconTraders";
import IconSet from "../shared/assets/images/icons/iconSet";
import Paper from "@mui/material/Paper";
import {
    useChangeAdminTradersStatusMutation,
    useDeleteSetMutation,
    useUpdateSetMutation,
} from "../store/API/tradeSetsApi";
import LinkedTrader from "../entities/components/modal/linkedTrader";
import ChangeStatusAdminTrader from "../entities/components/modal/changeStatusAdminTrader";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


interface IType {
    data?: any;
    showEyes?: boolean
}

const Set: FC<IType> = ({data, showEyes = false}) => {
    const [deleteSet] = useDeleteSetMutation()
    const [updateSet] = useUpdateSetMutation()
    const [changeAdminTradersStatus] = useChangeAdminTradersStatusMutation()

    const mediaQuery = useMediaQuery('(min-width:980px)');

    const [openModalSetSettings, setOpenModalSetSettings] = useState(false);
    const [openModalStatus, setOpenModalStatus] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [textValue, setTextValue] = useState(data?.description);
    const [nameAccount, setNameAccount] = useState(data?.name);
    const [errorValues, setErrorValues] = useState<any>({})

    const closeModal = () => {
        setTextValue(data?.description)
        setNameAccount(data?.name)
        setErrorValues({})
        setOpenModalSetSettings(false)
    }
    const handleUpdateSet = async () => {
        if (textValue === '' || nameAccount === '') {
            setErrorValues({
                description: {
                    isError: textValue === '' ? true : false,
                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                },
                name: {
                    isError: nameAccount === '' ? true : false,
                    text: nameAccount === '' ? 'Поле обязательно к заполнению' : null,
                },
            })
        } else {
            try {
                const res: any = await updateSet({
                    id: data.id,
                    body: {
                        name: nameAccount,
                        description: textValue,
                    }
                })

                if (res.error) {
                    let errors: any = {}
                    for (let key in res.error.data.errors) {
                        errors[key] = {
                            isError: true,
                            text: res.error.data.errors[key][0]
                        }
                    }
                    setErrorValues(errors)

                } else {
                    closeModal()
                    setOpenModal(false)
                    setErrorValues({})
                }

            } catch (e) {
                console.log(e)
            }
        }

    }

    useEffect(() => {
        setTextValue(data?.description)
        setNameAccount(data?.name)
    }, [data])
    return (
        <>
            <Paper>
                <ChangeStatusAdminTrader open={openModalStatus} isHidden={data.is_active}
                                         name={data.name} confirm={async () => {
                    await changeAdminTradersStatus({
                        id: data.id,
                        body: {
                            is_active: !data.is_active
                        }
                    })
                    setOpenModalStatus(false)
                }} handlerClose={() => setOpenModalStatus(false)}/>
                <Stack direction="row" spacing={7} alignItems="center" sx={{mb: 7}}>
                    <IconSet/>
                    <span className="h2 white-90">Сет {data?.name}</span>
                    {showEyes && <Box
                        onClick={() => setOpenModalStatus(true)}
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            height: 'fit-content',
                            position: 'absolute',
                            display: 'flex',
                            right: '20px',
                            top: '12px',
                        }}>
                        {data.is_active && <VisibilityIcon sx={{
                            color: '#BDBDBD'
                        }}/>}
                        {!data.is_active && <VisibilityOffIcon sx={{
                            color: '#BDBDBD'
                        }}/>}
                    </Box>}
                </Stack>
                <Divider variant="fullWidth" sx={{mb: 7, width: `103%`}}/>
                <Grid
                    direction={!mediaQuery ? "column-reverse" : "row"}
                    container
                    spacing={10}
                    columns={12}
                    wrap="wrap"
                    sx={{mb: 7}}
                >
                    <Grid item xs={12} md={5}>
                        <Chart
                            title="Доходность"
                        >
                            <BalanceChart data={data?.graph}/>
                        </Chart>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Stack className="subHeaders white-80" spacing={7}>
                            <p>
                                {data?.description}
                            </p>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={10} columns={12} wrap="wrap" alignItems="stretch">
                    <Grid item xs={16} md={5}>
                        <Stack direction="row" spacing={7}>
                            <Button
                                onClick={() => setOpenModalDelete(true)}
                                variant="contained"
                                color="error"
                                sx={{minWidth: 81, height: 48}}
                            >Удалить</Button>
                            <Button
                                onClick={() => setOpenModalSetSettings(true)}
                                fullWidth
                                color="neutral"
                                sx={{height: 48}}
                            >Настройки</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={16} md={7}>
                        <Button fullWidth color="neutral" startIcon={<IconTraders/>} sx={{height: 48}}
                                onClick={() => setOpenModal(true)}>
                            Подключенные трейдеры
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {
                openModalSetSettings &&
                <SimpleModal title="Настройки" openModal={openModalSetSettings}
                             closeModal={closeModal}>
                    <Stack spacing={7}>
                        <TextField
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={errorValues?.name?.isError || false}
                            helperText={errorValues?.name?.isError && errorValues?.name?.text}
                            onBlur={() => {
                                setErrorValues({
                                    ...errorValues, ['name']: {
                                        isError: nameAccount === '' ? true : false,
                                        text: nameAccount === '' ? 'Поле обязательно к заполнению' : null,
                                    }
                                })
                            }}
                            value={nameAccount}
                            onChange={(e) => {
                                setNameAccount(e.target.value)
                                setErrorValues({
                                    ...errorValues, ['name']: {
                                        isError: false
                                    }
                                })
                            }}
                            label="Название сета"
                            type="text"
                        />
                        <TextField
                            value={textValue}
                            error={errorValues?.description?.isError || false}
                            helperText={errorValues?.description?.isError && errorValues?.description?.text}
                            onBlur={() => {
                                setErrorValues({
                                    ...errorValues, ['description']: {
                                        isError: textValue === '' ? true : false,
                                        text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                                    }
                                })
                            }}
                            multiline
                            minRows={10}
                            onChange={(e) => {
                                setTextValue(e.target.value)
                                setErrorValues({
                                    ...errorValues, ['description']: {
                                        isError: false
                                    }
                                })
                            }}
                        />
                        <Stack direction="row" justifyContent="flex-end" spacing={7}>
                            <Button onClick={closeModal} color="error">Отмена</Button>
                            <Button
                                onClick={handleUpdateSet}
                                color="success">Сохранить настройки</Button>
                        </Stack>
                    </Stack>
                </SimpleModal>
            }

            {
                openModal &&
                <LinkedTrader title="Подключенные трейдеры" openModal={openModal} id={data?.id}
                              closeModal={setOpenModal}/>
            }

            {
                openModalDelete &&
                <SimpleModal maxWidth={620} title="Подтверждение" openModal={openModalDelete}
                             closeModal={setOpenModalDelete}>
                    <span className="h2">
                        Удалить <span className="blue">Сет {data?.name}</span>?
                    </span>
                    <Stack direction="row" justifyContent="flex-end" spacing={7}>
                        <Button onClick={() => setOpenModalDelete(false)} color="error">Отмена</Button>
                        <Button
                            onClick={() => deleteSet(data.id)}
                            color="success">Подтвердить</Button>
                    </Stack>
                </SimpleModal>
            }
        </>
    );
};

export default Set;
