import React, {useContext, useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import TitleIcon from '@mui/icons-material/Title';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import ReplayIcon from '@mui/icons-material/Replay';
import {FormControl, IconButton, Menu, MenuItem, Select, Tooltip} from "@mui/material";
import StraightenIcon from '@mui/icons-material/Straighten';
import SearchIcon from '@mui/icons-material/Search';
//@ts-ignore
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
// import {data} from "./mock";
import classNames from "classnames";
import ModalChartSettingsV2 from "./modal"; // theme css file
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {resolveAny} from "dns";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {da} from "date-fns/locale";
import {useGetChartSpeculativeQuery, useGetDrawMutation, useSetDrawMutation} from "../../../store/API/chartApi";
import {ToolsContext} from "../../../widgets/dashboardTabs/tradePage/tarde_speculative";
import {units} from "../../../widgets/dashboardTabs/tradePage/tarde_speculative/setings";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import {DashboardContext} from "../../../widgets/dashboardTabs/dashboardTabs";

const buttonsAction = [
    {title: <LinearScaleIcon/>, link: 'Line', tooltip: 'Line'},
    {title: <HorizontalRuleIcon/>, link: 'Horizontal Line', tooltip: 'Horizontal Line'},
    {title: <LineAxisIcon/>, link: 'Horizontal Ray', tooltip: 'Horizontal Ray'},
    {title: <TitleIcon/>, link: 'Label', tooltip: 'Label'},
    {title: <CropSquareIcon/>, link: 'Rectangle', tooltip: 'Rectangle'},
    {title: <StraightenIcon/>, link: 'Measure', tooltip: 'Measure'},
    {title: <ReplayIcon/>, link: 'clear', tooltip: 'Очистить график'},
]

// const units = [
//     {id: 2, title: <>Таймлайн&nbsp;-<span className={'green'}>&nbsp;5 минут</span></>, value: 'm5', timeUnit: "minute"},
//     {
//         id: 3,
//         title: <>Таймлайн&nbsp;-<span className={'green'}>&nbsp;15 минут</span></>,
//         value: 'm15',
//         timeUnit: "minute"
//     },
//     {
//         id: 4,
//         title: <>Таймлайн&nbsp;-<span className={'green'}>&nbsp;30 минут</span></>,
//         value: 'm30',
//         timeUnit: "minute"
//     },
//     {id: 5, title: <>Таймлайн&nbsp;-<span className={'green'}>&nbsp;1 час</span></>, value: 'h1', timeUnit: "hour"},
//     {id: 6, title: <>Таймлайн&nbsp;-<span className={'green'}>&nbsp;4 часа</span></>, value: 'h4', timeUnit: "hour"},
//     {id: 7, title: <>Таймлайн&nbsp;-<span className={'green'}>&nbsp;1 день</span></>, value: 'd1', timeUnit: "day"},
// ];

function formatDate(date: any) {
    // Получаем значения дня, месяца и года из даты
    const day = date.getDate();
    const month = date.getMonth() + 1; // Месяцы начинаются с 0, поэтому добавляем 1
    const year = date.getFullYear();

    // Преобразуем значения в строку и добавляем ведущие нули, если необходимо
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    const formattedYear = String(year);

    // Возвращаем дату в формате DD.MM.YYYY
    return `${formattedDay}.${formattedMonth}.${formattedYear}`;
}

function calculateNewIndex(array: any, startIndex: any, type = 'inc') {
    // Проверяем, что startIndex находится в пределах массива
    if (startIndex < 0 || startIndex >= array.length) {
        return -1// Возвращаем -1, если startIndex некорректный
    }
    // Вычисляем индекс, соответствующий 10% от начального индекса

    // Вычисляем индекс, соответствующий 25% от начального индекса
    let newIndex: any;
    const percentage = 0.2; // 20%
    const shift = Math.floor(array.length * 0.2); // 20%
    if (type === 'inc') {
        newIndex = Math.floor(startIndex + (array.length - startIndex) * 0.25);
    } else if (type === 'deinc') {
        // newIndex = startIndex - shift;
        // newIndex = Math.floor(startIndex - (array.length - startIndex) / 0.75);
        newIndex = Math.floor(startIndex - (array.length - startIndex) / 1.25);
    }

    // Проверяем, что новый индекс находится в пределах массива
    if (newIndex < 0) {
        newIndex = 0;
    } else if (newIndex >= array.length) {
        newIndex = array.length - 1;
    }

    // Возвращаем новый индекс
    return newIndex;
}

const ChatWithSettingsv2 = ({setSettingChart, indicatorData, lineIndicator}: any) => {
    const {tools, setTools} = useContext(ToolsContext)
    const [timeLine, setTimeLine] = useState<any>('m15')
    const {dataDashboard} = useContext(DashboardContext)
    const [drawingsChart, setDrawingsChart] = useState([])
    const [mainStockChart, setMainStockChart] = useState(null)

    const [params, setParams] = useState({
        page: 1,
        type: 1,
        tag: {id: null, name: 'Все'},
        search: '',
        sort: {path: null, sort: 1},
        duration: null
    })

    const {data: dataChart, isFetching, refetch} = useGetChartSpeculativeQuery({timeline: timeLine, tools: tools?.id}, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    console.log(timeLine, 'timeLine')
    const [getDraw] = useGetDrawMutation()
    const [setDraw] = useSetDrawMutation()
    const data: any = dataChart?.data ? dataChart?.data : []

    const [stockChart, setStockChart] = useState<any>(null);
    const [activeSetting, setActiveSetting] = useState<any>(null)
    const [chooseDiaposonDays, setChooseDiaposoneDays] = useState<any>(null)
    const [updateChart, setUpdateChart] = useState<any>(null);
    const [dateAxisData, setDateAxisData] = useState<any>(null);
    const [startIndexDate, setStartIndex] = useState<any>(1);
    const [state, setState] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const [zoomByDate, setZoomByDate] = useState<any>({
        start: null,
        end: null,
    })

    const [upperLineSeriesChart, setUpperLineSeriesChart] = useState(null)
    const [lowerLineSeriesChart, setLowerLineSeriesChart] = useState(null)

    const [openModal, setOpenModal] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (isFetching || !dataChart?.data || dataChart?.data?.length === 0) return;
        //@ts-ignore
        const am5 = window?.am5;
        //@ts-ignore
        const am5themes_Animated = window?.am5themes_Animated;
        //@ts-ignore
        const am5stock = window?.am5stock;
        //@ts-ignore
        const am5xy = window?.am5xy;

        if (!am5 || !am5themes_Animated || !am5stock || !am5xy) {
            console.error('One or more amCharts libraries are missing from the global window object.');
            return;
        }
        const chartContainer = document.getElementById("chartdiv_testing_chart");
        if (chartContainer) {
            chartContainer.style.marginTop = "-30px";
        }

        const root = am5.Root.new("chartdiv_testing_chart");
        const myTheme = am5.Theme.new(root);
        root._logo?.dispose();
        myTheme.rule("Grid", ["scrollbar", "minor"]).setAll({visible: false});

        root.setThemes([am5themes_Animated.new(root), myTheme]);

        const stockChart = root.container.children.push(am5stock.StockChart.new(root, {
            paddingRight: 0,
            active: true,
            drawingSelectionEnabled: true
        }));

        root.numberFormatter.set("numberFormat", "#,###.000000");
        root.interfaceColors.set("grid", am5.color(0xCCCCCC));

        const mainPanel = stockChart.panels.push(am5stock.StockPanel.new(root, {
            wheelY: "zoomX",
            panX: true,
            panY: true,
            wheelX: "panX",
        }));

        const valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {pan: "zoom"}),
            extraMin: 0.1,
            tooltip: am5.Tooltip.new(root, {}),
            numberFormat: "#,###.000000",
            extraTooltipPrecision: 2
        }));

        const dateAxis = mainPanel.xAxes.push(am5xy.GaplessDateAxis.new(root, {
            baseInterval: {timeUnit: "minute", count: 5},
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));
        setDateAxisData(dateAxis);

        dateAxis.get("renderer").labels.template.setAll({fill: am5.color(0x56CCF2)});
        valueAxis.get("renderer").labels.template.setAll({fill: am5.color(0x56CCF2)});
        dateAxis.get("renderer").grid.template.setAll({stroke: am5.color(0xffffff), strokeOpacity: 0});
        valueAxis.get("renderer").grid.template.setAll({stroke: am5.color(0xffffff), strokeOpacity: 0});

        const valueSeries = mainPanel.series.push(am5xy.CandlestickSeries.new(root, {
            name: tools?.symbol,
            valueXField: "Date",
            valueYField: "Close",
            highValueYField: "High",
            lowValueYField: "Low",
            openValueYField: "Open",
            xAxis: dateAxis,
            yAxis: valueAxis,
            legendValueText: "open: [bold]{openValueY}[/] high: [bold]{highValueY}[/] low: [bold]{lowValueY}[/] close: [bold]{valueY}[/]",
            legendRangeValueText: ""
        }));

        const valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(root, {
            stockChart: stockChart
        }));

        // Настройка курсора
        let cursor = mainPanel.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: dateAxis,
            yAxis: valueAxis,
        }));
        cursor.lineX.setAll({
            stroke: am5.color(0xffffff),
            strokeWidth: 1
        });
        cursor.lineY.setAll({
            stroke: am5.color(0xffffff),
            strokeWidth: 1
        });

        // Индикаторы
        const upperLineSeries = mainPanel.series.push(am5xy.LineSeries.new(root, {
            name: "Upper Line",
            valueXField: "Date",
            valueYField: "Upper",
            xAxis: dateAxis,
            yAxis: valueAxis,
            stroke: am5.color(0xFF0000),
            strokeWidth: 2,
            legendValueText: "[bold]{valueY}[/]",
            legendRangeValueText: ""
        }));

        const lowerLineSeries = mainPanel.series.push(am5xy.LineSeries.new(root, {
            name: "Lower Line",
            valueXField: "Date",
            valueYField: "Lower",
            xAxis: dateAxis,
            yAxis: valueAxis,
            stroke: am5.color(0x00FF00),
            strokeWidth: 2,
            legendValueText: "[bold]{valueY}[/]",
            legendRangeValueText: ""
        }));


        // Настройка scrollbarX
        const scrollbarX = mainPanel.set("scrollbarX", am5xy.XYChartScrollbar.new(root, {
            orientation: "horizontal",
            height: 30
        }));

        const sbDateAxis = scrollbarX.chart.xAxes.push(am5xy.GaplessDateAxis.new(root, {
            baseInterval: {timeUnit: "minute", count: 1},
            renderer: am5xy.AxisRendererX.new(root, {minorGridEnabled: false})
        }));

        let xAxisRenderer = scrollbarX.chart.xAxes.getIndex(0).get("renderer");
        xAxisRenderer.labels.template.set("fill", am5.color(0xffffff));

        const sbValueAxis = scrollbarX.chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        const sbSeries = scrollbarX.chart.series.push(am5xy.LineSeries.new(root, {
            valueYField: "Close",
            valueXField: "Date",
            xAxis: sbDateAxis,
            yAxis: sbValueAxis,
            fill: am5.color(0x6FCF97),
        }));

        sbSeries.fills.template.setAll({
            visible: true,
            fillOpacity: 0.6,
            fill: am5.color(0x000000),
            stroke: am5.color(0x000000),
        });

        scrollbarX.get("background").setAll({
            fill: am5.color(0x29312c),
            fillOpacity: 1,
        });

        scrollbarX.overlay.setAll({
            fill: am5.color(0x3C3C3C),
        });

        scrollbarX.thumb.setAll({
            fill: am5.color(0xffffff),
            strokeWidth: 1,
            fillOpacity: 0,
        });

        const drawingControl = am5stock.DrawingControl.new(root, {
            stockChart: stockChart,
        })
        const dayFilter = am5stock.PeriodSelector.new(root, {
            stockChart: stockChart
        })


        setStockChart(drawingControl)
        setUpdateChart(dayFilter)
        setMainStockChart(stockChart)

        setUpperLineSeriesChart(upperLineSeries)
        setLowerLineSeriesChart(lowerLineSeries)

        valueSeries.data.setAll(data);
        sbSeries.data.setAll(data);
        valueLegend.data.setAll([valueSeries, upperLineSeries, lowerLineSeries]);

        setTimeout(() => {
            if (data.length > 200) {
                const lastDate = data.at(-1).Date;
                const startDate = data.at(-200).Date;

                dayFilter?.selectPeriod({
                    timeUnit: "custom",
                    start: new Date(startDate),
                    end: new Date(lastDate)
                })
            }
        }, 100)

        let debounceTimer: NodeJS.Timeout | null = null;

        const handleDrawingsUpdated = (event: any) => {
            console.log(event)
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            console.log(JSON.parse(drawingControl?.serializeDrawings()))
            const filterDraw = JSON.parse(drawingControl?.serializeDrawings())?.map((el: any) => {
                if (el?.__tool === "Horizontal Line") {
                    return {...el, __drawing: el?.__drawing?.filter((f: any) => !f?.hidden)}
                } else {
                    return el
                }
            })
            debounceTimer = setTimeout(() => {
                setDraw({
                    idProduct: dataDashboard?.product?.product_data?.id,
                    idSymbol: tools?.id,
                    data: filterDraw
                })
                    .unwrap()
                    .then((res) => {
                        try {
                            if (stockChart?.unserializeDrawings) {
                                const drawings = JSON.parse(res.data.draw);

                                stockChart?.unserializeDrawings(drawings);
                                setDrawingsChart(JSON.parse(res?.data?.draw))
                            }

                        } catch (error) {
                            console.error("Error parsing drawings:", error);
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }, 1000); // Debounce delay
        };

        if (drawingControl) {
            stockChart.events.on("drawingsupdated", handleDrawingsUpdated);
            stockChart.events.on("drawingadded", function () {
                console.log('FINISH')
                drawingControl.set("active", false);
                drawingControl?.setEraser(false)
                setActiveSetting(null)
            });
        }

        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            if (drawingControl) {
                stockChart.events.off("drawingsupdated", handleDrawingsUpdated);
            }
            root.dispose();
        };

    }, [dataChart?.data, isFetching, tools?.id]);

    const handleSelectTool = (tool: any) => {
        if (!stockChart) return;
        stockChart?.setEraser(false)
        if (tool === 'remove') {
            setActiveSetting('remove')
            stockChart?.setEraser(activeSetting === tool ? false : true)
        } else if (tool === 'active') {
            console.log(tool)
            setActiveSetting('active')
            stockChart.setAll({
                drawingSelectionEnabled: true,
                //     tool: 'select'
            });
        } else if (tool !== null) {
            stockChart?.setEraser(false)
            setTimeout(() => {
                setActiveSetting(activeSetting === tool ? null : tool)
                stockChart.setAll({
                    active: activeSetting === tool ? false : true,
                    // active: true,
                    tool: activeSetting === tool ? null : tool,
                    //@ts-ignore
                    strokeColor: window?.am5.color(0xffffff),
                    //@ts-ignore
                    labelFill: window?.am5.color(0xffffff),
                });
            }, 0)
        } else {
            stockChart.set("active", false);
            stockChart?.setEraser(false)
            setActiveSetting(null)
        }


    };

    const handleResetDrawing = () => {
        if (stockChart) {
            const drawings = stockChart.get("drawings");
            console.log(stockChart)
            stockChart.clearDrawings()
        }
    };

    const handleSelectPeriod = (element: any) => {
        setChooseDiaposoneDays(element)
        if (element?.value === 'max') {
            updateChart.selectPeriod({timeUnit: "max"})
        } else {
            updateChart.selectPeriod({timeUnit: element.timeUnit, count: element?.value})
        }
    };

    const handleSelectRangePeriod = (element: any) => {
        if (!element.startDate) return
        console.log(element?.startDate)
        console.log(element?.endDate)
        updateChart.selectPeriod({
            timeUnit: "custom",
            start: new Date(element?.startDate),
            end: new Date(element?.endDate)
        })
    };

    const handleZoom = (type: any) => {
        console.log(zoomByDate)
        if (dateAxisData) {
            if (type === '+') {
                dateAxisData?.zoomToDates(new Date(zoomByDate?.start), new Date(zoomByDate?.end))
            } else if (type === '-') {
                const findStartIndex = data?.findIndex((f: any) => {
                    return f.Date === zoomByDate.start
                })
                const index = calculateNewIndex(data, findStartIndex, 'deinc') || 0
                const indexCurrent = (index === -1 || !index) ? 0 : index
                dateAxisData?.zoomToDates(new Date(data[indexCurrent]?.Date), new Date(zoomByDate?.end))
            }
        }
    }

    useEffect(() => {
        if (!timeLine || timeLine === 'd1') return;
        const times: Record<string, number> = {
            'm5': 5,
            'm15': 15,
            'm30': 30,
            'h1': 60,
            'h4': 240,
        };
        const timeEveryUpdate = times[timeLine];

        const calculateNextUpdate = () => {
            const now = new Date();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();
            const milliseconds = now.getMilliseconds();

            // Рассчитываем, сколько времени осталось до следующего полного интервала + 2 секунды
            const timeLeftInMinutes = timeEveryUpdate - (minutes % timeEveryUpdate);
            const timeLeftInSeconds = (timeLeftInMinutes * 60) - seconds;
            const timeLeftInMilliseconds = (timeLeftInSeconds * 1000) - milliseconds + 2000; // + 2 секунды

            return timeLeftInMilliseconds;
        };

        // Вызываем refetch по завершению текущего таймфрейма и каждые n минут после этого
        const scheduleNextUpdate = () => {
            const timeUntilNextUpdate = calculateNextUpdate();

            setTimeout(() => {
                refetch();
                scheduleNextUpdate(); // Запланировать следующий вызов
            }, timeUntilNextUpdate);
        };

        // Первое расписание
        scheduleNextUpdate();

        // @ts-ignore
        return () => clearTimeout(scheduleNextUpdate); // Очищаем таймер при размонтировании компонента

    }, [timeLine])

    useEffect(() => {
        if (!data || data?.length === 0 || !indicatorData || !upperLineSeriesChart || !lowerLineSeriesChart) return
        // Создаём таймер
        let timerId: any;

        const setIndicatorData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/stock/symbol/${tools?.id}/${timeLine}/indicator`, {
                    params: {
                        from: data[0]?.Date / 1000,
                        to: data?.at(-1)?.Date / 1000,
                        coefficient: indicatorData?.coefficient,
                        period: indicatorData?.period,
                        down: indicatorData?.down,
                        up: indicatorData?.up,
                    }
                });

                const indicatorDataArray: any = Object.keys(response.data).map((key: any) => {
                    if (!response.data[key]) return
                    return {
                        Date: +key,
                        Upper: response.data[key] ? response.data[key].upper : 710.1525999753509,
                        Lower: response.data[key] ? response.data[key].lower : 634.1028207752
                    }
                })?.filter((f: any) => f)

                if (upperLineSeriesChart && lowerLineSeriesChart) {
                    //@ts-ignore
                    upperLineSeriesChart.data.setAll(indicatorDataArray);
                    //@ts-ignore
                    lowerLineSeriesChart.data.setAll(indicatorDataArray);
                }
            } catch (error) {
                console.error(error);
            }
        };

        // Если `indicatorData` изменяется, очистите предыдущий таймер и установите новый
        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            setIndicatorData();
        }, 500); // 0.5 секунды

        // Очистите таймер при размонтировании компонента
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [indicatorData, data, lowerLineSeriesChart, upperLineSeriesChart]);

    useEffect(() => {
        if (!stockChart || !dataDashboard?.product?.product_data?.id || !tools?.id) return

        getDraw({
            idProduct: dataDashboard?.product?.product_data?.id,
            idSymbol: tools?.id
        })
            .unwrap()
            .then((res) => {
                const draws = res?.data?.draw ? JSON.parse(res?.data?.draw) : null
                if (draws && Array?.isArray(draws) && draws?.length !== 0) {
                    const filterDraws = draws?.filter((f) => f?.__drawing?.length !== 0)
                    console.log('res?.data?.draw', filterDraws)
                    if (filterDraws?.length !== 0) {
                        stockChart?.unserializeDrawings(filterDraws)
                        // @ts-ignore
                        setDrawingsChart(filterDraws)
                    }


                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [stockChart, tools?.id, dataDashboard?.product?.product_data?.id])

    useEffect(() => {
        setSettingChart(tools)
    }, [tools])

    useEffect(() => {
        if (tools.time_frame) {
            setTimeLine(tools.time_frame)
        }
        if (tools.object === 'Bond' || tools.object === 'Stock') {
            setParams({...params, type: tools.object === 'Stock' ? 1 : 2})
        }
    }, [tools?.object, tools?.time_frame])

    const addedLine = (price: any, id: any) => {

        if (stockChart && lowerLineSeriesChart) {
            //@ts-ignore
            const lastData = lowerLineSeriesChart?.dataItems?.at(-1)?.dataContext


            const line = [{
                "__tool": "Horizontal Line",
                "__panelIndex": 3,
                "__drawing": [{
                    "stroke": {
                        "type": "Template",
                        "__parse": true,
                        "settings": {
                            "stroke": {"type": "Color", "value": "#0a0"},
                            "strokeOpacity": 1,
                            "strokeDasharray": [8, 8],
                            "strokeWidth": 2,
                            "__parse": true,
                        }
                    },
                    "fill": {
                        "type": "Template",
                        "settings": {"fill": {"type": "Color", "value": "#ad6eff"}, "fillOpacity": 0.2},
                    },
                    "index": 3,
                    "showExtension": true,
                    "hidden": true,
                    "corner": "e",
                    "drawingId": id,
                    "sprite": {"type": "Line"},
                    "__parse": true
                }, {
                    "valueY": price,
                    "valueX": 0,
                    "corner": "p1",
                    "hidden": true,
                    "index": 3,
                    "drawingId": id,
                    "__parse": true
                }, {
                    "valueY": price,
                    "valueX": 0,
                    "corner": "p2",
                    "hidden": true,
                    "index": 3,
                    "drawingId": id,
                    "__parse": true
                }, {
                    "valueY": price,
                    "valueX": lastData?.Date,
                    "corner": "p3",
                    "hidden": true,
                    "index": 3,
                    "drawingId": id,
                    "__parse": true
                }]
            }]
            // const labelObject = {
            //     __tool: "Label",
            //     __panelIndex: 0,
            //     __drawing: [
            //         {
            //             corner: 0,
            //             drawingId: "172372446196518619",
            //             index: 1,
            //             label: {
            //                 type: "EditableLabel"
            //             },
            //             settings: {
            //                 type: "Template",
            //                 settings: {
            //                     fill: {
            //                         type: "Color",
            //                         value: "#ffffff"  // Цвет текста
            //                     },
            //                     fontFamily: "Arial",  // Шрифт
            //                     fontSize: "12px",  // Размер шрифта
            //                     fontStyle: "normal",  // Стиль шрифта
            //                     fontWeight: "normal"  // Толщина шрифта
            //                 }
            //             },
            //             type: "Template",
            //             sprite: {
            //                 type: "Container"
            //             },
            //             text: "",  // Текст, который будет отображаться
            //             valueX: 1711621849980,  // Координата X (временная метка или значение на оси X)
            //             valueY: 609.6,  // Координата Y
            //             rotation: 0,  // Угол поворота текста
            //             scale: 1,  // Масштабирование текста
            //             __parse: true
            //         }
            //     ]
            // };
            //@ts-ignore
            const saveData = JSON.parse(drawingsChart)
            console.log(saveData)
            console.log(line)
            // "Horizontal Line"
            const findLine = saveData?.find((f: any) => f.__tool === 'Horizontal Line')

            if (findLine) {
                stockChart?.unserializeDrawings(saveData?.map((el: any) => el.__tool === 'Horizontal Line' ? {
                    ...el,
                    //@ts-ignore
                    __drawing: [...el?.__drawing, ...line[0]?.__drawing]
                } : el))
            } else {
                //@ts-ignore
                stockChart?.unserializeDrawings([...saveData, line])
                // stockChart?.unserializeDrawings([line[0]])
            }
            // stockChart?.unserializeDrawings([...drawingsChart,line])
        }

    }

    const deleteLine = (id: any) => {
        if (mainStockChart) {
            //@ts-ignore
            mainStockChart?.deleteDrawing(id)
        }
    }
    const [countLine, setCountLine] = useState(0)

    function useDebounce(callback: any, delay: any) {
        const timeoutRef = useRef<any>(null);

        const debouncedFunction = (...args: any) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                callback(...args);
            }, delay);
        };

        return debouncedFunction;
    }

    const debouncedEffect = useDebounce(() => {
        console.log(lineIndicator, 'lineIndicator');

        if (lineIndicator?.grid_orders_count) {
            for (let i = 0; i < +(countLine || lineIndicator?.grid_orders_count); i++) {
                deleteLine(`indicator${i}`);
            }

            setTimeout(() => {
                //@ts-ignore
                const lastData = lowerLineSeriesChart?.dataItems?.at(-1)?.dataContext;
                console.log(lastData, 'lastData');
                let currentValue = lastData?.Lower;
                const stepAmount = currentValue * (lineIndicator?.step_between_orders / 100); // Рассчитываем уменьшение между первой и второй линиями

                for (let i = 0; i < +lineIndicator?.grid_orders_count; i++) {
                    addedLine(currentValue, `indicator${i}`);
                    currentValue -= stepAmount;
                }
                setCountLine(+lineIndicator?.grid_orders_count);
            }, 0);
        } else {
            if (countLine) {
                for (let i = 0; i < +countLine; i++) {
                    deleteLine(`indicator${i}`);
                }
            }
        }
    }, 300); // Задержка для дебаунса в миллисекундах

    useEffect(() => {
        debouncedEffect(); // Вызываем дебаунс-функцию при изменении lineIndicator
    }, [lineIndicator]);

    return <div className={s.chart}>
        {openModal &&
            <ModalChartSettingsV2 setParams={setParams} params={params} setTools={setTools} tools={tools}
                                  open={openModal}
                                  handlerClose={() => setOpenModal(false)}/>}
        {/*<div onClick={addedLine}>Added line</div>*/}
        {/*<div onClick={deleteLine}>Delete line</div>*/}
        <div className={s.header_chart}>
            {/*<button onClick={handleGetDrawingData}>Получить данные нарисованных элементов</button>*/}
            {/*<button onClick={setIndicatorData}>Получить данные нарисованных элементов</button>*/}
            <div className={classNames(s.wrapper_date, s.all_settings)} onClick={() => setOpenModal(true)}>
                <SearchIcon sx={{height: '20px', width: 'auto'}}/>
                Все инструменты
            </div>

            <div className={s.wrapper_date}>
                <div
                    onClick={handleClick}
                    className={s.date_input} style={{
                    justifyContent: state[0]?.startDate ? 'space-between' : 'center'
                }}>
                    {!state[0]?.startDate ? 'Дата от - до' : `${formatDate(state[0]?.startDate)} - ${formatDate(state[0]?.endDate)}`}

                    {state[0]?.startDate && <Tooltip arrow title={'Сбросить дату'}>
                        <IconButton sx={{
                            height: '25px',
                            width: '25px'
                        }} onClick={(e) => {
                            e.stopPropagation()
                            setState([
                                {
                                    startDate: null,
                                    endDate: null,
                                    key: 'selection'
                                }
                            ])
                            updateChart.selectPeriod({timeUnit: "max"})
                        }}>
                            <ReplayIcon sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>}
                </div>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            padding: 0,

                            marginTop: '12px !important',
                            '& .MuiList-root': {
                                padding: '0 !important',
                            }
                        }
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                        handleClose()
                        handleSelectRangePeriod(state[0])
                    }}
                    PaperProps={{
                        style: {
                            maxHeight: '100%',
                            width: 'fit-content',
                        },
                    }}
                >
                    <div

                        className={s.dataRangeBox}>
                        <DateRange
                            rangeColors={['#6FCF97']}
                            editableDateInputs={true}
                            onChange={(item: any) => {
                                setState([item.selection])
                            }}
                            maxDate={new Date()}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                        />
                    </div>
                </Menu>
            </div>

            <div className={s.zooming}>
                <div className={s.zoom_action} onClick={() => handleZoom('+')}>
                    <ZoomInIcon sx={{color: 'rgba(111, 207, 151, 1)'}}/>
                </div>
                <div className={s.zoom_action} onClick={() => handleZoom('-')}>
                    <ZoomOutIcon sx={{color: 'rgba(255, 136, 136, 1)'}}/>
                </div>
            </div>

            <FormControl sx={{
                maxWidth: '100% !important',
            }}>
                <Select
                    sx={{
                        maxWidth: '100% !important',
                        '& .MuiSelect-select': {
                            textAlign: 'center !important'
                        }
                    }}
                    value={timeLine}
                    inputProps={{'aria-label': 'Without label'}}
                >
                    {units?.map((el, i) => {
                        return <MenuItem
                            onClick={() => setTimeLine(el.value)}
                            key={el.id}
                            value={el.value}>
                            {el.title}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
        <div className={s.chart_container}>
            <div className={s.btns}>
                {buttonsAction?.map((el, i) => {
                    return <Tooltip title={el.tooltip} placement={"right"} arrow>
                        <div key={i}
                             className={`${s.btn_setting} ${activeSetting === el.link && s.btn_setting_active}`}
                             onClick={() => {
                                 if (el.link === 'clear') {
                                     handleResetDrawing()
                                 } else {
                                     handleSelectTool(el.link === activeSetting ? null : el.link)
                                 }

                             }}>
                            {el.title}
                        </div>
                    </Tooltip>
                })}
                <Tooltip title={'Удаление'} placement={"right"} arrow>
                    <div
                        className={`${s.btn_setting} ${activeSetting === 'remove' && s.btn_setting_active}`}
                        onClick={() => {
                            handleSelectTool('remove' === activeSetting ? null : 'remove')
                        }}>
                        <DeleteOutlineIcon/>
                    </div>
                </Tooltip>

            </div>
            <div className={s.chart_wrapper}>
                {dataChart && dataChart?.data?.length === 0 && tools && <h3 className={s.empty}>Нет данных</h3>}
                {!tools && <h3 className={s.empty}>Выберите инструменты</h3>}
                {(!isFetching && tools && dataChart?.data?.length !== 0) &&
                    <div id="chartdiv_testing_chart" style={{width: "100%", height: "550px"}}/>}
            </div>
        </div>
    </div>;
};

export default ChatWithSettingsv2;
