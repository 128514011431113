import React, {FC, useState} from 'react';
import Paper from "@mui/material/Paper";
import {Button, Checkbox, Divider, Stack, useMediaQuery} from "@mui/material";
import IconConnected from "../shared/assets/images/icons/iconConnected";
import {useAppSelector} from "../hooks/useRedux";
import CopyTradingModalSettings from "../entities/components/modal/copyTradingModalSettings";
import SimpleModal from "../entities/components/modal/simpleModal";
import {useUnsubscribeTraderMutation} from "../store/API/tradersUserApi";
import SettingsSidebar from "./settingsSidebar";
import {useGetProfileQuery} from "../store/API/profileApi";

interface IType {
    dataTrader?: any
}

const DashboardTradersSidebar: FC<IType> = ({dataTrader}) => {


    const {accountId, accountName} = useAppSelector(state => state.accountIdReducer)

    const subscribe = dataTrader?.subscribed_forex_accounts.find((item: any) => item.forex_account.id === accountId)

    const [unsubscribe] = useUnsubscribeTraderMutation()
    const {refetch} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const mediaQuery = useMediaQuery('(min-width:980px)');

    const [accept, setAccept] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState(false);
    const [openModalUnsubscribe, setOpenModalUnsubscribe] = useState(false);
    const [hoverBtn, setHoverBtn] = useState(false);

    return (
        <Stack spacing={7} sx={
            !mediaQuery ? {
                    width: `100vw`,
                    paddingBottom: '150px !important',
                    minHeight: '100vh',
                    padding: '14px',
                    background: '#1F1F1F',
                    "@media (min-width:980px)": {
                        padding: `14px `,
                    }
                }
                : null
        }>
            <Paper sx={{
                "@media (min-width:980px)": {
                    p: 14,
                }
            }}>
                <Stack spacing={7}>
                    <Stack>
                        Информация
                    </Stack>
                    <Divider variant="fullWidth" sx={{width: `calc(100%)`}}/>
                    <Stack className="subHeaders white-90" direction="row" alignItems="center"
                           justifyContent="space-between">
                        <span>Стратегия</span>
                        <span className="subHeaders white-100">{dataTrader?.strategy}</span>
                    </Stack>
                    <Stack className="subHeaders white-90" direction="row" alignItems="center"
                           justifyContent="space-between">
                        <span>Начало торгов</span>
                        <span className="subHeaders white-100">{dataTrader?.start_trading_at}</span>
                    </Stack>
                    <Stack className="subHeaders white-90" direction="row" alignItems="center"
                           justifyContent="space-between">
                        <span>Сделок за неделю</span>
                        <span className="subHeaders white-100">{dataTrader?.stats?.deals_count?.all_per_week}</span>
                    </Stack>
                    <Stack className="subHeaders white-90" direction="row" alignItems="center"
                           justifyContent="space-between">
                        <span>Средняя прибыль в PIP</span>
                        <span className="green">
                            {dataTrader?.stats?.mean_profit_in_pip}
                        </span>
                    </Stack>
                    <Stack className="subHeaders white-90" direction="row" alignItems="center"
                           justifyContent="space-between">
                        <span>Средний убыток в PIP</span>
                        <span className="red">
                            {dataTrader?.stats?.mean_losing_in_pip}
                        </span>
                    </Stack>
                    <Stack className="subHeaders white-90" direction="row" alignItems="center"
                           justifyContent="space-between">
                        <span>Минимальная нагрузка</span>
                        <span
                            className="subHeaders yellow">{dataTrader?.stats?.deposit_load?.for_forex_accounts[accountId] || dataTrader?.stats?.deposit_load?.min || 0}%</span>
                    </Stack>
                </Stack>
            </Paper>

            {
                dataTrader?.subscribed_forex_accounts.find((item: any) => item.forex_account.id === accountId) ?
                    <Button
                        onMouseEnter={() => setHoverBtn(true)}
                        onMouseLeave={() => setHoverBtn(false)}
                        onClick={() => setOpenModalUnsubscribe(true)}
                        fullWidth
                        variant="contained"
                        color={!hoverBtn ? "success" : "error"}
                        startIcon={!hoverBtn && <IconConnected connected/>}
                        sx={{height: 96}}>
                        <span className="h2">{!hoverBtn ? "Подключено" : "Отключить"}</span>
                    </Button>
                    :
                    <Button
                        onClick={() => setOpenModal(true)}
                        fullWidth
                        variant="contained"
                        color="warning"
                        startIcon={<IconConnected/>}
                        sx={{height: 96}}
                    >
                        <span className="h2">Подключиться</span>
                    </Button>
            }

            {
                (dataTrader?.subscribed_forex_accounts.find((item: any) => item.forex_account.id === accountId)) &&
                <Stack sx={{position: 'sticky', top: 0}}>
                    <SettingsSidebar
                        minRisk={dataTrader?.stats?.deposit_load?.for_forex_accounts[accountId] || dataTrader?.stats?.deposit_load?.min || 0}
                        traderId={dataTrader.id}
                        accountName={accountName} subscribeId={subscribe.id}/>
                </Stack>

            }
            {
                openModal &&
                <CopyTradingModalSettings
                    // minRisk={dataTrader?.stats?.deposit_load?.min || 0}
                    minRisk={dataTrader?.stats?.deposit_load?.for_forex_accounts[accountId] || dataTrader?.stats?.deposit_load?.min || 0}
                    skip={!dataTrader?.subscribed_forex_accounts.find((item: any) => item.forex_account.id === accountId)}
                    idTraderSubscribe={dataTrader.id}
                    idTrader={dataTrader.id}
                    nameTrader={dataTrader?.name}
                    nameAccount={accountName}
                    idAccount={accountId}
                    openModal={openModal}
                    closeModal={setOpenModal}/>
            }

            {
                openModalUnsubscribe &&
                <SimpleModal title="Отключить трейдера" openModal={openModalUnsubscribe}
                             closeModal={() => {
                                 setOpenModalUnsubscribe(false)
                                 setAccept(false)
                             }}>
                    <span className="h2">
                        <span>Вы уверены что хотите отключить копирование</span>
                        <span className="yellow">&nbsp;{dataTrader?.name}&nbsp;</span>
                        <span>?</span>
                        <br/>
                         <i style={{
                             fontSize: '12px',
                             fontWeight: 400
                         }}>
                             После отключения копирования, все открытые сделки необходимо будет закрыть вручную. Название трейдера указано в комментарии к ордеру.
                         </i>
                        <br/>
                           <div style={{
                               marginTop: '14px',
                           }}>
                                        <Checkbox checked={accept} onClick={() => setAccept(!accept)}/>
                                        <span style={{
                                            fontSize: '12px',
                                            marginLeft: '10px'
                                        }}>Подтверждаю</span>
                                    </div>
                    </span>
                    <Stack direction="row" justifyContent="flex-end" spacing={7}>
                        <Button onClick={() => {
                            setOpenModalUnsubscribe(false)
                            setAccept(false)
                        }} color="error">Отмена</Button>
                        <Button
                            disabled={!accept}
                            onClick={async () => {
                                unsubscribe(subscribe?.id).then(() => {
                                    setOpenModalUnsubscribe(false)
                                    setAccept(false)
                                    refetch()
                                })
                            }}
                            color="success">Подтвердить</Button>
                    </Stack>
                </SimpleModal>
            }
        </Stack>

    );
};

export default DashboardTradersSidebar;
