import React, {FC, useState} from 'react';
import {Button, IconButton, Pagination, Stack, TextField, useMediaQuery} from "@mui/material";
import TraderItem from "../entities/components/TraderItem";
import {
    useAddTraderMutation, useChangeAdminTradersStatusMutation,
    useDeleteTraderMutation,
    useGetAllAdminTradersQuery,
    useTraderByIdMutation,
    useUpdateTraderMutation
} from "../store/API/tradersAdminApi";
import IconPlus from "../shared/assets/images/icons/iconPlus";
import SimpleModal from "../entities/components/modal/simpleModal";
import CustomSelect from "../shared/UI/customSelect";

interface IType {
    children?: any
}

const TradersList: FC<IType> = ({children}) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [page, setPage] = useState(1);
    const {data} = useGetAllAdminTradersQuery(page, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [deleteTrader] = useDeleteTraderMutation()
    const [addTrader] = useAddTraderMutation()
    const [changeAdminTradersStatus] = useChangeAdminTradersStatusMutation()
    const [updateTrader] = useUpdateTraderMutation()
    const [traderById] = useTraderByIdMutation()

    const [openModalAddTrader, setOpenModalAddTrader] = useState(false);
    const [openModalSettingsTrader, setOpenModalSettingsTrader] = useState(false);

    const [idTrader, setIdTrader] = useState('');
    const [textValue, setTextValue] = useState('');
    const [nameTrader, setNameTrader] = useState('');
    const [linkTrader, setLinkTrader] = useState('');
    const [strategy, setStrategy] = useState('');
    const [hash, setHash] = useState('');
    const [textBtn, setTextBtn] = useState('Скопировать');

    const [errorValues, setErrorValues] = useState<any>({
        description: {
            isError: false,
            text: null
        },
        name: {
            isError: false,
            text: null
        },
        strategy: {
            isError: false,
            text: null
        },
        url: {
            isError: false,
            text: null
        },
    })

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handlerCopy = (copy: any) => {
        navigator.clipboard.writeText(copy)
            .then(() => {
                setTextBtn('Скопировано')
            })
            .then(() => {
                setTimeout(() => {
                    setTextBtn('Скопировать')
                }, 5000)
            })
    }

    const setSettingsDefaultValueHandler = async (id: any) => {
        if (id !== null) {
            try {
                const res: any = await traderById({
                    id: id
                })
                setNameTrader(res?.data?.data?.name)
                setStrategy(res?.data?.data?.strategy)
                setHash(res?.data?.data?.hash)
                setTextValue(res?.data?.data?.description)
                setLinkTrader(res?.data?.data?.url)
                setOpenModalSettingsTrader(true)
            } catch (e) {
                console.log(e)
            }
        } else {
            setNameTrader('')
            setStrategy('')
            setTextValue('')
            setLinkTrader('')
            setHash('')
        }


    }

    const closeModal = () => {
        setOpenModalAddTrader(false)
        setOpenModalSettingsTrader(false)
        setErrorValues({})
        setTextValue('')
        setStrategy('')
        setNameTrader('')
        setLinkTrader('')
        setHash('')
    }

    const handleAddTrader = () => {
        if (textValue === '' || nameTrader === '' || linkTrader === '' || strategy === '') {
            setErrorValues({
                description: {
                    isError: textValue === '' ? true : false,
                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                },
                name: {
                    isError: nameTrader === '' ? true : false,
                    text: nameTrader === '' ? 'Поле обязательно к заполнению' : null,
                },
                url: {
                    isError: linkTrader === '' ? true : false,
                    text: linkTrader === '' ? 'Поле обязательно к заполнению' : null,
                },
                strategy: {
                    isError: strategy === '' ? true : false,
                    text: strategy === '' ? 'Поле обязательно к заполнению' : null,
                },
            })
        } else {
            addTrader({
                name: nameTrader,
                description: textValue,
                url: linkTrader,
                strategy: strategy
            }).then((res: any) => {

                if (res.error) {
                    let errors: any = {};
                    for (let key in res.error.data.errors) {
                        errors[key] = {
                            isError: true,
                            text: res.error.data.errors[key][0]
                        }
                    }
                    setErrorValues(errors)
                } else {
                    closeModal()
                }

            })
        }
    }

    const handleUpdateTrader = async () => {
        if (textValue === '' || nameTrader === '' || linkTrader === '' || strategy === '') {
            setErrorValues({
                description: {
                    isError: textValue === '' ? true : false,
                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                },
                name: {
                    isError: nameTrader === '' ? true : false,
                    text: nameTrader === '' ? 'Поле обязательно к заполнению' : null,
                },
                url: {
                    isError: linkTrader === '' ? true : false,
                    text: linkTrader === '' ? 'Поле обязательно к заполнению' : null,
                },
                strategy: {
                    isError: strategy === '' ? true : false,
                    text: strategy === '' ? 'Поле обязательно к заполнению' : null,
                },
            })
        } else {
            try {
                const res = await updateTrader({
                    id: idTrader,
                    body: {
                        name: nameTrader,
                        description: textValue,
                        url: linkTrader,
                        strategy: strategy
                    }
                })
                closeModal()
            } catch (e) {
                console.log(e)
            }
        }
    }

    const changeStatusTrader = (id: any, active: any) => {
        return changeAdminTradersStatus({
            id,
            body: {
                is_active: active
            }
        })
    }

    return (
        <Stack spacing={7}>
            <Button
                fullWidth
                onClick={async () => {
                    setSettingsDefaultValueHandler(null)
                    setOpenModalAddTrader(true)
                }}
                startIcon={<IconPlus/>}
                sx={{height: 48, justifyContent: 'flex-start', color: '#BDBDBD'}}
            >
                Добавить трейдера
            </Button>
            {
                data &&
                //@ts-ignore
                data?.data?.map((item: any) =>

                    <TraderItem
                        changeStatusTrader={changeStatusTrader}
                        isActive={item.is_active}
                        showEyes={true}
                        key={item.id}
                        setSettingsDefaultValueHandler={setSettingsDefaultValueHandler}
                        isSelect
                        idTraderSubscribe={item.id}
                        id={item.id}
                        name={item.name}
                        graph={item.graph}
                        strategy={item.strategy}
                        stats={item.stats}
                        deleteTrader={deleteTrader}
                        openModal={setOpenModalSettingsTrader}
                        idTrader={setIdTrader}
                    />
                )
            }

            {
                //@ts-ignore
                data?.meta?.pagination?.total_pages > 1 &&
                <Pagination
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    //@ts-ignore
                    count={data?.meta?.pagination?.total_pages}
                    variant="outlined"
                    shape="rounded"
                    sx={{mr: 'auto'}}
                />
            }
            <SimpleModal maxWidth={780} title="Добавить трейдера" openModal={openModalAddTrader}
                         closeModal={closeModal}>
                <Stack spacing={7}>
                    <TextField
                        error={errorValues?.name?.isError || false}
                        helperText={errorValues?.name?.isError && errorValues?.name?.text}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={nameTrader}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['name']: {
                                    isError: nameTrader === '' ? true : false,
                                    text: nameTrader === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        onChange={(e) => {
                            setNameTrader(e.target.value)
                            setErrorValues({
                                ...errorValues, ['name']: {
                                    isError: false
                                }
                            })
                        }}
                        label="Название трейдера"
                        type="text"
                    />
                    <TextField
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={errorValues?.url?.isError || false}
                        helperText={errorValues?.url?.isError && errorValues?.url?.text}
                        value={linkTrader}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['url']: {
                                    isError: linkTrader === '' ? true : false,
                                    text: linkTrader === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        onChange={(e) => {
                            setLinkTrader(e.target.value)
                            setErrorValues({
                                ...errorValues, ['url']: {
                                    isError: false
                                }
                            })
                        }}
                        label="Ссылка на трейдера"
                        type="text"
                    />
                    <Stack sx={{
                        position: 'relative'
                    }} direction="row" alignItems="center" justifyContent="space-between">
                        <span className="subHeaders white-90">Стратегия</span>
                        <CustomSelect
                            smallError
                            isError={errorValues?.strategy?.isError || false}
                            // helperText={errorValues?.strategy?.isError && errorValues?.strategy?.text}
                            width={112}
                            defaultValue="Стратегия"
                            options={[{id: 'grid', title: 'grid'}, {id: 'stoploss', title: 'stoploss'}]}
                            optionValue={setStrategy}
                        />
                    </Stack>
                    <TextField
                        value={textValue}
                        error={errorValues?.description?.isError || false}
                        helperText={errorValues?.description?.isError && errorValues?.description?.text}
                        multiline
                        minRows={10}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['description']: {
                                    isError: textValue === '' ? true : false,
                                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        onChange={(e) => {
                            setTextValue(e.target.value)
                            setErrorValues({
                                ...errorValues, ['description']: {
                                    isError: false
                                }
                            })
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={7}>
                        <Button onClick={closeModal} color="error">Отмена</Button>
                        <Button
                            onClick={handleAddTrader}
                            color="success">Подтвердить</Button>
                    </Stack>
                </Stack>
            </SimpleModal>
            <SimpleModal maxWidth={780} title="Настройки трейдера" openModal={openModalSettingsTrader}
                         closeModal={closeModal}>
                <Stack spacing={7}>
                    <TextField
                        error={errorValues?.name?.isError || false}
                        helperText={errorValues?.name?.isError && errorValues?.name?.text}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['name']: {
                                    isError: nameTrader === '' ? true : false,
                                    text: nameTrader === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={nameTrader}
                        onChange={(e) => {
                            setNameTrader(e.target.value)
                            setErrorValues({
                                ...errorValues, ['name']: {
                                    isError: false
                                }
                            })
                        }}
                        label="Название трейдера"
                        type="text"
                    />
                    <TextField
                        fullWidth
                        error={errorValues?.url?.isError || false}
                        helperText={errorValues?.url?.isError && errorValues?.url?.text}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['url']: {
                                    isError: linkTrader === '' ? true : false,
                                    text: linkTrader === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                (linkTrader !== '' && linkTrader) && <IconButton size="small"
                                                                                 sx={{
                                                                                     height: 25,
                                                                                     borderRadius: '0',
                                                                                     color: '#828282',
                                                                                     fontSize: 12
                                                                                 }}
                                                                                 onClick={() => window.open(linkTrader)}
                                >
                                    Открыть
                                </IconButton>

                        }}
                        value={linkTrader}
                        onChange={(e) => {
                            setLinkTrader(e.target.value)
                            setErrorValues({
                                ...errorValues, ['url']: {
                                    isError: false
                                }
                            })
                        }}
                        label="Ссылка на трейдера"
                        type="text"
                    />
                    <TextField
                        fullWidth
                        value={hash}
                        disabled
                        label="Хэш"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                <IconButton size="small"
                                            sx={{borderRadius: '0', height: 25, color: '#828282', fontSize: 12}}
                                            onClick={() => handlerCopy(hash)}
                                >
                                    {textBtn}
                                </IconButton>
                        }}
                        sx={{color: '#828282'}}
                    />
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <span className="subHeaders white-90">Стратегия</span>
                        <CustomSelect
                            smallError
                            isError={errorValues?.strategy?.isError || false}
                            width={112}
                            isSettingsParams={strategy || null}
                            defaultValue="Стратегия"
                            options={[{id: 'grid', title: 'grid'}, {id: 'stoploss', title: 'stoploss'}]}
                            optionValue={setStrategy}
                        />
                    </Stack>
                    <TextField
                        error={errorValues?.description?.isError || false}
                        helperText={errorValues?.description?.isError && errorValues?.description?.text}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['description']: {
                                    isError: textValue === '' ? true : false,
                                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        value={textValue}
                        multiline
                        minRows={10}
                        onChange={(e) => setTextValue(e.target.value)}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={7}>
                        <Button onClick={closeModal} color="error">Отмена</Button>
                        <Button
                            onClick={handleUpdateTrader}
                            color="success">Подтвердить</Button>
                    </Stack>
                </Stack>
            </SimpleModal>
        </Stack>
    );
};

export default TradersList;
