import React, {FC, useEffect} from 'react';
import {Typography, Stack, useMediaQuery, Chip, Select, MenuItem} from "@mui/material";
import {useGetProfileQuery} from "../store/API/profileApi";
import {NavLink, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../hooks/useRedux";
import {accountIdSlice} from "../store/slice/accountIdSlice";


interface T {
    heading?: string;
    accountNumber?: string;
    isSelect?: boolean;
    isLink?: boolean;
    typeAccount?: string
}


const Header: FC<T> = ({heading, isSelect, accountNumber, typeAccount, isLink}) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const {data, error, isLoading} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const accountIdLS = localStorage.getItem('AccountId')
    const location = useLocation()
    const id = location?.pathname?.split('/').pop()
    const {accountId, accountName} = useAppSelector(state => state.accountIdReducer)

    const {changeAccountId, changeAccountName} = accountIdSlice.actions
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (id && (!location.pathname.includes('trader-dashboard') && !location.pathname.includes('copy-trading'))) {
            console.log('id here', id)
            console.log('id here', data?.data?.accounts)
            localStorage.setItem('AccountId', id)
        }
    }, [id])

    useEffect(() => {
        if (accountId) return

        const account = data?.data?.accounts.filter((item: any) => item.id === (accountIdLS && (+accountIdLS || null)))
        dispatch(changeAccountId(accountIdLS ? +accountIdLS : data?.data?.accounts[0]?.id))
        dispatch(changeAccountName(account ? account[0]?.login : data?.data?.accounts[0]?.login))
        // dispatch(changeAccountName(accountIdLS ? data?.data?.accounts?.find((f: any) => +f.id === +accountIdLS)?.login : data?.data?.accounts[0]?.login))
    }, [data, isLoading, accountId, accountIdLS])

    const changeAccount = (e: any) => {
        localStorage.setItem('AccountId', e.target.value)
        const account = data?.data?.accounts.filter((item: any) => item.id === e.target.value)

        dispatch(changeAccountId(e.target.value))
        dispatch(changeAccountName(account[0].login))
    }
    return (
        <header className="header">
            <Stack direction={mediaQuery ? "row" : "column"} spacing="auto" alignItems="center">
                <Stack
                    direction={mediaQuery ? "row" : "column"}
                    spacing={mediaQuery ? 7 : 4}
                    alignItems="center"
                    sx={mediaQuery ? null : {mb: 4}}>
                    <Typography variant="h1">
                        <span>{heading}</span>
                        {
                            accountNumber &&
                            <span>
                            <span className="white-90"> / Счет </span>
                            <span>{accountNumber}</span>
                        </span>
                        }
                    </Typography>
                    {typeAccount && <Chip label={typeAccount} variant="outlined" color="success" sx={{
                        color: typeAccount.includes('Робот Aggressive') ? '#BB6BD9' : ''
                    }}/>}
                </Stack>
                <Stack className="subHeaders" direction={mediaQuery ? "row" : "column"} alignItems="center"
                       spacing={mediaQuery ? 7 : 4}>
                    {(isSelect && !isLoading) &&
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={7}>
                            <span className="white-80">Выбор&nbsp;счета</span>
                            <Select
                                onChange={changeAccount}
                                IconComponent={"select"}
                                value={((accountIdLS && +accountIdLS) && +accountIdLS) || data?.data?.accounts?.find((f: any) => f.id === (id ? +id : 0))?.id || accountId || data?.data?.accounts[0]?.id}
                                // value={data?.data?.accounts?.find((f: any) => f.id === 3)}
                                fullWidth
                            >
                                {
                                    data &&
                                    data?.data?.accounts?.map((item: any) =>
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {
                                                isLink ?
                                                    <NavLink to={`/exchange-account/${item.id}`} className="white-90">

                                                        {item.name || item.login}&nbsp;
                                                        (
                                                        <span
                                                            className={
                                                                item.deposit_load < 33 ?
                                                                    'green'
                                                                    : item.deposit_load < 66 ?
                                                                        'yellow'
                                                                        : 'red'
                                                            }
                                                        >
                                               {item.deposit_load}%
                                            </span>
                                                        )
                                                    </NavLink>
                                                    :
                                                    <>
                                                        {item.name || item.login}&nbsp;
                                                        (
                                                        <span
                                                            className={
                                                                item.deposit_load < 33 ?
                                                                    'green'
                                                                    : item.deposit_load < 66 ?
                                                                        'yellow'
                                                                        : 'red'
                                                            }
                                                        >
                                               {item.deposit_load}%
                                            </span>
                                                        )
                                                    </>
                                            }
                                        </MenuItem>
                                    )
                                }

                            </Select>
                        </Stack>
                    }
                </Stack>
            </Stack>
        </header>
    );
};

export default Header;
