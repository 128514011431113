

export const customMuiInputLabel={
    styleOverrides: {
        root: {
            fontSize: 12,
            fontWeight: 500,
            top:3
        }
    },
}as object