import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

type DataType = {
	pv: number;
	uv: number;
	d?: string;
};

type T = {
	index?: number;
	data: DataType[];
};

const ChartCard = ({ data = [], index }: T) => {
	useLayoutEffect(() => {
		let root = am5.Root.new(`chartdiv-${index}`);

		root?._logo?.dispose();
		root.setThemes([am5themes_Animated.new(root)]);

		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				wheelX: 'none',
				wheelY: 'none',
				cursor: am5xy.XYCursor.new(root, {}),
				pinchZoomX: false,
			})
		);

		let cursor = chart.set(
			'cursor',
			am5xy.XYCursor.new(root, {
				behavior: 'none',
			})
		);

		cursor.lineY.set('visible', false);
		cursor.lineX.set('stroke', am5.color(0xffffff));
		cursor.lineX.set('strokeWidth', 2);

		const dataItems = data.map((el: any, i: any) => ({
			...el,
			year: i,
			pv: el.pv + (el.uv / 100) * 95,
			tooltipPV: el.pv,
			// pv: (el.uv / el.pv) * 100,
		}));

		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: 'year',
				visible: false,
				tooltip: am5.Tooltip.new(root, {
					visible: false,
					marginBottom: 50,
					labelText: '[#fff]{d}',
					forceHidden: true,
				}),
				renderer: am5xy.AxisRendererX.new(root, {
					forceHidden: true,
				}),
			})
		);

		xAxis.data.setAll(dataItems);

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				visible: false,
				extraTooltipPrecision: 2,
				renderer: am5xy.AxisRendererY.new(root, {
					minGridDistance: 20,
					opposite: true,
				}),
			})
		);

		xAxis.get('renderer').grid.template.setAll({
			visible: false,
		});

		yAxis.get('renderer').grid.template.setAll({
			visible: false,
		});

		function createSeries(name: string, field: string, color: any, tooltipValue: any) {
			const series = chart.series.push(
				am5xy.LineSeries.new(root, {
					stroke: color,
					fill: color,
					name: name,
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: field,
					categoryXField: 'year',
				})
			);

			series.fills.template.setAll({
				fillOpacity: 0.1,
				strokeOpacity: 0.0,
				stroke: color,
				visible: true,
				strokeWidth: 1,
			});

			let tooltip = am5.Tooltip.new(root, {
				getFillFromSprite: false,
				labelText: tooltipValue,
				pointerOrientation: 'horizontal',
			});

			tooltip?.get('background')?.setAll({
				strokeOpacity: 1,
				stroke: am5.color(0x3c3c3c),
				fillOpacity: 1,
				fill: am5.color(0x1f1f1f),
			});

			series.set('tooltip', tooltip);

			series.data.setAll(dataItems);
			series.appear(1000);
		}

		createSeries('Cars', 'pv', am5.color(0x56ccf2), '[#56CCF2]{tooltipPV}%');
		createSeries('Motorcycles', 'uv', am5.color(0x6fcf97), '[#6FCF97]{valueY}$');
		createSeries('d', 'd', am5.color(0x6fcf97), '[#6FCF97]{valueY}$');

		chart.appear(1000, 100);

		return () => {
			root.dispose();
		};
	}, []);

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			id={`chartdiv-${index}`}
			style={{ width: '100%', height: '100%', userSelect: 'none' }}
		></div>
	);
};

export default ChartCard;
