import React from 'react';

const IconExit = () => {
    return (
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5293 11.9132H14.042V14.2958C14.042 14.4045 14.0731 14.5107 14.1311 14.601C14.1891 14.6913 14.2716 14.7617 14.368 14.8033C14.4644 14.8449 14.5705 14.8558 14.6729 14.8346C14.7753 14.8134 14.8693 14.7611 14.9432 14.6843L19.8717 9.55261C19.9705 9.44952 20.0261 9.30977 20.0261 9.16406C20.0261 9.01836 19.9705 8.87861 19.8717 8.77552L14.9432 3.64379C14.8679 3.56929 14.774 3.51853 14.6722 3.49738C14.5703 3.47623 14.4649 3.48556 14.3679 3.52429C14.2716 3.56602 14.1892 3.63656 14.1313 3.72698C14.0734 3.8174 14.0426 3.92366 14.0427 4.03233V6.41492H7.53001C7.38996 6.41492 7.25564 6.47285 7.15662 6.57596C7.05759 6.67908 7.00195 6.81893 7.00195 6.96475V11.3634C7.00195 11.5092 7.05759 11.6491 7.15662 11.7522C7.25564 11.8553 7.38996 11.9132 7.53001 11.9132H7.5293Z" fill="#828282"/>
            <path d="M9.5759 18.3277H3.23645C2.3877 18.3358 1.57055 17.993 0.964195 17.3745C0.357843 16.7561 0.0117986 15.9125 0.00195312 15.0288V3.29911C0.0117986 2.41538 0.357843 1.57179 0.964195 0.953357C1.57055 0.33492 2.3877 -0.00788043 3.23645 0.000137551H9.5759C10.4247 -0.00788043 11.2418 0.33492 11.8482 0.953357C12.4545 1.57179 12.8006 2.41538 12.8104 3.29911V4.03221C12.8104 4.32386 12.6991 4.60356 12.5011 4.80978C12.303 5.01601 12.0344 5.13187 11.7543 5.13187C11.4742 5.13187 11.2056 5.01601 11.0075 4.80978C10.8095 4.60356 10.6982 4.32386 10.6982 4.03221V3.29911C10.689 2.99847 10.5657 2.7139 10.3553 2.50776C10.1449 2.30163 9.86466 2.19076 9.5759 2.19945H3.23645C2.9477 2.19076 2.66741 2.30163 2.45704 2.50776C2.24666 2.7139 2.12336 2.99847 2.11416 3.29911V15.0288C2.12336 15.3294 2.24666 15.614 2.45704 15.8201C2.66741 16.0263 2.9477 16.1371 3.23645 16.1284H9.5759C9.86466 16.1371 10.1449 16.0263 10.3553 15.8201C10.5657 15.614 10.689 15.3294 10.6982 15.0288V14.2957C10.6982 14.004 10.8095 13.7243 11.0075 13.5181C11.2056 13.3119 11.4742 13.196 11.7543 13.196C12.0344 13.196 12.303 13.3119 12.5011 13.5181C12.6991 13.7243 12.8104 14.004 12.8104 14.2957V15.0288C12.8006 15.9125 12.4545 16.7561 11.8482 17.3745C11.2418 17.993 10.4247 18.3358 9.5759 18.3277Z" fill="#3C3C3C"/>
        </svg>

    );
};

export default IconExit;
