import React, {FC, useEffect, useState} from 'react';
import {useSetPaymentWalletRobotMutation} from "../../../store/API/productApi";
import {Box, Checkbox, Divider, Modal, Stack, TextField} from "@mui/material";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

const PaymentBalanceModal = ({
                                 open,
                                 handleClose,
                             }: any) => {
    const [step, setStep] = useState(1)
    const [link, setLink] = useState('')
    const [amount, setAmount] = useState(1)
    const [confirm, setConfirm] = useState(false)

    const [setPaymentWalletRobot, {isLoading}] = useSetPaymentWalletRobotMutation()

    const handleKeyDown = (e: any) => {
        const invalidChars = ['-', '+', 'e', 'E', '.', ','];

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleChange = (e: any) => {
        const value = e.target.value.replace(/[^\d]/g, ''); // Удаляет все символы, кроме цифр
        setAmount(value);
    };

    const handleConfirm = () => {
        setConfirm(true)
        if ((!amount || amount == 0 || amount < 1)) {
            return
        } else {
            setPaymentWalletRobot(amount)
                .unwrap()
                .then((res) => {
                    setLink(res?.payment_url)
                    setStep(2)
                    console.log(res?.payment_url)
                })
                .catch((e) => {
                    console.log(e)
                    toast.error(e?.data?.message ? e?.data?.message : 'Ошибка')
                })
        }

    }
    return (
        <>
            <Modal open={open} onClose={handleClose} sx={{
                "@media (max-width:980px)": {
                    maxWidth: '95%',
                    width: '100%',
                    margin: '0 auto',
                }
            }}>
                <Box sx={{maxWidth: 620}}>
                    <Stack onClick={handleClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90" sx={{mb: 7}}>Пополнение кошелька</Stack>
                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    <Stack spacing={7}>
                        <Stack className="h2 white-100" spacing={14}>
                            {step === 1 && <TextField
                                sx={{
                                    // Для веб-китов браузеров (Chrome, Safari, Edge)
                                    '& input[type=number]': {
                                        '-moz-appearance': 'textfield',
                                        '-webkit-appearance': 'none',
                                        appearance: 'textfield',
                                    },
                                    // Для Firefox
                                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                }}
                                required={true}
                                fullWidth
                                value={amount}
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                helperText={((!amount || amount == 0 || amount < 1) && confirm) && ("Поле обязательно к заполнению")}
                                label={'Введите сумму пополнения'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="number"
                                inputProps={{min: 1}}
                            />}
                            {step === 2 && <>

                                        <span>
                                            <span>Ваша ссылка на пополнение</span>
                                            <a href={link} target={'_blank'}>
                                            <span className="green">&nbsp;{link}</span>
                                                </a>
                                        </span>

                            </>}

                        </Stack>
                        <Stack justifyContent="flex-end">
                            {step === 1 && <Button
                                sx={{ml: 'auto', maxWidth: 268}}
                                color="success"
                                disabled={isLoading}
                                onClick={handleConfirm}
                                // component={Link} target="_blank"
                                // to={'dataPaymentLink?.payment_url'}
                            >Пополнить</Button>}

                            {step === 2 && <Button
                                sx={{ml: 'auto', maxWidth: 268}}
                                color="success"
                                onClick={handleClose}
                                disabled={false}
                                component={Link} target="_blank"
                                to={link}
                            >Перейти и оплатить</Button>}
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default PaymentBalanceModal;
