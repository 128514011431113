import React, {FC, useEffect, useState} from 'react';
import PersonalData from "../../entities/components/personalData";
import {Chip, Grid, IconButton, TextField} from "@mui/material";
import SelfEmployment from "../../entities/components/selfEmployment";
import IconAccount from "../../shared/assets/images/icons/iconAccount";
import s from './styles.module.css'
import Button from "@mui/material/Button";
import {useGetLinkRefProfileQuery, useSetLinkRefProfileMutation} from "../../store/API/userApi";
import {toast} from "react-toastify";

interface IType {
    children?: any
}

const PersonalSetting: FC<IType> = ({children}) => {
    const {data, isLoading} = useGetLinkRefProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [setLinkRefProfile, {isLoading: loadingBtn}] = useSetLinkRefProfileMutation()

    const [valueRef, setValueRef] = useState<string>('')
    const [textBtn, setTextBtn] = useState('Скопировать');

    const handlerCopy = (copy: any) => {
        navigator.clipboard.writeText(copy)
            .then(() => {
                setTextBtn('Скопировано')
            })
            .then(() => {
                setTimeout(() => {
                    setTextBtn('Скопировать')
                }, 5000)
            })
    }

    const setRefLink = async () => {
        try {
            const res = await setLinkRefProfile(valueRef)
            toast.success('Успешно', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } catch (e) {
            toast.error('Ошибка', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            console.log(e)
        }
    }

    useEffect(() => {
        setValueRef(data?.url || '')
    }, [isLoading])

    return (
        <div className={s.settings}>
            <div className={s.ref_box}>
                <h3 className={s.title}>Реферальная система</h3>

                <div className={s.line}/>

                <div className={s.ref_box_action}>
                    <TextField
                        fullWidth
                        value={valueRef}
                        onChange={(e) => setValueRef(e.target.value)}
                        label="Forex ссылка"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // InputProps={{
                        //     endAdornment:
                        //         <IconButton size="small"
                        //                     sx={{borderRadius: '0', height: 25, color: '#828282', fontSize: 12}}
                        //                     onClick={() => handlerCopy(valueRef)}
                        //         >
                        //             {textBtn}
                        //         </IconButton>
                        // }}
                        sx={{color: '#828282'}}
                    />

                    <Button disabled={loadingBtn} onClick={setRefLink} sx={{width: '100%', marginTop: '14px'}}
                            color={'success'}>Сохранить</Button>
                </div>
            </div>
            <div className={s.ref_box2}></div>
        </div>
    );
};

export default PersonalSetting;
