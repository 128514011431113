import logo from '../shared/assets/images/logoFooter.svg'
import logo_footer from '../shared/assets/images/logo_footer.png'
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";


const Footer = () => {
    return (
        <footer className="footer">
            <div className={'footer_top'}>
                <div className={'footer_top_left'}>
                    <img className={'footer_top_left_title'} src={logo_footer} alt={logo}/>
                    <p className={'footer_top_left_subtitle'}>Система копирования сделок</p>
                </div>
                <img className={'footer_top_right'} src={logo} alt={logo}/>
            </div>

            <div className={'footer_middle'}>
                <div>
                    <div className={'footer-box'}>
                        <Button target={'_blank'} component={Link} to={`https://copyten.ru/docs/terms_of_use.pdf`}
                                color="neutral" sx={{
                            marginRight: '10px',
                            width: '245px',
                            "@media (max-width:560px)": {
                                width: '100%',
                                margin: '0px 0 10px 0',
                                marginRight: '0px',
                            }
                        }}>Пользовательское соглашение</Button>
                        <Button target={'_blank'} component={Link} to={`https://copyten.ru/docs/privacy_policy.pdf`}
                                sx={{
                                    width: '245px',
                                    "@media (max-width:560px)": {
                                        width: '100%',
                                    }
                                }} color="neutral">Политика конфиденциальности</Button>
                    </div>

                    <div className={'footer-box'} style={{
                        marginTop: '10px'
                    }}>
                        <Button target={'_blank'} component={Link} to={`https://copyten.ru/docs/referral.pdf`}
                                color="neutral" sx={{
                            marginRight: '10px',
                            width: '245px',
                            "@media (max-width:560px)": {
                                minWidth: '100%',
                                marginBottom: '10px',
                                marginRight: '0px',
                            }
                        }}>Партнёрская программа</Button>
                        <Button target={'_blank'} component={Link} to={`https://copyten.ru/docs/cookie.pdf`} sx={{
                            width: '245px',
                            "@media (max-width:560px)": {
                                minWidth: '100%',
                            }
                        }} color="neutral">Политика использования cookie</Button>
                    </div>

                </div>
                <p className={'footer_middle_right'}>
                    Общество с ограниченной ответственностью «ПЛАТФОРМА» <br/>
                    (ООО «Платформа») <br/>
                    ИНН: 7224082796 <br/>
                    КПП: 722401001 <br/>
                    Юридический адрес: 625509, Тюменская область, <br/>
                    р-н Тюменский, п Новотарманский, <br/>
                    ул. Янтарная, д. 7. <br/>
                    89224781578 и platformofficial@yandex.ru. <br/></p>
            </div>

            <div className={'footer_bottom'}>
                <p className={'footer_bottom_title'}>Общество с ограниченной ответственностью «ПЛАТФОРМА»
                    (ООО «Платформа»)
                </p>
                <p className={'footer_bottom_subtitle'}>Copyten</p>
            </div>
        </footer>
    );
};

export default Footer;
