import React, {FC} from 'react';

interface IType {
    children?: any
}

const IconSet: FC<IType> = ({children}) => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0001 16H1.00001C0.734594 16.0025 0.479354 15.9039 0.292331 15.7267C0.105307 15.5494 0.00239563 15.3085 0.00700004 15.0587H0V1.88234C0 0.842753 0.895435 0 2.00001 0H8.00004C8.2234 0.00020046 8.44026 0.0707741 8.61605 0.200469L10.9001 1.88234H16.0001C17.1047 1.88234 18.0001 2.72509 18.0001 3.76468V7.52937H19.0001C19.3362 7.5294 19.6499 7.68838 19.8349 7.95251C20.0199 8.21664 20.0515 8.55058 19.9191 8.84136L16.9191 15.4296C16.7615 15.7755 16.4 16 16.0001 16ZM4.66002 9.41171L2.51701 14.1176H15.3411L17.4841 9.41171H4.66002ZM2.00001 3.76468V10.4752L3.08102 8.09972C3.2386 7.75374 3.60006 7.52941 4.00002 7.52937H16.0001V3.76468H2.00001Z"
                fill="#828282"/>
        </svg>
    );
};

export default IconSet;
