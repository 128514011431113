import React, {useLayoutEffect, useMemo} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const CopytradingChart = ({dataChart, id, height, marginBottom}: any) => {

    const data = useMemo(() => {
        return dataChart?.map((el: any, i: number) => {
            return ({
                ...el, index: i,
            })
        })

    }, [dataChart])

    useLayoutEffect(() => {
        let root = am5.Root.new(`copytrading-chart-${id}`);

        root._logo?.dispose()
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            cursor: am5xy.XYCursor.new(root, {}),
            pinchZoomX: false,
            height: am5.percent(100)
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none",
        }));

        cursor.lineY.set("visible", false);
        cursor.lineX.set("stroke", am5.color(0xffffff));
        cursor.lineX.set("strokeWidth", 2);

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "index",
            forceHidden: true,
            visible: false,

            renderer: am5xy.AxisRendererX.new(root, {
                forceHidden: true,
                visible: false,
            }),
            tooltip: am5.Tooltip.new(root, {
                visible: false,
                forceHidden: true,
            }),
            startLocation: 0.5,
            endLocation: 0.5,
        }));

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            extraTooltipPrecision: 2,
            numberFormat: "#'%'",
            forceHidden: true,
            visible: false,
            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 20,
                opposite: true,
                visible: false,
                forceHidden: true,
            })
        }));

        yAxis.get("renderer").labels.template.setAll({
            fontSize: "0",
            rotation: 20,
            forceHidden: true,
            visible: false,
        });

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "uv",
            categoryXField: "index",
        }));

        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            labelText: "[bold][#6FCF97]{valueY}%[/]",
            pointerOrientation: 'horizontal'
        });

        tooltip?.get("background")?.setAll({
            strokeOpacity: 1,
            fillOpacity: .5,

        });

        series.set("tooltip", tooltip);
        series.set("fill", am5.color(0x6FCF97));
        series.set("stroke", am5.color(0x6FCF97));

        series.fills.template.setAll({
            fillOpacity: .1,
            strokeOpacity: 0,
            fill: am5.color(0x6FCF97),
            stroke: am5.color(0x6FCF97),
            visible: true,
            strokeWidth: 2,
        });

        series?.get("background")?.setAll({
            strokeOpacity: 1,
            stroke: am5.color(0x6FCF97),
            fillOpacity: .5,
            fill: am5.color(0x6FCF97),

        });

        xAxis.get("renderer").grid.template.setAll({
            stroke: am5.color(0xffffff),
            visible: false,
        })

        yAxis.get("renderer").grid.template.setAll({
            stroke: am5.color(0xffffff),
            visible: false,
        })

        series.data.setAll(data);

        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [data]);
    return (

        <div id={`copytrading-chart-${id}`} style={{
            minWidth: "calc(100% + 40px)",
            marginLeft: '-20px',
            minHeight: height,
            marginBottom: marginBottom
        }}/>

    );
};

export default CopytradingChart;
