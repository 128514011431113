import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface T {
	data?: any;
	dataTableHead?: string[];
}

const DashboardTable: FC<T> = ({ data, dataTableHead }) => {
	return (
		<>
			<TableContainer
				sx={{
					position: 'relative !important',
				}}
			>
				<Table>
					<TableHead>
						<TableRow>
							{dataTableHead &&
								dataTableHead.map((item: any, index: any) => (
									<TableCell key={item + index} sx={{ color: '#BDBDBD' }}>
										{item}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							width: '100%',
						}}
					>
						{data?.years?.map((item: any) => (
							<TableRow key={item.year}>
								<TableCell
									sx={{
										color: '#fff',
										minWidth: '38px !important',
									}}
								>
									{item.year}
								</TableCell>
								{item.data?.map((item: any, index: any) => (
									<TableCell sx={{ minWidth: '38px !important' }} key={index} className={item > 0 ? 'green' : item < 0 ? 'red' : ''}>
										{item ? item : '-'}
									</TableCell>
								))}
							</TableRow>
						))}

						<TableRow
							sx={{
								position: 'sticky !important',
								left: '0px !important',
							}}
						>
							<TableCell
								colSpan={12}
								sx={{
									color: '#fff',
									borderTop: '0.5px solid #828282 !important',
									padding: '1px !important',
									height: '100% !important',
									width: '100% !important',
								}}
							>
								Всего
							</TableCell>
							<TableCell
								colSpan={2}
								className={data?.all_time > 0 ? 'green' : 'red'}
								sx={{
									borderTop: '0.5px solid #828282 !important',
									// minWidth: '48px !important',
								}}
							>
								{data?.all_time}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default DashboardTable;
