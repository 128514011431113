const IconEdit = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.440721 8C0.317581 7.99979 0.200193 7.94785 0.117197 7.85685C0.03267 7.7666 -0.00933166 7.64456 0.00174616 7.52139L0.109295 6.33846L5.07762 1.37054L6.63027 2.92318L1.66327 7.89067L0.480668 7.99824C0.46706 7.99956 0.453452 8 0.440721 8ZM6.94019 2.61274L5.38797 1.0601L6.31904 0.128781C6.40138 0.0463288 6.5131 0 6.62961 0C6.74612 0 6.85785 0.0463288 6.94019 0.128781L7.87125 1.0601C7.95368 1.14246 8 1.25422 8 1.37076C8 1.4873 7.95368 1.59906 7.87125 1.68142L6.94063 2.6123L6.94019 2.61274Z"
                fill="#828282"/>
        </svg>

    );
};

export default IconEdit;
