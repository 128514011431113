import React from 'react';
import { Box, Checkbox, Divider, Modal, Stack } from '@mui/material';
import IconClose from '../../../shared/assets/images/icons/iconClose';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const RobotModalEnableDisable = ({ open, handleClose, handleConfrim, isFetching, isActive, login }: any) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			sx={{
				'@media (max-width:980px)': {
					maxWidth: '95%',
					width: '100%',
					margin: '0 auto',
				},
			}}
		>
			<Box sx={{ maxWidth: 620 }}>
				<Stack onClick={handleClose} sx={{ position: 'absolute', top: 14, right: 28, cursor: 'pointer' }}>
					<IconClose />
				</Stack>
				<Stack className='h2 white-90' sx={{ mb: 7 }}>
					Робот
				</Stack>
				<Divider variant='fullWidth' sx={{ mb: 7 }} />
				<Stack spacing={7}>
					{isActive && (
						<p>
							Вы уверены что хотите отключить <span className='pink'>робот</span> ? Если у вас остались открытые сделки, то необходимо закрыть их
							вручную.
						</p>
					)}
					{!isActive && (
						<p>
							Вы уверены что хотите включить продукт <span className='pink'>робот</span> ?
						</p>
					)}
				</Stack>
				<Stack direction='row' justifyContent='flex-end' spacing={7} sx={{ mt: 7 }}>
					<Button onClick={handleClose} color='error'>
						Нет
					</Button>

					<Button onClick={handleConfrim} disabled={isFetching} color='success'>
						Да
					</Button>
				</Stack>
			</Box>
		</Modal>
	);
};

export default RobotModalEnableDisable;
