import {Box, Button, Checkbox, Divider, Modal, Stack} from "@mui/material";
import {FC, useEffect, useState} from "react";
import * as React from "react";
import IconClose from "../../../shared/assets/images/icons/iconClose";
import {useAppSelector} from "../../../hooks/useRedux";
import {
    useSubscribeTraderMutation,
    useUpdateSettingsTraderMutation
} from "../../../store/API/tradersUserApi";
import {toast} from "react-toastify";
import {useGetProfileQuery} from "../../../store/API/profileApi";


interface IType {
    maxWidth?: number;
    closeModal: any;
    openModal: boolean;
    step?: number;
    setStep?: (number: number) => void;
    idTrader?: any
    nameTrader?: any
    idAccount?: any
    nameAccount?: any
    isError?: any
    handleClosePrev?: any
}

const CopyTradingModalChild: FC<IType> = ({
                                              handleClosePrev,
                                              maxWidth,
                                              openModal,
                                              closeModal,
                                              step,
                                              setStep,
                                              idTrader,
                                              nameTrader,
                                              idAccount,
                                              nameAccount,
                                              isError,
                                          }) => {
    const [open, setOpen] = useState(false);
    const {
        risk,
        maxLot,
        minLot,
        excludeSymbols,
        excludeDays,
        excludeHours
    } = useAppSelector(state => state.setParametersReducer)
    const {refetch} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [subscribe, {error, isLoading}] = useSubscribeTraderMutation()
    const [updateSettings] = useUpdateSettingsTraderMutation()
    const {data} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const [accept, setAccept] = useState<boolean>(false)

    const findAccount = (data && data?.data?.accounts?.length > 0) ? data?.data?.accounts?.find((f: any) => f.id === idAccount) : ''

    useEffect((() => {
        setOpen(openModal)
    }), [open, openModal])

    const handleClose = () => {
        closeModal(false)
        setOpen(false);
    };

    const handleSubscribe = () => {
        if (idAccount) {
            subscribe({
                idTrader,
                idAccount,
                body: {
                    settings: {
                        main: {
                            drying: false,
                            min_risk: 0,
                            risk: risk,
                            max_lot: maxLot,
                            min_lot: minLot,
                            exclude_symbols: excludeSymbols,
                            exclude_days: excludeDays,
                            exclude_hours: excludeHours
                        }
                    }
                }
            }).then((res: any) => {
                if (res.error) {
                    for (let key in res.error.data.errors) {
                        toast.error(res.error.data.errors[key][0] || "Ошибка при подключении!", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }

                    handleClose()
                    return
                }
                handleClose()
                isError(true)
                if (!error && !isLoading && step && setStep) {
                    refetch()
                    toast.success("Успешно!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    handleClosePrev && handleClosePrev()
                }

            }).catch((e) => {
                console.log(e)
            })
        } else {
            updateSettings({
                idTrader,
                body: {
                    main: {
                        drying: false,
                        min_risk: 0,
                        risk: risk,
                        max_lot: maxLot,
                        min_lot: minLot,
                        exclude_symbols: excludeSymbols,
                        exclude_days: excludeDays,
                        exclude_hours: excludeHours,
                    },
                    symbols: []
                }
            }).then((res: any) => {
                if (res.error) {
                    for (let key in res.error.data.errors) {
                        toast.error(res.error.data.errors[key][0] || "Ошибка при подключении!", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }

                    handleClose()
                    return
                }
                handleClose()
                if (!error && !isLoading && step && setStep) {
                    refetch()
                    toast.success("Успешно!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    handleClosePrev && handleClosePrev()
                }
            })
        }

    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >

            <Box sx={{maxWidth: maxWidth ? maxWidth : 620}}>

                <Stack onClick={handleClose} sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                    <IconClose/>
                </Stack>
                <Stack className="h2 white-90" sx={{mb: 7}}>Подключение трейдера</Stack>

                <Divider variant="fullWidth" sx={{mb: 7}}/>

                {
                    idAccount ?
                        <div className="h2">
                            <span>Подключить трейдера</span>
                            <span className="green">&nbsp;{nameTrader}&nbsp;</span>
                            <span>на счет</span>
                            <span
                                className="blue">&nbsp;{findAccount !== '' ? (findAccount.name || findAccount.login) : ''}&nbsp;</span>
                            <span>?</span>

                            <div style={{
                                marginTop: "15px"
                            }}>
                                <i style={{
                                    fontSize: '12px',
                                    fontWeight: 400
                                }}>В соответствии с пунктом 3.5 <a href="https://copyten.ru/docs/terms_of_use.pdf"
                                                                   target={'_blank'} style={{
                                    color: '#4c8f8f',
                                    textDecoration: 'underline'
                                }}> Пользовательского
                                    соглашения </a> Пользователь
                                    самостоятельно осуществляет сделки на свой страх и риск и Компания не несет
                                    ответственность за результаты указанных сделок.</i>
                                <div style={{
                                    marginTop: '14px',
                                }}>
                                    <Checkbox checked={accept} onClick={() => setAccept(!accept)}/>
                                    <span style={{
                                        fontSize: '12px',
                                        marginLeft: '10px'
                                    }}>Согласен(а)</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="h2">
                            <span>Сохранить настройки трейдера</span>
                            <span className="green">&nbsp;{nameTrader}&nbsp;</span>
                            <span>для счета</span>
                            <span className="blue">&nbsp;{nameAccount}&nbsp;</span>
                            <span>?</span>
                        </div>
                }


                <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>
                    <Button onClick={handleClose} color="error">Отклонить</Button>
                    <Button onClick={handleSubscribe} disabled={!accept && idAccount}
                            color="success">Продолжить</Button>
                </Stack>
            </Box>
        </Modal>

    );
};

export default CopyTradingModalChild;
