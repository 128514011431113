import React, {useEffect, useState} from 'react';
import s from "../styles.module.css";
import {styled, Switch} from "@mui/material";
import {NavLink} from "react-router-dom";

const PinkSwitch = styled(Switch)(({theme, colorTheme}: any) => {

        return {
            '& .MuiSwitch-switchBase.Mui-checked': {
                color: colorTheme,
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: colorTheme,
            },
        }
    }
);

const SwitchBox = ({dataTradersChart, keyO, color, chartRef, index}: any) => {
    const [checked, setCheckedValue] = useState<any>({})

    useEffect(() => {
        let obj: any = {}
        for (let key in dataTradersChart) {
            obj[key] = true
        }
        setCheckedValue(obj)
    }, [dataTradersChart])

    return (
        <div className={s.action}>
            <div className={s.text_box}>
                <p><NavLink style={{color: 'inherit'}} target={'_blank'}
                            to={`/trader-dashboard/${keyO?.id}`}> {`Трейдер ${keyO?.name}`}</NavLink> <span style={{
                    color: `#${color[keyO.key]}`
                }}>{keyO?.gain}%</span></p>
            </div>
            <div>
                <PinkSwitch
                    // @ts-ignore
                    colorTheme={`#${color[keyO.key]}`}
                    checked={checked[keyO.key]}
                    onChange={(e) => {
                        const series = chartRef!.current!.series!.getIndex(index)
                        setCheckedValue({...checked, [keyO.key]: e.target.checked})
                        if (!e.target.checked) {
                            series.hide()
                        } else {
                            series.show()
                        }
                    }}
                    defaultChecked={true}
                />
            </div>
        </div>
    );
};

export default SwitchBox;
