const IconNewProduct = () => {
    return (
        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.1616 14.4758V16.271C10.1613 16.2953 10.1677 16.3193 10.1803 16.3404C10.1929 16.3615 10.2111 16.3789 10.233 16.3908L11.682 17.202C11.7774 17.2554 11.8961 17.189 11.8961 17.0821V14.685L17.8768 11.337L19.7622 12.3924C19.9134 12.477 20 12.6224 20 12.7918V18.9553C20 19.1247 19.9134 19.2701 19.7622 19.3547L14.2571 22.4365C14.1058 22.5212 13.9326 22.5212 13.7814 22.4365L8.27628 19.3547C8.12501 19.2701 8.03844 19.1247 8.03844 18.9553V13.3777L9.73709 14.3286C9.86819 14.4023 10.0121 14.4522 10.1616 14.4758ZM10.5933 0.563512C10.4421 0.478829 10.2689 0.478829 10.1177 0.563512L4.61255 3.6453C4.46128 3.72998 4.3747 3.87541 4.3747 4.04473V10.2083C4.3747 10.3776 4.46128 10.523 4.61255 10.6077L10.1176 13.6895C10.2689 13.7742 10.4421 13.7742 10.5933 13.6895L16.0984 10.6077C16.2497 10.523 16.3363 10.3776 16.3363 10.2083V4.04473C16.3363 3.87537 16.2497 3.72998 16.0984 3.6453L14.2131 2.5899L8.23233 5.93796V8.3351C8.23233 8.44196 8.11374 8.50834 8.01827 8.45492L6.56926 7.64375C6.54733 7.63187 6.52913 7.61445 6.51657 7.59336C6.50401 7.57227 6.49756 7.54828 6.49791 7.52392V4.96702L12.4786 1.61896L10.5933 0.563512ZM3.6136 8.37974L2.12316 9.21412V11.771C2.12281 11.7954 2.12926 11.8194 2.14182 11.8405C2.15438 11.8616 2.17258 11.879 2.19451 11.8909L3.64352 12.702C3.73894 12.7555 3.85758 12.6891 3.85758 12.5822V10.9259C3.95866 11.0564 4.08603 11.1655 4.23195 11.2468L7.27728 12.9516V17.3439L6.21853 17.9366C6.06726 18.0213 5.89411 18.0213 5.74284 17.9366L0.237846 14.8548C0.086576 14.7702 0 14.6248 0 14.4554V8.29183C0 8.12247 0.086576 7.97709 0.237846 7.8924L3.6136 6.00268V8.37974Z"
                  fill="#828282"/>
        </svg>

    );
};

export default IconNewProduct;
