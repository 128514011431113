import React, {FC} from 'react';

interface IType {
    status: any;
}

const IconProduct: FC<IType> = ({status}) => {
    return (
        <>
            {
                status === 1 ?
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1250_52470)">
                            <path
                                d="M34 17H31.5V4.875H34V17ZM29.8333 17H27.3333V2.4375H29.8333V17ZM25.6667 17H23.1667V0H25.6667V17ZM21.5 17H19V6.5H21.5V17Z"
                                fill="#6FCF97"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M17.0001 7.27051L28.5743 13.9529V27.3177L17.0001 34.0001L5.42578 27.3177V13.9529L17.0001 7.27051Z"
                                  fill="#D5A889"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M5.42578 13.9526V27.3175L17 33.9999V20.6351L5.42578 13.9526Z" fill="#937661"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M17.0001 7.27051L28.5743 13.9529L17.0001 20.6353L5.42578 13.9529L17.0001 7.27051Z"
                                  fill="#E8BF9D"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.60352 21.9337L12.8232 23.7925V18.2239L9.60352 16.365V21.9337ZM21.1778 9.68262L24.3975 11.5415L12.8232 18.2239L9.60352 16.365L21.1778 9.68262Z"
                                  fill="#EFC7A4"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.60352 16.3652V21.9339L12.8232 23.7927V18.2241L9.60352 16.3652Z" fill="#D5A889"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1250_52470">
                                <rect width="34" height="34.0002" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    : status === 0 ?
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1250_52506)">
                                <path
                                    d="M25.4915 17C20.7982 16.9953 16.9969 13.1876 17 8.49433C17.0031 3.80102 20.8095 -0.00156191 25.5028 4.8129e-07C30.1961 0.00156384 34 3.80668 34 8.5C33.9972 13.1966 30.1881 17.0019 25.4915 17ZM18.7 8.64619C18.7402 12.3872 21.7949 15.3931 25.5362 15.3731C29.2774 15.3529 32.2996 12.3144 32.2996 8.57309C32.2996 4.83183 29.2774 1.7933 25.5362 1.77309C21.7949 1.75308 18.7402 4.75894 18.7 8.49999V8.64619ZM26.35 12.75H24.65V11.05H26.35V12.75ZM26.35 9.34999H24.65V4.25H26.35V9.34999Z"
                                    fill="#FF8888"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M17.0001 7.27051L28.5743 13.9529V27.3177L17.0001 34.0001L5.42578 27.3177V13.9529L17.0001 7.27051Z"
                                      fill="#D5A889"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M5.42578 13.9526V27.3175L17 33.9999V20.6351L5.42578 13.9526Z" fill="#937661"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M17.0001 7.27051L28.5743 13.9529L17.0001 20.6353L5.42578 13.9529L17.0001 7.27051Z"
                                      fill="#E8BF9D"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9.60352 21.9337L12.8232 23.7925V18.2239L9.60352 16.365V21.9337ZM21.1778 9.68262L24.3975 11.5415L12.8232 18.2239L9.60352 16.365L21.1778 9.68262Z"
                                      fill="#EFC7A4"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9.60352 16.3652V21.9339L12.8232 23.7927V18.2241L9.60352 16.3652Z" fill="#D5A889"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1250_52506">
                                    <rect width="34" height="34.0002" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        : status === 0 ?
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1250_52542)">
                                    <path
                                        d="M25.5 17C20.8056 17 17 13.1944 17 8.5C17 3.80558 20.8056 0 25.5 0C30.1944 0 34 3.80558 34 8.5C33.9948 13.1923 30.1923 16.9948 25.5 17ZM25.5 1.7C21.7445 1.7 18.7 4.74446 18.7 8.5C18.7 12.2555 21.7445 15.3 25.5 15.3C29.2555 15.3 32.3 12.2555 32.3 8.5C32.2958 4.74621 29.2538 1.70422 25.5 1.7ZM29.75 9.35H24.65V4.25H26.35V7.65H29.75V9.35Z"
                                        fill="#F2994A"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M17.0001 7.27051L28.5743 13.9529V27.3177L17.0001 34.0001L5.42578 27.3177V13.9529L17.0001 7.27051Z"
                                          fill="#D5A889"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M5.42578 13.9526V27.3175L17 33.9999V20.6351L5.42578 13.9526Z" fill="#937661"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M17.0001 7.27051L28.5743 13.9529L17.0001 20.6353L5.42578 13.9529L17.0001 7.27051Z"
                                          fill="#E8BF9D"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M9.60352 21.9337L12.8232 23.7925V18.2239L9.60352 16.365V21.9337ZM21.1778 9.68262L24.3975 11.5415L12.8232 18.2239L9.60352 16.365L21.1778 9.68262Z"
                                          fill="#EFC7A4"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M9.60352 16.3652V21.9339L12.8232 23.7927V18.2241L9.60352 16.3652Z"
                                          fill="#D5A889"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1250_52542">
                                        <rect width="34" height="34.0002" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            :
                            <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.323 16C20.8736 16.0013 20.4424 15.8227 20.1255 15.5041L13.4957 8.87336C13.1459 8.52449 12.9665 8.03981 13.0049 7.54723L13.428 1.99048C13.4884 1.15467 14.1543 0.490527 14.9903 0.432455L20.547 0.0093092C20.5901 0 20.6341 0 20.6773 0C21.1257 0.00115116 21.5555 0.179396 21.8731 0.495927L28.5038 7.12577C28.8213 7.44321 28.9998 7.87383 28.9998 8.32285C28.9998 8.77187 28.8213 9.20249 28.5038 9.51993L22.5197 15.5041C22.203 15.8225 21.7721 16.0011 21.323 16ZM20.6765 1.69258L15.1155 2.11573L14.6924 7.67671L21.323 14.3074L27.3063 8.32412L20.6765 1.69258ZM17.7322 6.42505C16.9247 6.42522 16.2296 5.85495 16.0719 5.063C15.9142 4.27105 16.3379 3.47799 17.0839 3.16884C17.8298 2.85968 18.6903 3.12054 19.139 3.79188C19.5878 4.46321 19.4998 5.35804 18.9289 5.92912C18.6122 6.24759 18.1813 6.42617 17.7322 6.42505Z"
                                    fill="#BDBDBD"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.9998 7.27051L23.5741 13.9529V27.3177L11.9998 34.0001L0.425537 27.3177V13.9529L11.9998 7.27051Z"
                                      fill="#D5A889"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0.425537 13.9526V27.3175L11.9998 33.9999V20.6351L0.425537 13.9526Z"
                                      fill="#937661"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.9998 7.27051L23.5741 13.9529L11.9998 20.6353L0.425537 13.9529L11.9998 7.27051Z"
                                      fill="#E8BF9D"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M4.60303 21.9334L7.82276 23.7923V18.2237L4.60303 16.3648V21.9334ZM16.1773 9.68237L19.397 11.5413L7.82276 18.2237L4.60303 16.3648L16.1773 9.68237Z"
                                      fill="#EFC7A4"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M4.60303 16.3652V21.9339L7.82276 23.7927V18.2241L4.60303 16.3652Z" fill="#D5A889"/>
                            </svg>


            }
        </>


    );
};

export default IconProduct;