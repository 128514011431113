

export const customMuiCard={
    styleOverrides: {
        root: {
            padding:0,
            border: `0.5px solid #3c3c3c`,
            borderRadius: 10
        }
    },
}as object