import React, {FC} from 'react';

interface IType {
    children?: any
}

const IconTraders: FC<IType> = ({children}) => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.7 0C4.66243 0 2.2 2.48731 2.2 5.55556C2.2 8.6238 4.66243 11.1111 7.7 11.1111C10.7376 11.1111 13.2 8.6238 13.2 5.55556C13.2 2.48731 10.7376 0 7.7 0ZM4.4 5.55556C4.4 3.71461 5.87746 2.22222 7.7 2.22222C9.52254 2.22222 11 3.71461 11 5.55556C11 7.3965 9.52254 8.88889 7.7 8.88889C5.87746 8.88889 4.4 7.3965 4.4 5.55556Z" fill="#828282"/>
            <path d="M16.3993 5.79809C16.0898 5.63871 15.7474 5.55562 15.4 5.55562V3.3334C16.0948 3.3334 16.7796 3.49952 17.3986 3.81828C17.4668 3.85339 17.5339 3.89024 17.6 3.92878C18.1339 4.24015 18.5979 4.66188 18.961 5.16728C19.3691 5.73525 19.6382 6.39275 19.7464 7.08596C19.8545 7.77918 19.7986 8.48842 19.5832 9.15561C19.3678 9.82281 18.9991 10.429 18.5072 10.9246C18.0153 11.4202 17.4142 11.791 16.7531 12.0068C16.1648 12.1989 15.5439 12.2633 14.9309 12.1969C14.855 12.1887 14.7793 12.1785 14.7037 12.1662C14.0182 12.0552 13.3684 11.782 12.8074 11.3688L12.8063 11.3679L14.1031 9.57285C14.3837 9.77969 14.7089 9.91641 15.0519 9.97194C15.3949 10.0275 15.746 10.0002 16.0765 9.89225C16.4071 9.78435 16.7076 9.59892 16.9535 9.35114C17.1995 9.10335 17.3839 8.80027 17.4915 8.46668C17.5992 8.13309 17.6272 7.77848 17.5731 7.43188C17.519 7.08528 17.3845 6.75654 17.1805 6.47257C16.9764 6.1886 16.7087 5.95746 16.3993 5.79809Z" fill="#828282"/>
            <path d="M19.7979 20C19.7979 19.4166 19.6842 18.839 19.4631 18.3C19.2421 17.761 18.9182 17.2713 18.5098 16.8588C18.1014 16.4463 17.6166 16.1191 17.083 15.8958C16.5494 15.6726 15.9775 15.5577 15.4 15.5577V13.3333C16.1503 13.3333 16.8942 13.4626 17.6 13.7146C17.7095 13.7537 17.8181 13.7958 17.9257 13.8408C18.7265 14.1758 19.454 14.6669 20.0669 15.286C20.6798 15.905 21.1659 16.6399 21.4976 17.4488C21.5422 17.5574 21.5838 17.6671 21.6225 17.7778C21.8721 18.4907 22 19.2421 22 20H19.7979Z" fill="#828282"/>
            <path d="M15.4 20H13.2C13.2 16.9318 10.7376 14.4444 7.7 14.4444C4.66243 14.4444 2.2 16.9318 2.2 20H0C0 15.7045 3.44741 12.2222 7.7 12.2222C11.9526 12.2222 15.4 15.7045 15.4 20Z" fill="#828282"/>
        </svg>
    );
};

export default IconTraders;
