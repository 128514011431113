import React, {FC, useState} from 'react';
import AccountType from "../accountType";
import {Button, Divider, Stack, useMediaQuery} from "@mui/material";
import s from './styles.module.css'
import AccountHeader from "../accountHeader";
import IconAddAccount from "../../../shared/assets/images/icons/iconAddAccount";
import {IBalance, Server} from "../../../types";
import {NavLink} from "react-router-dom";
import {
    useDeleteAccountMutation
} from "../../../store/API/userApi";
import AccountModal from "../modal/accountModal";
import ChartCard from "../chart/chartCard";
import {accountIdSlice} from "../../../store/slice/accountIdSlice";
import {useAppDispatch} from "../../../hooks/useRedux";
import {toast} from "react-toastify";

interface T {
    isLoading?: boolean;
    accountId?: number | string;
    accountPassword?: number | string;
    accountLogin?: number | string;
    server?: any;
    status: number;
    productType?: string;
    balance?: IBalance;
    accountType?: Server;
    accountNumber?: number | string;
    accountName?: string;
    depositLoad?: any;
    reject_reason: null | string,
    index?: number
    type?: number | any
    product_data?: any
}

const AccountCard: FC<T> = ({
                                type,
                                index,
                                isLoading,
                                accountId,
                                accountPassword,
                                accountLogin,
                                server,
                                status,
                                productType,
                                accountNumber,
                                balance,
                                accountType,
                                reject_reason,
                                accountName,
                                depositLoad,
                                product_data
                            }) => {
    // const [deleteAccount] = useDeleteAccountMutation()
    const {changeAccountId} = accountIdSlice.actions
    const dispatch = useAppDispatch()

    const [deleteAccount, {
        data: deleteAccountData,
        error: deleteAccountError,
        isLoading: deleteAccountLoading
    }] = useDeleteAccountMutation();

    const handleDeleteAccount = () => {
        deleteAccount(accountNumber).then((res: any) => {
            if(res?.error) {
                toast.error(res.error.data.message, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
        })
    }

    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [openModal, setOpenModal] = useState(false);
    const [_, setUpdateAccount] = useState(false);
    const [account, setAccount] = useState({});

    const changeAccount = (e: any) => {
        dispatch(changeAccountId(e))
    }

    return (
        <Stack sx={{
            height: mediaQuery ? 400 : 'unset',
            border: ` 0.5px solid #3C3C3C`,
            background: `linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, #1F1F1F 100%)`,
            borderRadius: 2.5,
            overflow: 'hidden'
        }}>
            <AccountType
                type={type}
                accountLogin={accountLogin}
                product_data={product_data}
                productType={productType}
                accountType={accountType}
                accountNumber={accountNumber}
                accountName={accountName}
                status={status}
            />
            <Divider variant="fullWidth" sx={{mb: 14, ml: 14}}/>

            {
                status === -1 ?
                    <Stack alignItems="center"
                           sx={{
                               height: `100%`,
                           }}
                    >
                        <div className={s.negative_answer}>
                            <IconAddAccount/>
                            <Stack className="h2 red" sx={{mb: 7, mt: 7}} textAlign="center">Заявка на добавление счета
                                отклонена</Stack>
                            <Stack className="subHeaders white-90" textAlign="center">
                                {reject_reason}
                            </Stack>
                        </div>
                        <Stack direction="row" spacing={7} sx={{mt: 'auto', mb: 7, mr: 7, ml: 'auto'}}>
                            <Button color="error" onClick={handleDeleteAccount}>Удалить</Button>
                            <Button color="success" onClick={() => {
                                setUpdateAccount(true)
                                setOpenModal(true)
                                setAccount({accountLogin, accountNumber, accountPassword, accountId, server})
                            }}>Изменить</Button>
                        </Stack>
                    </Stack>
                    : status === 0 ?
                        <Stack alignItems="center" sx={{height: `100%`}}>
                            <div className={s.negative_answer}>
                                <IconAddAccount success/>
                                <Stack className="h2 blue" sx={{mb: 7, mt: 12}} textAlign="center">
                                    Ожидание рассмотрения заявки <br/> на добавление счета
                                </Stack>
                            </div>
                        </Stack>
                        :
                        <>
                            <NavLink to={`/exchange-account/${accountNumber}`} onClick={() => changeAccount(accountNumber)}>
                                <AccountHeader depositLoad={depositLoad} balance={balance} accountType={accountType?.type}/>
                            </NavLink>
                            <Stack sx={{width: '100%', height: 220}}>
                                <ChartCard data={balance!.graph} index={index}/>
                            </Stack>
                        </>

            }
            {
                openModal &&
                <AccountModal account={account} updateAccount openModal={openModal} closeModal={setOpenModal}/>
            }
        </Stack>
    );
};

export default AccountCard;
