import React, {FC, useEffect, useState} from 'react';
import {Stack, useMediaQuery} from "@mui/material";
import {Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomAxisTick from "./customAxisTick";
import HeaderChart from "../../../shared/components/headerChart";
import Paper from "@mui/material/Paper";
import CustomTooltip from "./customTooltip";

const wrapperTooltipStyle = {
    padding: `8px 14px`,
    background: '#1F1F1F',
    border: `0.5px solid #3C3C3C`,
    borderRadius: `10px`,
    outline: 'none'
}
const contentTooltipStyle = {
    background: '#1F1F1F',
    padding: 0,
    border: 'none'
}

interface IType {
    data?: any
}

const SymbolChart: FC<IType> = ({data}) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [barData, setBarData] = useState<any>([])

    const [toolTipYPosition, setToolTipYPosition] = useState<any>({});
    const [activeBarIndex, setActiveBarIndex] = useState<any>();
    useEffect(() => {
        if (data) setBarData(data)
    }, [data])
    const handlerSwitch = (e: any, id: any, item: any, index: any) => {
        if (!e.target.checked) {
            const arr = barData.filter((item: any) => item.id !== id)
            setBarData(arr)
        }
        if (e.target.checked && item) {
            setBarData([...barData, item])
        }
    }
    useEffect(() => {
        const barCharts = document.querySelectorAll(".recharts-bar-rectangle");
        if (!barCharts || !activeBarIndex) return;
        const barChart = barCharts[activeBarIndex];
        setToolTipYPosition(barChart.getBoundingClientRect().height);
    }, [activeBarIndex]);

    return (
        <Paper sx={{
            flexGrow: 1, "@media (min-width:980px)": {
                padding: `14px 4px`,
            }
        }}>
            <HeaderChart title="Символы"/>
            <Stack sx={{width: '100%', height: 230}}>
                <ResponsiveContainer>
                    <BarChart
                        data={barData}
                        stackOffset="sign"
                        maxBarSize={20}
                        barCategoryGap={2}
                        barGap={2}
                        margin={{
                            top: 0,
                            right: 10,
                            left: -30,
                            bottom: 0,
                        }}
                        onMouseMove={(e) => {
                            setActiveBarIndex(e.activeTooltipIndex);
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" stroke="#3c3c3c"/>
                        <XAxis dataKey="name" rotate={-40} tick={<CustomAxisTick textAnchor="middle"/>} interval={0}/>
                        <YAxis tick={<CustomAxisTick rotate={-30}/>}/>
                        <Tooltip content={<CustomTooltip barChart/>} contentStyle={contentTooltipStyle}
                                 wrapperStyle={wrapperTooltipStyle}
                                 position={{y: 50 - toolTipYPosition}}
                                 allowEscapeViewBox={{x: true, y: true}}
                                 offset={-50}/>
                        <ReferenceLine y={0} stroke="#3c3c3c" alwaysShow={true} isFront={true}/>
                        <Bar dataKey="pv" stackId={1} fill="#6FCF97" isAnimationActive={false}/>
                        <Bar dataKey="uv" stackId={1} fill="#FF8888"/>
                    </BarChart>
                </ResponsiveContainer>
            </Stack>
        </Paper>
    );
};

export default SymbolChart;
