import MainLayout from "../../widgets/mainLayout";
import ProductItem from "../../entities/components/productItem";
import MyProductsList from "../../widgets/myProductsList";
import {Stack, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import s from './styles.module.css'
import Button from "@mui/material/Button";

const Products = () => {
    const mediaQuery = useMediaQuery('(min-width:980px)');

    const [visible, setVisible] = useState<'all' | 'product' | 'product_list'>('all')

    useEffect(() => {
        if (mediaQuery) {
            setVisible('all')
        } else {
            setVisible('product')
        }
    }, [mediaQuery])
    return (
        <MainLayout heading="Продукты">
            <div className={s.product}>
                {visible === 'all' && <Stack spacing={7}>
                    <ProductItem/>
                    <MyProductsList/>
                </Stack>}

                {visible === 'product' && <Stack spacing={7}>
                    <ProductItem/>
                </Stack>}

                {visible === 'product_list' && <Stack spacing={7}>
                    <MyProductsList/>
                </Stack>}

                {!mediaQuery && <Button
                    onClick={() => setVisible(visible === 'product' ? 'product_list' : 'product')}
                    variant="contained"
                    color={visible === 'product' ? "warning" : 'error'}
                    sx={{height: 48, position: 'fixed', right: 20, left: 20, bottom: 40, zIndex: '9'}}
                >{visible === 'product' ? 'Мои продукты' : 'Выйти'}</Button>}
            </div>
        </MainLayout>
    );
};

export default Products;
