

export const customMuiTab={
    styleOverrides: {
        root:{
            minHeight: 'unset',

            borderRadius: 10,
            '&.MuiButtonBase-root':{
                padding:`14px 28px`,
            }
        }
    }
}as object