import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {headersAccept, headersAuthorization, headersContent} from "./APIToken";


export const traderAdminApi = createApi({
    reducerPath: 'traderAdminApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}/admin/forex`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
            headers.set("Authorization", headersAuthorization)
        },
    }),
    tagTypes: ['Trader'],
    endpoints: (build) => ({
        getAllAdminTraders: build.query({
            query: (page) => ({
                url: '/traders',
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Trader'],
        }),

        changeAdminTradersStatus: build.mutation({
            query: ({id, body}) => ({
                url: `/trader/${id}/active`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Trader']
        }),

        addTrader: build.mutation({
            query: (body) => ({
                url: `/trader`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Trader']
        }),
        updateTrader: build.mutation({
            query: ({id, body}) => ({
                url: `/trader/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Trader']
        }),

        traderById: build.mutation({
            query: ({id}) => ({
                url: `/trader/${id}`,
                method: 'GET',
            }),
            invalidatesTags: ['Trader']
        }),


        deleteTrader: build.mutation({
            query(id) {
                return {
                    url: `/trader/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Trader']
        }),
    })
});

export const {
    useGetAllAdminTradersQuery,
    useDeleteTraderMutation,
    useTraderByIdMutation,
    useAddTraderMutation,
    useChangeAdminTradersStatusMutation,
    useUpdateTraderMutation
} = traderAdminApi;

export const {} = traderAdminApi.endpoints;
