import React, {FC} from 'react';
import {Avatar, Stack, useMediaQuery} from "@mui/material";
import CustomTooltip from "./customTooltip";
import {NavLink} from "react-router-dom";

interface IType {
    name: string;
    number?: number | string;
    avatar?: string;
    direction?: 'row-reverse' | 'row';
    justifyContent?: string;
    notMb?: any;
    strategy?: any;
    redirect?: any;
}

const NickName: FC<IType> = ({strategy, redirect, name, number, avatar, direction, justifyContent, notMb}) => {
    const mediaQuery = useMediaQuery('(min-width:980px)');

    const NavigateName = (props: any) => redirect?.isNeedRedirect ?
        <NavLink to={redirect?.redirectClick} {...props} target={'_blank'}>{props.children}</NavLink> :
        <span {...props}>{props.children}</span>
    return (
        <Stack
            // mb={notMb ? 0 : 7}
            direction={direction ? direction : 'row'}
            alignItems="center"
            justifyContent={justifyContent ? justifyContent : "flex-start"}
            spacing={7}

        >

            <Stack sx={{
                maxWidth: 'fit-content',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',

            }}>
                <NavigateName style={{color: 'inherit'}}
                              className="subHeadersBold">{name}</NavigateName>
            </Stack>

            {
                avatar &&
                <CustomTooltip title={
                    <>
                        <span>{`Стратегия "${strategy}"`}</span>
                        <br/>
                    </>
                }
                >
                    <Avatar
                        alt={name}
                        src={avatar}
                        sx={{width: 34, height: 34}}
                    />
                </CustomTooltip>
            }
        </Stack>
    );
};

export default NickName;
