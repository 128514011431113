import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Skeleton,
    Stack,
    useMediaQuery
} from "@mui/material";
import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import imgStrategyGrid from "../shared/assets/images/strategy.png";
import imgStrategyStopLoss from "../shared/assets/images/strategys-stop-loss.png";
import NickName from "../shared/components/nickName";
import Paper from "@mui/material/Paper";
import {Link, useLocation} from "react-router-dom";
import IconSettings from "../shared/assets/images/icons/iconSettings";
import CustomAreaChart from "../entities/components/chart/customAreaChart";
import TabsHeader from "../entities/components/tabsHeader";
import TabsItem from "../entities/components/tabsItem";
import CopyTradingModalSettings from "../entities/components/modal/copyTradingModalSettings";
import SimpleModal from "../entities/components/modal/simpleModal";
import DeleteAccountModal from "../entities/components/modal/deleteAccountModal";
import {
    useGetAllSubscribesQuery,
    useGetAllSubscribesSetQuery,
    useUnsubscribeTraderMutation
} from "../store/API/subscribesApi";
import CopytradingChart from "../entities/components/chart/copitradingChart";
import {useGetProfileQuery} from "../store/API/profileApi";
import TraderItem from "../entities/components/TraderItem";
import {useGetAllLinkedTradersQuery} from "../store/API/userApi";
import {useAppSelector} from "../hooks/useRedux";


interface IType {
    accountId?: string;
    dataSets?: any;
    product?: any;
    login?: any;
    assigned?: any;
    updateDeposit?: any,
    data?: {

        id: any;
        stats: { gain: number, dropdown: number, balance: number, deposit_load: number },
        graph: [],
        trader: { id: string | number, name: string, strategy: string }
    }[]
}

const TradersAndSets: FC<IType> = ({product, login, dataSets, assigned, updateDeposit}) => {
    const location = useLocation()
    const {accountId, accountName} = useAppSelector((state) => state.accountIdReducer);

    const {data, isLoading, refetch: refetch_trade} = useGetAllSubscribesQuery(accountId, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {
        data: dataSet,
        isLoading: isLoadingSet,
        isFetching,
        refetch: refetch_set
    } = useGetAllSubscribesSetQuery(accountId, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const {refetch} = useGetProfileQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [unsubscribeTrader] = useUnsubscribeTraderMutation()

    const mediaQuery = useMediaQuery('(min-width:980px)');

    const [accept, setAccept] = useState<boolean>(false)
    const [value, setValue] = useState<number | null>(null);

    const [idSet, setIdSet] = useState('');
    const [openSetModal, setOpenSetModal] = useState(false)

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalUnsubscribe, setOpenModalUnsubscribe] = useState(false);

    const [subscribeId, setSubscribeId] = useState(0);
    const [subscribeLogin, setSubscribeLogin] = useState(0);

    const [trader, setTrader] = useState({});
    const [idTrader, setIdTrader] = useState('');
    const [nameTrader, setNameTrader] = useState('');

    const [_, setName] = useState('');

    const {data: dataLinkedTraders, isLoading: isLoadingLinkedTraders} = useGetAllLinkedTradersQuery(idSet, {
        skip: !openSetModal,
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
    });

 
    const handleSettingsAcc = (id: any) => {
        setIdTrader(id)
        setOpenModal(true)
    }
    const handleUnsubscribe = (id: any, login: any) => {
        setSubscribeLogin(login)
        setSubscribeId(id)
        setAccept(false)
        setOpenModalUnsubscribe(true)
    }

    const updateDepositTradeAndSet = () => {
        updateDeposit()
        refetch_trade()
        refetch_set()
    }

    if (isLoading || isLoadingSet || isFetching) {
        return (
            <Skeleton variant="rounded" width={`100%`} height={mediaQuery ? 433 : '100vh'}
            />
        )
    }
    return (
        <>
            <Paper
                sx={
                    !mediaQuery ? {
                            width: `100vw`,
                            paddingBottom: '200px !important',
                            padding: '14px',
                            minHeight: '100vh',
                            background: '#1F1F1F',
                        }
                        : {
                            padding: '14px !important'
                        }
                }
            >

                <TabsHeader
                    isModalVariant={false}
                    variant="fullWidth"
                    size={'sizeSmall'}
                    tabsName={[
                        {name: 'Трейдеры'},
                        {name: 'Сеты'},
                    ]}
                    tabsValue={value === null ? ((dataSet?.data?.length > 0) ? 1 : 0) : value} onTabsChange={setValue}/>
                <Divider sx={{mb: 4, width: `105%`}}/>

                {isLoading && !mediaQuery && <LinearProgress color={'error'} sx={{
                    marginBottom: '10px'
                }}/>}

                <TabsItem value={value === null ? ((dataSet?.data?.length > 0) ? 1 : 0) : value} index={0}>
                    <Stack className="subHeadersBold white-80" sx={{mb: 4}}>{data?.data?.length ?? 0}/15</Stack>

                    <Stack spacing={7}>
                        {
                            data && data?.data.map((item: any, index: number) =>
                                <Paper
                                    key={item.trader.id}
                                    sx={{
                                        flexGrow: 1,
                                        "@media (min-width:980px)": {
                                            padding: `14px`,
                                        }
                                    }}>
                                    <NickName
                                        strategy={item.trader.strategy === 'grid' ? "сеточная" : "со стопами"}
                                        name={item.trader.name} number={item.trader.id}
                                        avatar={item.trader.strategy === 'grid' ? imgStrategyGrid : imgStrategyStopLoss}
                                        justifyContent="space-between"/>
                                    <Stack sx={{mb: 8}}>
                                        <CopytradingChart dataChart={item.graph} id={index + 3} height={80}/>
                                    </Stack>
                                    <Grid container columns={4} sx={{mb: 7}}>
                                        <Grid item xs={2}>
                                            <Stack alignItems="center" justifyContent="center" spacing={2}
                                                   sx={{
                                                       p: 2,
                                                       borderRight: `0.5px solid #3C3C3C`,
                                                       borderBottom: `0.5px solid #3C3C3C`
                                                   }}>
                                                <span className="subHeaders white-90">Прирост</span>
                                                <span
                                                    className={`subHeadersBold ${(item.stats.gain > 0) ? "green" : "red"} `}
                                                >
                                                    {item.stats.gain}%
                                                </span>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack alignItems="center" justifyContent="center" spacing={2}
                                                   sx={{p: 2, borderBottom: `0.5px solid #3C3C3C`}}>
                                                <span className="subHeaders white-90">Просадка</span>
                                                <span
                                                    className={`subHeadersBold ${(item.stats.dropdown > 0) ? "green" : "red"} `}
                                                >
                                                    {item.stats.dropdown}%
                                                </span>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Stack alignItems="center" justifyContent="center" spacing={2}
                                                   sx={{p: 2, textAlign: "center"}}>
                                                <span className="subHeaders white-90">Нагр. депозита</span>
                                                <span
                                                    className={`subHeadersBold ${(item.stats.deposit_load > 0) ? "green" : "red"} `}
                                                >
                                                    {item.stats.deposit_load}%
                                                </span>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Stack direction="row" spacing={7} sx={{mb: 4}}>
                                        <Button
                                            onClick={() => {
                                                handleSettingsAcc(item.id)
                                                setNameTrader(item.trader.name)
                                                setTrader(item.trader.id)
                                                setName('трейдера')
                                            }}
                                            color="neutral"
                                            sx={{width: 48, height: 48, minWidth: 'unset', backgroundColor: '#1F1F1F'}}
                                        >
                                            <IconSettings/>
                                        </Button>
                                        <Button fullWidth color="neutral" component={Link}
                                                sx={{backgroundColor: '#1F1F1F'}}
                                                to={`/trader-dashboard/${item.trader.id}`}>
                                            Дашборд
                                            трейдера
                                        </Button>
                                    </Stack>
                                    <Button onClick={() => handleUnsubscribe(item.id, item.trader.name)} fullWidth
                                            variant="contained"
                                            color="error">Отключить</Button>
                                </Paper>
                            )
                        }
                    </Stack>
                </TabsItem>
                <TabsItem value={value === null ? ((dataSet?.data?.length > 0) ? 1 : 0) : value} index={1}>
                    <Stack className="subHeadersBold white-80" sx={{mb: 4}}>{dataSet?.data?.length ?? 0}/1</Stack>
                    <Stack spacing={7}>
                        {
                            dataSet && dataSet?.data?.map((item: any) =>
                                <Paper
                                    key={item.set.id}
                                    sx={{
                                        flexGrow: 1,
                                        "@media (min-width:980px)": {
                                            padding: `14px`,
                                        }
                                    }}>
                                    <NickName
                                        name={item.set.name} number={item.set.id}
                                        justifyContent="space-between"/>
                                    <Stack sx={{mb: 8}}>
                                        <CustomAreaChart data={item.graph} height={54}
                                                         dataArea={[{dataKey: "uv", stroke: "#6FCF97", fill: "#29312C"}]}/>
                                    </Stack>
                                    <Grid container columns={4} sx={{mb: 7}}>
                                        <Grid item xs={2}>
                                            <Stack alignItems="center" justifyContent="center" spacing={2}
                                                   sx={{
                                                       p: 2,
                                                       borderRight: `0.5px solid #3C3C3C`,
                                                       borderBottom: `0.5px solid #3C3C3C`
                                                   }}>
                                                <span className="subHeaders white-90">Прирост</span>
                                                <span className="subHeadersBold green">{item.stats.gain}%</span>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Stack alignItems="center" justifyContent="center" spacing={2}
                                                   sx={{p: 2, borderBottom: `0.5px solid #3C3C3C`}}>
                                                <span className="subHeaders white-90">Просадка</span>
                                                <span className="subHeadersBold green">{item.stats.dropdown}%</span>
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Stack alignItems="center" justifyContent="center" spacing={2}
                                                   sx={{p: 2, textAlign: "center"}}>
                                                <span className="subHeaders white-90">Нагр. депозита</span>
                                                <span className="subHeadersBold green">{item.stats.deposit_load}%</span>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        onClick={() => {
                                            handleUnsubscribe(item.id, item.set.name)
                                            setName('сет')
                                        }}
                                        fullWidth
                                        variant="contained"
                                        color="error">Отключить</Button>
                                    <Button
                                        onClick={() => {
                                            setOpenSetModal(true)
                                            setIdSet(item?.set?.id)
                                            // handleUnsubscribe(item.id, item.set.name)
                                            // setName('сет')
                                        }}
                                        fullWidth
                                        sx={{
                                            marginTop: '10px'
                                        }}
                                        variant="contained"
                                        color="success">Подключенные трейдеры</Button>
                                </Paper>
                            )
                        }
                    </Stack>
                </TabsItem>

            </Paper>
            {!assigned && <Button color="error" variant="contained" sx={{
                marginTop: '20px',
                height: '76px',
                maxWidth: '100%',
                width: '100%',
                background: '#2C2626',
                borderRadius: '5px',
                "@media (max-width:980px)": {
                    marginTop: '0',
                    height: 48, position: 'fixed', right: 20, left: 20, bottom: 120,
                    maxWidth: '100%',
                    width: 'inherit',
                }
            }} onClick={() => setOpenModalDelete(true)}>Удалить счет</Button>}
            {
                openModal &&
                <CopyTradingModalSettings
                    idTraderSubscribe={idTrader}
                    idTrader={trader}
                    openModal={openModal}
                    nameTrader={nameTrader}
                    trader={trader}
                    nameAccount={login}
                    updateDeposit={updateDepositTradeAndSet}
                    closeModal={setOpenModal}>

                    <span>Изменить настройки</span>
                    <span className="green">&nbsp;{nameTrader}&nbsp;</span>
                    <span>на счет</span>
                    <span className="blue">&nbsp;для счета {login}&nbsp;</span>
                    <span>?</span>

                </CopyTradingModalSettings>
            }
            {
                openModalUnsubscribe &&
                <SimpleModal title="Отключить трейдера" openModal={openModalUnsubscribe}
                             closeModal={() => {
                                 setOpenModalUnsubscribe(false)
                                 setAccept(false)
                             }}>
                    <div className="h2">
                        <span>Вы уверены что хотите отключить копирование</span>
                        <span className="yellow">&nbsp;{subscribeLogin}&nbsp;</span>
                        <span>?</span>
                        <br/>
                        <i style={{
                            fontSize: '12px',
                            fontWeight: 400
                        }}>
                            После отключения копирования, все открытые сделки необходимо будет закрыть вручную. Название
                            трейдера указано в комментарии к ордеру.
                        </i>
                        <br/>
                        <div style={{
                            marginTop: '14px',
                        }}>
                            <Checkbox checked={accept} onClick={() => setAccept(!accept)}/>
                            <span style={{
                                fontSize: '12px',
                                marginLeft: '10px'
                            }}>Подтверждаю</span>
                        </div>
                    </div>
                    <Stack direction="row" justifyContent="flex-end" spacing={7}>
                        <Button onClick={() => setOpenModalUnsubscribe(false)} color="error">Отмена</Button>
                        <Button
                            disabled={!accept}
                            onClick={() => {
                                unsubscribeTrader(subscribeId).then(() => {
                                    setOpenModalUnsubscribe(false)
                                    refetch()
                                })
                            }}
                            color="success">Сохранить</Button>
                    </Stack>
                </SimpleModal>
            }
            {
                openModalDelete &&
                <DeleteAccountModal
                    title={'Удалить счет'}
                    assigned={assigned}
                    accountId={accountId}
                    product={product}
                    accountName={login}
                    openModal={openModalDelete}
                    closeModal={setOpenModalDelete}
                />
            }

            <SimpleModal maxWidth={1080} title='Подключенные трейдеры' openModal={openSetModal}
                         closeModal={setOpenSetModal}>
                <Stack spacing={7}>
                    {isLoadingLinkedTraders ? (
                        <Skeleton variant='rounded' width={`100%`} height={68}/>
                    ) : dataLinkedTraders?.data && dataLinkedTraders?.data?.length > 0 ? (
                        dataLinkedTraders?.data.map((item: any) => {
                            return (
                                <TraderItem
                                    isNeedRedirect={true}
                                    redirectClick={`/trader-dashboard/${item?.id}`}
                                    key={item?.id}
                                    id={item?.id}
                                    stats={{
                                        ...item?.stats,
                                        deposit_load: item?.stats?.deposit_load?.for_forex_accounts[accountId] || 0,
                                    }}
                                    graph={item?.graph}
                                    name={item?.name}
                                    strategy={item?.strategy}
                                />
                            );
                        })
                    ) : (
                        <Paper className='h2'>Нет подключенных трейдеров</Paper>
                    )}
                </Stack>
            </SimpleModal>

        </>
    );
};
export default TradersAndSets;
