import React, {useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import CustomButton from "../../../common/button";
import {ReactComponent as FoundIcon} from '../../../shared/assets/images/foundmaker.svg'
import TradeTop from "../tradePage/trade_top";
import moment from "moment";
import DateWork from "../dateWork";
import {useNavigate} from "react-router-dom";
import PaymentBalanceModal from "../../../entities/components/modal/paymentBalanceModal";

export const getDayLabel = (days: any) => {
    if (days % 10 === 1 && days % 100 !== 11) {
        return 'день';
    } else if (days % 10 >= 2 && days % 10 <= 4 && (days % 100 < 10 || days % 100 >= 20)) {
        return 'дня';
    } else {
        return 'дней';
    }
};

export const calculateDaysBetween = (endDate: any, type = 'days') => {
    // Текущая дата
    const today = moment();

    // Дата окончания
    const end = moment(endDate);

    // Разница в днях
    const daysDifference = end.diff(today, 'days');
    const hourseDifference = end.diff(today, 'hours');

    if (type === 'days') {
        return +daysDifference >= 0 ? +daysDifference : 0;
    } else {
        return hourseDifference;
    }

};

const StockHeader = ({dataDashboard}: any) => {
    const navigate = useNavigate()
    const [openPaymentBalance, setOpenPaymentBalance] = useState(false)

    const product = dataDashboard?.product?.product_data || {}
    const dynamic_notification = dataDashboard?.dynamic_notification || {}
    console.log(dataDashboard)

    const valid_to = product?.valid_to ? moment(product?.valid_to, 'DD.MM.YYYY HH:mm:ss') : product?.valid_to

    return (
        <div className={s.main}>
            {openPaymentBalance &&
                <PaymentBalanceModal open={openPaymentBalance} handleClose={() => setOpenPaymentBalance(false)}/>}
            <div className={s.header}>
                <div className={classNames(s.item, s.header_left)}>
                    <div className={s.header_left_top}>
                        <FoundIcon/>
                        <p className={s.header_left_top_title}>COPYTEN FONDMAKER</p>
                    </div>
                    <div className={s.header_left_bottom}>
                        {dynamic_notification?.title && <p className={s.header_left_bottom_title_green}
                                                           style={{
                                                               color:
                                                                   (dynamic_notification?.type === 'success' && '#6FCF97') ||
                                                                   (dynamic_notification?.type === 'error' && 'rgb(246, 87, 104)') ||
                                                                   (dynamic_notification?.type === 'warn' && '#F2C94C') || '#fff'
                                                           }}
                        >
                            {dynamic_notification?.title}
                        </p>}
                        {dynamic_notification?.sub_title && <p className={s.header_left_bottom_title}>
                            {dynamic_notification?.sub_title}
                        </p>}

                        {dynamic_notification?.button && <CustomButton onClick={() => {
                            if (dynamic_notification?.button?.action === 'url') {
                                return
                            } else if (dynamic_notification?.button?.action === 'payment_product') {
                                navigate(`/tariff?payment_product=${product?.id}`)
                            } else if (dynamic_notification?.button?.action === 'replenish_balance') {
                                setOpenPaymentBalance(true)
                                console.log('replenish_balance')
                            }
                        }
                        }
                                                                       className={classNames(s.button_action, dynamic_notification?.button?.action === 'url' && s.button_action_url)}>
                            {dynamic_notification?.button?.action === 'url' ?
                                <a href={dynamic_notification?.button?.url} target={'_blank'}
                                   className={classNames(s.btn_url)}>{dynamic_notification?.button?.text}</a> : dynamic_notification?.button?.text}
                        </CustomButton>}
                    </div>
                </div>
                <div className={s.header_right}>
                    <div className={classNames(s.item, s.item_r_t)}>
                        <div className={s.item_r_t_title}>
                            <h3 className={s.item_r_t_title_f}>Работаем еще</h3>

                            <DateWork valid_to={valid_to}/>
                        </div>

                        <CustomButton>
                            Продлить сервер
                        </CustomButton>
                    </div>
                    <div className={classNames(s.item, s.item_r_b)}>
                        <div className={s.item_r_b_item}>
                            <p className={s.item_r_b_item_title}>Тариф</p>
                            <p className={s.item_r_b_item_subtitle}>{product?.title || ''}</p>
                        </div>
                        <div className={s.item_r_b_item}>
                            <p className={s.item_r_b_item_title}>Статус</p>
                            <p className={classNames(s.item_r_b_item_subtitle, s.item_r_b_item_subtitle_green)} style={{
                                color: product?.is_active ? '#6FCF97' : '#f65768'
                            }}>{product?.is_active ? 'Активен' : 'Неактивен'}</p>
                        </div>
                        <div className={s.item_r_b_item}>
                            <p className={s.item_r_b_item_title}>Дата валидности</p>
                            <p className={s.item_r_b_item_subtitle}>
                                {valid_to ? `${moment(valid_to).format('DD.MM.YYYY')} (${calculateDaysBetween(valid_to)} ${getDayLabel(calculateDaysBetween(valid_to))})` : '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.header_description}>
                <TradeTop stats={dataDashboard?.stats} setOpenPaymentBalance={() => setOpenPaymentBalance(true)}/>
            </div>
        </div>
    );
};

export default StockHeader;