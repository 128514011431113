import React, {useLayoutEffect, useMemo} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ChartGrowth = ({dataBalanceGain}: any) => {
    const data = useMemo(() => {
        return dataBalanceGain?.map((el: any, i: number) => {
            const dateandtime: any = el.d ? el.d.split(' ') : null

            return ({
                ...el, index: i, d: dateandtime === null ? 'Нету' : dateandtime[0]
            })
        })

    }, [dataBalanceGain])

    useLayoutEffect(() => {
        let root = am5.Root.new("chartdiv");

        root._logo?.dispose()
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            cursor: am5xy.XYCursor.new(root, {}),
            pinchZoomX: true,
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none",
        }));

        cursor.lineY.set("visible", false);
        cursor.lineX.set("stroke", am5.color(0xffffff));
        cursor.lineX.set("strokeWidth", 2);

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "index",
            forceHidden: true,
            visible: false,

            renderer: am5xy.AxisRendererX.new(root, {
                forceHidden: true,
            }),
            tooltip: am5.Tooltip.new(root, {
                visible: false,
                forceHidden: true,
            }),
            startLocation: 0.5,
            endLocation: 0.5,
        }));

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            extraTooltipPrecision: 2,
            numberFormat: "#'%'",

            renderer: am5xy.AxisRendererY.new(root, {
                minGridDistance: 20,
                opposite: true,

            })
        }));

        yAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0xFF0000),
            fontSize: "10px",
            rotation: 20,

        });

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "uv",
            categoryXField: "index",
        }));

        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            labelText: "[bold][#56CCF2]{valueY}%[/]\n{d}",
            pointerOrientation: 'horizontal'
        });

        tooltip?.get("background")?.setAll({
            strokeOpacity: 1,
            stroke: am5.color(0x3C3C3C),
            fillOpacity: .5,
            fill: am5.color(0x1F1F1F),
        });

        series.set("tooltip", tooltip);
        series.set("fill", am5.color(0x3C3C3C));
        series.set("stroke", am5.color(0x56CCF2));

        xAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0xffffff),
            forceHidden: true,
        });

        yAxis.get("renderer").labels.template.setAll({
            fill: am5.color(0x56CCF2),
        });

        xAxis.get("renderer").grid.template.setAll({
            stroke: am5.color(0xffffff),
        })

        yAxis.get("renderer").grid.template.setAll({
            stroke: am5.color(0xffffff),
        })

        root.interfaceColors.set("grid", am5.color(0xCCCCCC));

        series.data.setAll(data);

        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [data]);
    return (
        <div>
            <div id="chartdiv" style={{width: "100%", height: "227px"}}></div>
        </div>
    );
};

export default ChartGrowth;
