import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IProductsData} from "../../types";
import {headersAccept, headersAuthorization, headersContent} from "./APIToken";


export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}/user`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
            headers.set("Authorization", headersAuthorization)
        },
    }),
    tagTypes: ['Products'],
    endpoints: (build) => ({
        getAllProducts: build.query<IProductsData, string | number>({
            query: (page) => ({
                url: '/products',
                params: {
                    page,

                }
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Products'],
        }),
        getAllAddValidateProducts: build.query({
            query: ({page, type}) => ({
                url: type ? `/products/available/accounts/${type}` : '/products/available/accounts',
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Products'],
        }),
        getAllAddValidateProductsRobot: build.query({
            query: ({page, id}) => ({
                url: `/product/${id}/available-accounts`,
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Products'],
        }),
        getProductsBySlug: build.query({
            query: ({slug, page}) => ({
                url: `/products/${slug}`,
                params: {
                    page,
                }
            }),

        }),
        getPaymentLink: build.mutation({
            query: ({body, id}) => ({
                url: id,
                method: 'Post',
                body
            }),
            invalidatesTags: ['Products']
        }),
        getWallets: build.query({
            query: (id) => ({
                url: id,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Products']
        }),
        getProductDataBySlug: build.query({
            query: (id) => ({
                url: id,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Products']
        }),
        createNewProduct: build.mutation({
            query: ({body, slug}) => ({
                url: `/product/${slug}`,
                method: 'Post',
                body
            }),
            invalidatesTags: ['Products']
        }),
        updateProduct: build.mutation({
            query({body, id}) {
                return {
                    url: `product/${id}`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: ['Products']
        }),
        deleteProduct: build.mutation({
            query: ({id}) => {
                return {
                    url: `product/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Products']
        }),
        robotSettingsSend: build.mutation({
            query: ({body, id}) => {
                return {
                    url: `/product/${id}/settings`,
                    method: 'PUT',
                    body
                }
            },
            invalidatesTags: ['Products']
        }),
        getRobotSettings: build.mutation({
            query: (id) => {
                return {
                    url: `/product/${id}/settings`,
                    method: 'GET',
                }
            },
            invalidatesTags: ['Products']
        }),
        setEnableDisableRobot: build.mutation({
            query: ({id, type}) => {
                return {
                    url: `/product/${id}/${type}`,
                    method: 'PATCH',
                }
            },
            invalidatesTags: ['Products']
        }),
        setPaymentWalletRobot: build.mutation({
            query: (amount) => {
                return {
                    url: `${process.env.REACT_APP_URL}/wallets/COMMISSION-WALLET/replenish?amount=${amount}`,
                    method: 'POST',
                }
            },
            // invalidatesTags: ['Products']
        })
    }),

});

export const {
    useGetAllProductsQuery,
    useGetProductsBySlugQuery,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useCreateNewProductMutation,
    useRobotSettingsSendMutation,
    useGetPaymentLinkMutation,
    useGetWalletsQuery,
    useGetProductDataBySlugQuery,
    useGetRobotSettingsMutation,
    useSetPaymentWalletRobotMutation,
    useSetEnableDisableRobotMutation,
    useGetAllAddValidateProductsQuery,
    useGetAllAddValidateProductsRobotQuery
} = productApi;

export const {getAllProducts} = productApi.endpoints;
