import React from 'react';
import s from './styles.module.css'

export const Step = ({step,style}: any) => {
    return (
        <div style={style} className={s.step}>{`Шаг ${step}`}</div>
    );
};

export const Title = ({title,style}: any) => {
    return <h2 className={s.title} style={style}>{title}</h2>
}

