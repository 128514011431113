import React, {FC} from 'react';

interface IType {
    active: any
}

const IconMenu: FC<IType> = ({active}) => {
    return (
        <>
            {
                active ?
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6897 0.310329C13.2759 -0.103443 12.605 -0.103443 12.1913 0.310329L7 5.5016L1.80873 0.310329C1.39496 -0.103443 0.724101 -0.103443 0.310329 0.310329C-0.103443 0.724101 -0.103443 1.39496 0.310329 1.80873L5.5016 7L0.310329 12.1913C-0.103443 12.605 -0.103443 13.2759 0.310329 13.6897C0.724101 14.1034 1.39496 14.1034 1.80873 13.6897L7 8.4984L12.1913 13.6897C12.605 14.1034 13.2759 14.1034 13.6897 13.6897C14.1034 13.2759 14.1034 12.605 13.6897 12.1913L8.4984 7L13.6897 1.80873C14.1034 1.39496 14.1034 0.724101 13.6897 0.310329Z" fill="#828282"/>
                    </svg>

                    :
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 18.3333C20 19.2538 19.2538 20 18.3333 20H1.66667C0.746193 20 0 19.2538 0 18.3333C0 17.4129 0.746192 16.6667 1.66667 16.6667H18.3333C19.2538 16.6667 20 17.4129 20 18.3333ZM20 10C20 10.9205 19.2538 11.6667 18.3333 11.6667H1.66667C0.746193 11.6667 0 10.9205 0 10C0 9.07952 0.746192 8.33333 1.66667 8.33333H18.3333C19.2538 8.33333 20 9.07952 20 10ZM20 1.66667C20 2.58714 19.2538 3.33333 18.3333 3.33333H1.66667C0.746193 3.33333 0 2.58714 0 1.66667C0 0.746192 0.746192 0 1.66667 0H18.3333C19.2538 0 20 0.746192 20 1.66667Z"
                            fill="#828282"/>
                    </svg>
            }
        </>
    );
};

export default IconMenu;