import React, {FC} from 'react';

interface T {
    active?: boolean
}

const IconPartner: FC<T> = ({active}) => {
    return (
        <>
            {
                active ?
                    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.4 18H0C0 14.0236 3.22355 10.8 7.2 10.8C11.1765 10.8 14.4 14.0236 14.4 18H12C12 15.349 9.85097 13.2 7.2 13.2C4.54903 13.2 2.4 15.349 2.4 18ZM20.4 14.4H18V10.8H14.4V8.4H18V4.8H20.4V8.4H24V10.8H20.4V14.4ZM7.2 9.6C4.54903 9.6 2.4 7.45097 2.4 4.8C2.4 2.14903 4.54903 0 7.2 0C9.85097 0 12 2.14903 12 4.8C11.9967 7.4496 9.8496 9.59669 7.2 9.6ZM7.2 2.4C5.88888 2.40133 4.82147 3.45462 4.80268 4.76561C4.78389 6.0766 5.82068 7.16004 7.13123 7.19894C8.44178 7.23784 9.541 6.2178 9.6 4.908V5.388V4.8C9.6 3.47452 8.52548 2.4 7.2 2.4Z"
                            fill="url(#paint0_linear_979_55852)"/>
                        <defs>
                            <linearGradient id="paint0_linear_979_55852" x1="12" y1="0" x2="12" y2="18"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#EB5757"/>
                                <stop offset="1" stopColor="#F2994A"/>
                            </linearGradient>
                        </defs>
                    </svg>


                    :
                    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.4 18H0C0 14.0236 3.22355 10.8 7.2 10.8C11.1765 10.8 14.4 14.0236 14.4 18H12C12 15.349 9.85097 13.2 7.2 13.2C4.54903 13.2 2.4 15.349 2.4 18ZM20.4 14.4H18V10.8H14.4V8.4H18V4.8H20.4V8.4H24V10.8H20.4V14.4ZM7.2 9.6C4.54903 9.6 2.4 7.45097 2.4 4.8C2.4 2.14903 4.54903 0 7.2 0C9.85097 0 12 2.14903 12 4.8C11.9967 7.4496 9.8496 9.59669 7.2 9.6ZM7.2 2.4C5.88888 2.40133 4.82147 3.45462 4.80268 4.76561C4.78389 6.0766 5.82068 7.16004 7.13123 7.19894C8.44178 7.23784 9.541 6.2178 9.6 4.908V5.388V4.8C9.6 3.47452 8.52548 2.4 7.2 2.4Z"
                            fill="#828282"/>
                    </svg>
            }
        </>
    );
};

export default IconPartner;
