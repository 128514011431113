import React, {useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import {Box, Modal, Stack} from "@mui/material";
import IconClose from "../../../../shared/assets/images/icons/iconClose";
import Button from "@mui/material/Button";
import Step1 from "./step_1";
import Step2 from "./step_2";
import {useGetLinkRefQuery} from "../../../../store/API/userApi";

const ModalInfoAddAccount = ({open, handlerClose, confirm}: any) => {
    const [step, setStep] = useState(1)
    const {data, isLoading} = useGetLinkRefQuery('', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const ref = useRef<any>(null)

    useEffect(() => {
        ref?.current?.scrollTo({top: 0, behavior: 'smooth'});
    }, [step]);

    return (
        <Modal open={open} onClose={handlerClose}>

            <Box sx={{
                maxWidth: 838,
                maxHeight: '80%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',

                "@media (max-width:768px)": {
                    padding: '28px 14px !important'
                }
            }}>
                <Stack sx={{borderBottom: '1px solid #3C3C3C', marginBottom: '13.5px'}}>
                    <Stack onClick={handlerClose} sx={{position: "absolute", top: 28, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 blue" sx={{mb: 7, fontSize: '16px', fontWeight: 600}}>Регистрация у брокера и
                        открытие счета</Stack>
                </Stack>

                <div className={s.content} ref={ref}>
                    {step === 1 && <Step1 url={data ? data?.url : ''}/>}
                    {step === 2 && <Step2/>}
                    <Stack direction="row" justifyContent="flex-end" spacing={7} sx={
                        {
                            mt: '28px',
                            "@media (max-width:768px)": {
                                flexDirection: 'column !important',
                                alignItems: 'flex-end'
                            }
                        }}
                    >
                        <Button sx={{
                            width: 'fit-content',
                        }} onClick={() => {
                            handlerClose()
                        }}
                                color={"inherit"}>{'Закрыть'}</Button>
                        <Button sx={{
                            width: 'fit-content',
                            "@media (max-width:768px)": {
                                marginTop: '14px !important'
                            }
                        }} onClick={() => {
                            confirm()
                        }}
                                color={"success"}>{'Продолжить'}</Button>
                    </Stack>
                </div>


            </Box>
        </Modal>
    )
        ;
};

export default ModalInfoAddAccount;
