import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {headersAccept, headersAuthorization, headersContent} from "./APIToken";

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}/authentication`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
            headers.set("Authorization", headersAuthorization)
        },
    }),
    tagTypes: ['ProfileSelector'],
    endpoints: (build) => ({
        getProfile: build.query({
            query: () => ({
                url: '/check-token'
            }),
            keepUnusedDataFor: 0,
            providesTags: ['ProfileSelector'],
        }),
        getProfileUpdate: build.mutation({
            query: () => ({
                url: '/check-token'
            }),
            invalidatesTags: ['ProfileSelector']
            // keepUnusedDataFor: 0,
        }),
    })
});

export const {useGetProfileQuery,useGetProfileUpdateMutation} = profileApi;

export const {getProfile} = profileApi.endpoints;
