import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.css'
import InputSetting from "./inputSetting";
import {Button, Divider, FormControl, MenuItem, Select, Skeleton, Slider, Stack, Switch} from "@mui/material";
import classNames from "classnames";
import {DashboardContext} from "../../../dashboardTabs";
import {
    useChangeSettingStatusMutation,
    useCreateSettingsMutation, useDeleteSettingChartMutation, useGetCalcSpeculativeQuery,
    useGetSettingsChartQuery, useSetSpeculativeLimitMutation,
    useUpdateSettingsMutation
} from "../../../../../store/API/chartApi";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import IconClose from "../../../../../shared/assets/images/icons/iconClose";
import Modal from "@mui/material/Modal";
import {useSearchParams} from "react-router-dom";

export const units = [
    //TODO: remove m1
    // {id: 8, title: <>1 минута</>, value: 'm1', timeUnit: "minute"},
    {id: 2, title: <>5 минут</>, value: 'm5', timeUnit: "minute"},
    {
        id: 3,
        title: <>15 минут</>,
        value: 'm15',
        timeUnit: "minute"
    },
    {
        id: 4,
        title: <>30 минут</>,
        value: 'm30',
        timeUnit: "minute"
    },
    {id: 5, title: <>&nbsp;1 час</>, value: 'h1', timeUnit: "hour"},
    {id: 6, title: <>&nbsp;4 часа</>, value: 'h4', timeUnit: "hour"},
    {id: 7, title: <>&nbsp;1 день</>, value: 'd1', timeUnit: "day"},
];

function gridCost(value: any, percent: any, step: any, lot: any, ordersCount: any) {
    const MULTIPLIER = (100 - step) / 100;
    let result = {value: 0, percent: 0};
    value *= lot
    percent *= lot

    for (let i = 0; i < ordersCount; i++) {
        result.value += value;
        result.percent += percent;
        value *= MULTIPLIER;
        percent *= MULTIPLIER;
    }

    return result;
}

const defaultSettings = {
    "indicator": {
        period: 150,
        coefficient: 1.5,
        up: 0,
        down: 0,
    },
    "take_profit": {
        type: "chanel_percent",
        value: 70,
    },
    "lot": 1,
    "step_between_orders": 0.5,
    "grid_orders_count": 2,
    "grid_order_offset": 0.5,
    "grids_count": 2,
    "min_channel_width": 0,
    "time_frame": 'm15',
    "min_profit": 0.5,
    "drying": false,
    "disable_trade_if_price_higher": 0,
}

const SettingsSpeculative = ({
                                 settingChart,
                                 refetchTable,
                                 setSettingChartData,
                                 setLineIndicator,
                                 lineIndicator
                             }: any) => {
    // const [timeLine, setTimeLine] = useState<any>('m15')
    const [paramsSearch, setParamsSearch] = useSearchParams()

    const idSetting = paramsSearch.get('symbolID') ? paramsSearch.get('symbolID') : (settingChart?.id || null)

    const {dataDashboard} = useContext(DashboardContext)
    const [openRemoveModal, setOpenRemoveModal] = useState(false)

    const {data, isFetching, isError} = useGetSettingsChartQuery({
        idProduct: dataDashboard?.product?.product_data?.id,
        idInstrument: idSetting,
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !dataDashboard?.id || !idSetting
    })
    const {data: dataCalc, isFetching: isFetchingCalc} = useGetCalcSpeculativeQuery({
        idProduct: dataDashboard?.product?.product_data?.id,
        idInstrument: idSetting,
    }, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        skip: !dataDashboard?.id || !idSetting
    })
    console.log(dataCalc)
    const [createSettings, {isLoading}] = useCreateSettingsMutation()
    const [updateSettings, {isLoading: isLoadingUpdate}] = useUpdateSettingsMutation()
    const [setSpeculativeLimit, {isLoading: isLoadingLimit}] = useSetSpeculativeLimitMutation()
    const [deleteSettingChart, {isLoading: isLoadingDelete}] = useDeleteSettingChartMutation()
    const [changeSettingStatus, {isLoading: isLoadingStatus}] = useChangeSettingStatusMutation()

    const limitMax = (dataDashboard && dataDashboard?.product && dataDashboard?.product?.product_data) ? (dataDashboard?.product?.product_data?.speculate_limit || 0) : 0
    const balanceValue = (dataDashboard && dataDashboard?.stats && dataDashboard?.stats?.balance) ? (dataDashboard?.stats?.balance?.orders_cost || 0) : 0

    const [settings, setSettings] = useState<any>(defaultSettings)
    const [limit, setLimit] = useState(null)

    const handleChangeStatus = (status: any) => {
        changeSettingStatus({
            idProduct: dataDashboard?.product?.product_data?.id, idInstrument: idSetting, status: status
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                toast.success(status === 'disable' ? 'Отключено' : 'Включено')
                refetchTable()
            })
            .catch((e) => {
                toast.error('Ошибка')
            })
    }
    const handleDelete = () => {
        deleteSettingChart({
            idProduct: dataDashboard?.product?.product_data?.id, idInstrument: idSetting
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                toast.success('Настройки удалены')
                setOpenRemoveModal(false)
            })
            .catch((e) => {
                toast.error('Ошибка')
            })
    }
    const handleLimit = () => {
        setSpeculativeLimit({
            idProduct: dataDashboard?.product?.product_data?.id, limit: limit ? limit : (limitMax || 0)
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                toast.success('Лимит установлен')
            })
            .catch((e) => {
                toast.error('Ошибка')
            })
    }
    const handleCreate = () => {
        createSettings({
            idProduct: dataDashboard?.product?.product_data?.id, idInstrument: idSetting, body: settings
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                toast.success('Настройки успешно созданы')
            })
            .catch((e) => {
                toast.error('Ошибка создания')
            })
    }
    const handleUpdate = () => {
        updateSettings({
            idProduct: dataDashboard?.product?.product_data?.id, idInstrument: idSetting, body: settings
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                toast.success('Настройки успешно сохранены')
            })
            .catch((e) => {
                toast.error('Ошибка создания')
            })
    }

    const valuePercent = gridCost((+dataCalc?.value || 0), (+dataCalc?.percent || 0), (+settings?.step_between_orders || 0), (+settings?.lot || 0), (+settings.grid_orders_count || 0))

    console.log(settings)

    useEffect(() => {
        if (data?.data) {
            setSettings(data?.data)
            setSettingChartData(data?.data?.indicator)
        } else {
            setSettings(defaultSettings)
            setSettingChartData(defaultSettings?.indicator)
        }
    }, [data?.data])
    useEffect(() => {
        setLimit(limitMax)
    }, [limitMax])
    const isLimitNinetyNinePercent = (limit || 0) >= (0.99 * balanceValue);

    if (isError) {
        return <div className={s.empty_box}>
            Ошибка загрузки
        </div>
    }
    if (isFetching) {
        return <Skeleton variant="rounded" width={440} height={300}/>
    }
    return (
        <div className={s.main}>
            {/*background: rgba(213,55,55,0.1);*/}
            <div className={s.wrapper} style={{
                background: isLimitNinetyNinePercent ? 'rgba(213,55,55,0.1)' : 'linear-gradient(90deg, #1F1F1F 0%, rgba(31, 31, 31, 0) 100%)'
            }}>
                <h3 className={s.title}>Лимит активных ордеров</h3>
                <div className={s.wrapper_input} style={{
                    border: isLimitNinetyNinePercent ? '1px solid #dc4747' : '1px solid #303030'
                }}>
                    <InputSetting value={limit} min={0} max={balanceValue}
                                  onChange={(value: any) => {
                                      setLimit(value)
                                  }}/>
                    <p className={s.text}>/</p>
                    <p className={s.text}>{balanceValue}</p>
                </div>

                <Button disabled={isLoadingLimit} onClick={handleLimit} sx={{
                    width: '100%', marginTop: '12px',
                    background: '#29312C',
                }} color="success">Сохранить</Button>
            </div>
            {settingChart ?
                <div className={classNames(s.wrapper, s.sub_wrapper)}>
                    <h3 className={s.title}>Настройки Торговля</h3>
                    <h3 className={classNames(s.title, s.title_blue)}>{`(${settingChart?.symbol}) - ${settingChart?.description}`}</h3>

                    <Button onClick={() => {
                        if (!data?.data) {
                            handleCreate()
                        } else {
                            handleUpdate()
                        }
                    }} sx={{
                        width: '100%',
                        background: '#413A25',
                    }} disabled={isLoading || isLoadingUpdate}
                            color="warning">{!data?.data ? 'Добавить' : 'Сохранить'}</Button>

                    <FormControl sx={{
                        maxWidth: '100% !important',
                    }}>
                        <Select
                            sx={{
                                maxWidth: '100% !important',
                                '& .MuiSelect-select': {
                                    textAlign: 'center !important'
                                }
                            }}
                            value={settings?.time_frame}
                            inputProps={{'aria-label': 'Without label'}}
                        >
                            {units?.map((el, i) => {
                                return <MenuItem
                                    onClick={() => setSettings({...settings, time_frame: el.value})}
                                    key={el.id}
                                    value={el.value}>
                                    {el.title}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text, s.text_v2)}>Период</p>

                        <InputSetting value={settings.indicator?.period} min={0} onChange={(value: any) => {
                            setSettings({...settings, indicator: {...settings?.indicator, period: value}})
                            setSettingChartData({...settings?.indicator, period: value})
                        }}/>
                    </div>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text, s.text_v2)}>Коэф</p>

                        <InputSetting value={settings.indicator?.coefficient} min={0} onChange={(value: any) => {
                            setSettings({...settings, indicator: {...settings?.indicator, coefficient: value}})
                            setSettingChartData({...settings?.indicator, coefficient: value})
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text, s.text_v2)}>Верх</p>

                        <InputSetting value={settings.indicator?.up} min={0} onChange={(value: any) => {
                            setSettings({...settings, indicator: {...settings?.indicator, up: value}})
                            setSettingChartData({...settings?.indicator, up: value})
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text, s.text_v2)}>Низ</p>

                        <InputSetting value={settings.indicator?.down} min={-300} max={300} onChange={(value: any) => {
                            setSettings({...settings, indicator: {...settings?.indicator, down: value}})
                            setSettingChartData({...settings?.indicator, down: value})
                        }}/>
                    </div>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_clear)}>
                        <div className={classNames(s.clear_wrapper)}>
                            <p className={classNames(s.text)}>Лот</p>

                            <InputSetting value={settings.lot} min={0} onChange={(value: any) => {
                                setSettings({...settings, lot: value})
                            }}/>
                        </div>

                        <div className={classNames(s.clear_wrapper)}>
                            <p className={classNames(s.text)}>По 1 сетке <span
                                className={s.green_box}>{`${Number(valuePercent?.percent || 0)?.toFixed(3)}%`}</span>
                            </p>

                            <p className={classNames(s.text)}>{Number(valuePercent?.value || 0)?.toFixed(3)}</p>
                        </div>
                        <div className={classNames(s.clear_wrapper)}>
                            <p className={classNames(s.text)}>В общем <span
                                className={s.green_box}>{settings?.grids_count === 0 ? Number(valuePercent?.percent)?.toFixed(3) : `${Number((valuePercent?.percent || 0) * settings?.grids_count)?.toFixed(3)}%`}</span>
                            </p>

                            <p className={classNames(s.text)}>{settings?.grids_count === 0 ? Number(valuePercent?.value)?.toFixed(3) : Number((valuePercent?.value || 0) * settings?.grids_count)?.toFixed(3)}</p>
                        </div>
                    </div>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Тейкпрофит</p>

                        <InputSetting value={settings.take_profit?.value} min={0} onChange={(value: any) => {
                            setSettings({...settings, take_profit: {...settings?.take_profit, value: value}})
                        }}/>
                    </div>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_clear)}>
                        <p className={classNames(s.text,)} style={{textAlign: 'left', width: '100%'}}>Тип профита</p>

                        <div className={s.btn_box}>
                            {/*chanel_percent*/}
                            <Button onClick={() => {
                                setSettings({
                                    ...settings,
                                    take_profit: {...settings?.take_profit, type: 'chanel_percent'}
                                })
                            }} sx={{
                                width: '100%',
                                background: settings?.take_profit?.type === 'chanel_percent' ? '#29312C' : 'inherit',
                            }} color={settings?.take_profit?.type === 'chanel_percent' ? "success" : 'inherit'}>% От
                                канала</Button>

                            <Button onClick={() => {
                                setSettings({
                                    ...settings,
                                    take_profit: {...settings?.take_profit, type: 'price_percent'}
                                })
                            }} sx={{
                                width: '100%',
                                background: settings?.take_profit?.type === 'price_percent' ? '#29312C' : 'inherit',
                            }} color={settings?.take_profit?.type === 'price_percent' ? "success" : 'inherit'}>% От
                                цены</Button>

                            {/*point*/}
                            <Button onClick={() => {
                                setSettings({
                                    ...settings,
                                    take_profit: {...settings?.take_profit, type: 'point'}
                                })
                            }} sx={{
                                width: '100%',
                                background: settings?.take_profit?.type === 'point' ? '#29312C' : 'inherit',
                            }}
                                    color={settings?.take_profit?.type === 'point' ? "success" : 'inherit'}>Пункты</Button>
                        </div>
                    </div>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Шаг между ордерами %</p>

                        <InputSetting
                            onFocus={() => setLineIndicator({
                                step_between_orders: settings?.step_between_orders,
                                grid_orders_count: settings?.grid_orders_count,
                            })}
                            onBlur={() => setLineIndicator(null)}

                            value={settings.step_between_orders} min={0} onChange={(value: any) => {
                            setSettings({...settings, step_between_orders: value})
                            setLineIndicator({
                                ...lineIndicator,
                                step_between_orders: value,
                            })
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Количество ордеров в сетке</p>

                        <InputSetting
                            onFocus={() => setLineIndicator({
                                step_between_orders: settings?.step_between_orders,
                                grid_orders_count: settings?.grid_orders_count,
                            })}
                            step={1}
                            onBlur={() => setLineIndicator(null)}
                            value={settings.grid_orders_count} min={0} onChange={(value: any) => {
                            setSettings({...settings, grid_orders_count: value})
                            setLineIndicator({
                                ...lineIndicator,
                                grid_orders_count: value,
                            })
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Отступ след. сетки %</p>

                        <InputSetting value={settings.grid_order_offset} min={0} onChange={(value: any) => {
                            setSettings({...settings, grid_order_offset: value})
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Количество сеток (макс.)</p>

                        <InputSetting value={settings.grids_count} min={0} onChange={(value: any) => {
                            setSettings({...settings, grids_count: value})
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v3)}>
                        <div className={classNames(s.clear_wrapper)}>
                            <p className={classNames(s.text,)}>Мин. ширина канала</p>

                            <InputSetting value={settings.min_channel_width} min={0} max={100}
                                          onChange={(value: any) => {
                                              setSettings({...settings, min_channel_width: value})
                                          }}/>
                        </div>
                        <div className={s.info_slide}>
                            <p>0%</p>
                            <p>100%</p>
                        </div>
                        <Slider
                            size="medium"
                            defaultValue={settings.min_channel_width}
                            //@ts-ignore
                            value={settings.min_channel_width}
                            min={0}
                            max={100}
                            step={1}
                            onChange={(e: any) => {
                                setSettings({...settings, min_channel_width: e.target?.value})
                            }}
                            sx={{
                                padding: '0 !important',
                            }}
                            valueLabelDisplay="off"
                            // marks={marks}
                        />
                    </div>

                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Мин. прибыль</p>

                        <InputSetting value={settings.min_profit} min={0} onChange={(value: any) => {
                            setSettings({...settings, min_profit: value})
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Не торговать если TP выше</p>

                        <InputSetting value={settings.disable_trade_if_price_higher} min={0} onChange={(value: any) => {
                            setSettings({...settings, disable_trade_if_price_higher: value})
                        }}/>
                    </div>
                    <div className={classNames(s.wrapper_input, s.wrapper_input_v2)}>
                        <p className={classNames(s.text,)}>Сушка</p>

                        <Switch
                            defaultChecked={settings?.drying}
                            checked={settings?.drying}
                            size="small"
                            onChange={(e) => {
                                setSettings({...settings, drying: e.target.checked})
                                // handleBadgeVisibility()
                                //
                                // if (!e.target.checked) {
                                //
                                //     onChange && onChange(null)
                                // } else {
                                //
                                //     onChange && onChange(value || (minValue || 0))
                                // }
                            }}
                        />
                    </div>

                    {data?.data &&
                        <Button onClick={() => handleChangeStatus(settings?.is_active ? 'disable' : 'enable')} sx={{
                            width: '100%',
                            // background: '#29312C',
                            background: !settings?.is_active ? "#29312C" : '#2C2626',
                        }} color={!settings?.is_active ? "success" : "error"}
                                disabled={isLoadingStatus}>{!settings?.is_active ? 'Включить' : 'Отключить'}</Button>}
                    {data?.data && <Button onClick={() => setOpenRemoveModal(true)} sx={{
                        width: '100%',
                        background: '#2C2626',
                    }} color="error" disabled={isLoadingDelete}>Удалить</Button>}
                </div> : <div className={s.empty_box}>
                    Выберите инструменты
                </div>}

            <Modal
                open={openRemoveModal}
                onClose={() => setOpenRemoveModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >

                <Box sx={{maxWidth: 460}}>
                    <Stack onClick={() => setOpenRemoveModal(false)}
                           sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90" sx={{mb: 7}}>Удаление</Stack>

                    <Divider variant="fullWidth" sx={{mb: 7}}/>

                    <Stack spacing={7}>
                        <div className="h2">
                            <span>Вы уверены, что хотите удалить настройки ?</span>
                        </div>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>

                        <Button onClick={() => setOpenRemoveModal(false)} color="error">Отмена</Button>
                        <Button onClick={handleDelete} color="primary">Подтвердить</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default SettingsSpeculative;