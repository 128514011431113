import React from 'react';
import s from './styles.module.css'

const InputSetting = ({
                          onChange, value, min = 0, step = 0.5, max = null, onBlur = () => {
    }, onFocus = () => {
    }
                      }: any) => {
    return (
        <input onFocus={onFocus} min={min} onBlur={onBlur} className={s.input} step={step} type="number" value={value}
               onKeyDown={(e: any) => {
                   const invalidChars = min === 0 ? ['-', '+', 'e', 'E',] : ['+', 'e', 'E',];

                   if (invalidChars.includes(e.key)) {
                       e.preventDefault();
                   }
               }} onChange={(e: any) => {
            if (max !== null && +e.target?.value >= max) {
                onChange(max)
            } else if (+e.target?.value >= min && (max !== null ? +e.target?.value < max : true)) {
                onChange(e.target?.value)
            }
        }}/>
    );
};

export default InputSetting;