import React, {FC, useState} from 'react';
import {Stack, useMediaQuery} from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {useGetAllServersQuery} from "../store/API/serverApi";
import IconPlus from "../shared/assets/images/icons/iconPlus";
import ServerModal from "../entities/components/modal/serverModal";

interface IType {
    children?: any
}

const BrokerServersList: FC<IType> = ({children}) => {
    const {data: isDataServers} = useGetAllServersQuery('/servers', {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const mediaQuery = useMediaQuery('(min-width:980px)');
    const [openModal, setOpenModal] = useState(false);
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [serverId, setServerId] = useState(0)
    const [serverName, setServerName] = useState('')
    const [valuesServerBroker, setValuesServerBroker] = useState<any>(null)

    return (
        <Stack spacing={7}>
            <Button
                fullWidth
                onClick={() => setOpenModal(true)}
                color="neutral"
                startIcon={<IconPlus/>}
                sx={{height: 48, justifyContent: 'flex-start', fontSize: 16, color: '#BDBDBD'}}
            >
                Добавить сервер
            </Button>
            {
                isDataServers?.data && isDataServers?.data.map(item =>
                    <Paper key={item.id}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={7}>
                            <span className="subHeadersBold white-100">{item.title}</span>
                            <Stack direction="row" spacing={7}>
                                <Button onClick={() => {
                                    setValuesServerBroker(item)
                                    setTimeout(() => setOpenModalUpdate(true), 0)

                                    setServerId(item.id)
                                }} color="neutral">Настройки</Button>
                                <Button onClick={() => {
                                    setOpenModalDelete(true)
                                    setServerId(item.id)
                                    setServerName(item.title)
                                }} color="error">Удалить</Button>
                            </Stack>
                        </Stack>
                    </Paper>
                )
            }

            {openModal &&
                <ServerModal title="Добавить сервер" isAddServer openModal={openModal} closeModal={setOpenModal}/>}
            {openModalUpdate &&
                <ServerModal valuesServerBroker={valuesServerBroker} title="Настройки сервера" isUpdateServer
                             updateServerNumber={serverId}
                             openModal={openModalUpdate} closeModal={setOpenModalUpdate}/>}
            {openModalDelete && <ServerModal title="Подтверждение"
                                             isDeleteServer
                                             updateServerNumber={serverId}
                                             serverName={serverName}
                                             openModal={openModalDelete}
                                             closeModal={setOpenModalDelete}/>}

        </Stack>
    );
};

export default BrokerServersList;
