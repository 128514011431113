import React from 'react';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";

const ChangeStatusAdminTrader = ({open, handlerClose, isHidden, name,confirm}: any) => {
    const text = isHidden ? 'Вы уверены что хотите скрыть' : 'Вы уверены что хотите отобразить'

    return (
        <Modal open={open} onClose={handlerClose}>
            <Box sx={{maxWidth: 460}}>
                <Stack className="h2 white-90" sx={{mb: 7, textAlign: 'center'}}>{`${text} ${name} ?`}</Stack>
                <Stack direction="row" justifyContent="flex-end" spacing={7} sx={{mt: 7}}>
                    <Button color="success" onClick={confirm}>Подтвердить</Button>
                    <Button color="error" onClick={handlerClose}>Отмена</Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ChangeStatusAdminTrader;
