import React, {useEffect, useState} from 'react';
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {routes} from "./routes/routes";
import '../shared/assets/styles/reset.scss';
import '../shared/assets/styles/libs.scss';
import '../shared/assets/styles/globals.scss';
import Auth from "../pages/auth";
import {useAppSelector} from "../hooks/useRedux";
import Sidebar from "../widgets/sidebar";
import {Grid} from "@mui/material";
import ChatWithSettings from "../entities/components/chartWithSettings";
import ChatWithSettingsv2 from "../entities/components/chartWithSettingsv2";
import Home from "../pages/home";
import AccountDashboard from "../pages/accountDashboard";
import Products from "../pages/products/products";
import Partner from "../pages/partner";
import Settings from "../pages/settings";
import Support from "../pages/support";
import TraderDashboard from "../pages/traderDashboard";
import Admin from "../pages/admin";
import AdminForexAccounts from "../pages/adminForexAccounts";
import AdminBankRequisites from "../pages/adminBankRequisites";
import AdminSets from "../pages/adminSets";
import AdminTransaction from "../pages/adminTransaction";
import AdminTraders from "../pages/adminTraders";
import AdminBrokerServers from "../pages/adminBrokerServers";
import ChartPage from "../widgets/dashboardTabs/chartPage";
import HistoryPage from "../widgets/dashboardTabs/historyPage";
import OpenPositionsPage from "../widgets/dashboardTabs/openPositions";
import TradePage from "../widgets/dashboardTabs/tradePage";


function App() {
    const {isAuth} = useAppSelector(state => state.authReducer)
    const [userToken, _] = useState<any>(localStorage.getItem('token'))
    const [hiddeSideBar, setHiddeSideBar] = useState<boolean>(false)
    const {pathname} = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [pathname])

    if (isAuth) navigate(0)

    return (
        <div style={{
            display: 'flex',
            // maxWidth: '1280px',
            width: '100%',
            margin: '0 auto',
            // background:'#fff'
        }} className={'App'}>

            {userToken && <Grid sx={{
                width: hiddeSideBar ? 100 : 300,
                minWidth: hiddeSideBar ? 100 : 300,
                maxWidth: hiddeSideBar ? 100 : 300,
                overflow: 'hidden',
                transition: '.3s all',
                "@media (max-width:1220px)": {
                    width: 'calc(100% - 10px)',
                    minWidth: '100px',
                    maxWidth: 'calc(100% - 10px)',
                }
            }}>
                <Sidebar hiddeSideBar={hiddeSideBar} setHiddeSideBar={setHiddeSideBar}
                         isAdmin={pathname.includes('admin') && !isAuth}/>
            </Grid>}
            <div style={{
                width: '100%',
                marginTop: '20px',
                margin: '20px 20px 0 20px'
            }}>
                <Routes>

                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/reg'} element={<Home/>}/>
                    <Route path={'/auth'} element={<Auth/>}/>
                    <Route path={'/tariff'} element={<Products/>}/>

                    <Route path={'/exchange-account/:id'} element={<AccountDashboard/>}>
                        <Route index element={<ChartPage/>}/>
                        <Route path={'trade'} element={<TradePage/>}/>
                        <Route path={'history'} element={<HistoryPage/>}/>
                        <Route path={'open-position'} element={<OpenPositionsPage/>}/>
                    </Route>

                    <Route path={'/partner'} element={<Partner/>}/>
                    <Route path={'/settings'} element={<Settings/>}/>
                    <Route path={'/support'} element={<Support/>}/>
                    <Route path={'/trader-dashboard/:id'} element={<TraderDashboard/>}/>
                    <Route path={'/admin/users'} element={<Admin/>}/>
                    <Route path={'/admin/forex'} element={<AdminForexAccounts/>}/>
                    <Route path={'/admin/bank-requisites'} element={<AdminBankRequisites/>}/>
                    <Route path={'/admin/sets'} element={<AdminSets/>}/>
                    <Route path={'/admin/transaction'} element={<AdminTransaction/>}/>
                    <Route path={'/admin/traders'} element={<AdminTraders/>}/>
                    <Route path={'/admin/broker-servers'} element={<AdminBrokerServers/>}/>

                </Routes>
            </div>
        </div>
    );
}

export default App;
