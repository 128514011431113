import React, {FC, useState} from 'react';
import Box from "@mui/material/Box";
import {styled, Tabs, useMediaQuery} from "@mui/material";
import Tab from "@mui/material/Tab";

interface ITabsName {
    name: string;
    icon?: React.ReactElement;
}

interface IType {
    tabsName: ITabsName[];
    onTabsChange: (value: number) => void;
    tabsValue: number | null;
    variant?: 'standard' | 'scrollable' | 'fullWidth' | undefined;
    size?: 'sizeSmall';
    isModalVariant?: boolean
}

const AntTab = styled((props: any) => <Tab disableRipple sx={{

    "@media (max-width: 980px)": {
        minWidth: !props.isModalVariant ? '100%' : 'fit-content'
    }

}} {...props}/>)(
    ({theme}) => ({
        flexDirection: 'row',
        gap: 22,
        padding: `14px 28px`,
        border: ` 0.5px solid #3C3C3C`,
        // border: '1px solid red',
        borderRadius: 10,
        fontWeight: 600,
        fontSize: 16,

        '&.Mui-selected': {
            color: '#fff',
        },
        '&.sizeSmall': {
            padding: `8px `,
            fontSize: 12,
            borderRadius: 5
        },

    }),
);

const TabsHeader: FC<IType> = ({tabsName, onTabsChange, variant, tabsValue, size, isModalVariant = false}) => {
    const [value, setValue] = useState(tabsValue);

    const mediaQuery = useMediaQuery('(min-width:980px)');
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        onTabsChange(newValue)
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box sx={{mb: 7}}>
            <Tabs value={value} onChange={handleChange}
                  orientation={!mediaQuery && !isModalVariant ? "vertical" : "horizontal"}
                  variant={variant && variant} sx={{
                flexDirection: 'column !important',
                flexGrow: ''
            }}>
                {
                    tabsName && tabsName.map((item, index) =>
                        <AntTab isModalVariant={isModalVariant} className={size && size} key={item.name}
                                icon={item.icon && item.icon}
                                label={item.name} {...a11yProps(index)} />
                    )
                }
            </Tabs>
        </Box>
    );
};

export default TabsHeader;
