import React, {FC, useState} from 'react';
import Paper from "@mui/material/Paper";
import {FormControl, Grid, InputLabel, MenuItem, Select, useMediaQuery} from "@mui/material";
import NickName from "../../shared/components/nickName";
import imgStrategyGrid from "../../shared/assets/images/strategy.png";
import imgStrategyStopLoss from "../../shared/assets/images/strategys-stop-loss.png";
import CurrentValues from "./currentValues";
import {Link} from "react-router-dom";
import CopytradingChart from "./chart/copitradingChart";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from "@mui/material/Box";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ChangeStatusAdminTrader from "./modal/changeStatusAdminTrader";

interface IType {
    idTrader?: any;
    idTraderSubscribe?: any;
    openModal?: any;
    deleteTrader?: any;
    id: string | number;
    name: string;
    strategy: string;
    stats: any;
    changeStatusTrader?: any;
    graph: [];
    isSelect?: boolean;
    showEyes?: boolean;
    isActive?: boolean;
    isNeedRedirect?: boolean;
    redirectClick?: any
    setSettingsDefaultValueHandler?: (id: any) => void
}

const TraderItem: FC<IType> = ({
                                   isNeedRedirect = false,
                                   redirectClick,
                                   setSettingsDefaultValueHandler,
                                   idTrader,
                                   idTraderSubscribe,
                                   openModal,
                                   changeStatusTrader,
                                   deleteTrader,
                                   id,
                                   showEyes = false,
                                   isActive,
                                   name,
                                   strategy,
                                   stats,
                                   graph,
                                   isSelect,
                               }) => {

    const mediaQuery = useMediaQuery('(min-width:980px)');

    const [openModalStatus, setOpenModalStatus] = useState(false)

    return (

        <Paper sx={{
            height: mediaQuery ? 68 : 'inherit',
            alignItems: "center",
            "@media (min-width:980px)": {
                p: `7px  28px`,
            }
        }}>
            <ChangeStatusAdminTrader open={openModalStatus} isHidden={isActive}
                                     name={name} confirm={async () => {
                await changeStatusTrader(id, !isActive)
                setOpenModalStatus(false)
            }} handlerClose={() => setOpenModalStatus(false)}/>
            <Grid container spacing={10} columns={14} wrap="wrap" alignItems="center" sx={{
                marginTop: '-28px'
            }}>
                <Grid item xs={14} md={2}>
                    <NickName name={name} number={id}
                              redirect={
                                  {
                                      isNeedRedirect: isNeedRedirect,
                                      redirectClick: redirectClick
                                  }
                              }
                              strategy={strategy === 'grid' ? "сеточная" : "со стопами"}
                              direction="row-reverse"
                              avatar={strategy === 'grid' ? imgStrategyGrid : imgStrategyStopLoss}
                              justifyContent="flex-end"/>
                </Grid>
                <Grid item xs={14} md={3}>
                    <CopytradingChart marginBottom={'-13px'} height={83} dataChart={graph} id={id}/>
                </Grid>
                <Grid item xs={14} md={isSelect ? (showEyes ? 6.5 : 7) : 9}>
                    <CurrentValues
                        dropdown={stats?.dropdown}
                        balance={stats?.balance?.value}
                        depositLoad={stats?.deposit_load}
                        gainCurrentMonth={stats?.balance?.gain?.current_month}
                        gainAll={stats?.balance?.gain?.all}
                    />
                </Grid>

                <Grid item xs={14} md={2}>
                    {
                        isSelect &&
                        <FormControl fullWidth>
                            <InputLabel shrink={false} sx={{left: 24, top: -8,}}>
                                Управ...
                            </InputLabel>
                            <Select
                                IconComponent={"select"}
                                fullWidth
                            >
                                <MenuItem onClick={() => {
                                    if (idTrader) idTrader(id)

                                    setSettingsDefaultValueHandler && setSettingsDefaultValueHandler(id)
                                    openModal(true)
                                }}>
                                    Настройки
                                </MenuItem>
                                <MenuItem sx={{color: '#56CCF2'}} component={Link} to={`/trader-dashboard/${id}`}>
                                    Страница
                                </MenuItem>
                                <MenuItem onClick={() => deleteTrader(idTraderSubscribe)} sx={{color: '#FF8888'}}>
                                    Удалить
                                </MenuItem>
                            </Select>
                        </FormControl>
                    }

                </Grid>
                {showEyes && <Box
                    onClick={() => setOpenModalStatus(true)}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        height: '100%',
                        position: 'absolute',
                        display: 'flex',
                        right: '20px',
                        top: 0,
                        "@media (max-width:980px)": {
                            height: 'fit-content',
                            right: '110px',
                            top: '18px'
                        }
                    }}>
                    {isActive && <VisibilityIcon sx={{
                        color: '#BDBDBD'
                    }}/>}
                    {!isActive && <VisibilityOffIcon sx={{
                        color: '#BDBDBD'
                    }}/>}
                </Box>}
            </Grid>
        </Paper>

    );
};

export default TraderItem;
