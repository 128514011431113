import React, {useContext} from 'react';
import Tabs3 from "../tabs_3";
import {DashboardContext} from "../dashboardTabs";

const OpenPositionsPage = () => {
    const {
        isTrader
    } = useContext(DashboardContext)
    return (
        <Tabs3 isTrader={isTrader}
            // dataTrader={dataTrader}
               dataTrader={null}
        />
    );
};

export default OpenPositionsPage;