import React, {FC} from 'react';

interface IType {
    deleteBtn?: any
}

const IconClose: FC<IType> = ({deleteBtn}) => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.77834 0.221664C9.48279 -0.0738879 9.0036 -0.0738878 8.70805 0.221664L5 3.92971L1.29195 0.221664C0.996399 -0.0738879 0.517215 -0.0738879 0.221664 0.221664C-0.0738879 0.517215 -0.0738878 0.996399 0.221664 1.29195L3.92971 5L0.221664 8.70805C-0.0738879 9.0036 -0.0738879 9.48279 0.221664 9.77834C0.517215 10.0739 0.996399 10.0739 1.29195 9.77834L5 6.07029L8.70805 9.77834C9.0036 10.0739 9.48279 10.0739 9.77834 9.77834C10.0739 9.48279 10.0739 9.0036 9.77834 8.70805L6.07029 5L9.77834 1.29195C10.0739 0.996399 10.0739 0.517215 9.77834 0.221664Z"
                fill={deleteBtn ?"#FF8888":"#828282"}/>
        </svg>

    );
};

export default IconClose;