import React, {FC, useState} from 'react';
import Button from "@mui/material/Button";
import IconPlus from "../shared/assets/images/icons/iconPlus";
import Set from "./set";
import {Pagination, Skeleton, Stack, TextField} from "@mui/material";
import {useAddSetMutation, useGetAllAdminSetsQuery} from "../store/API/tradeSetsApi";
import SimpleModal from "../entities/components/modal/simpleModal";
import Paper from "@mui/material/Paper";

interface IType {
    children?: any
}

const AdminSetsList: FC<IType> = ({children}) => {
    const [page, setPage] = useState(1);
    const {data, isLoading, error} = useGetAllAdminSetsQuery(page, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })
    const [addSet] = useAddSetMutation()

    const [openModal, setOpenModal] = useState(false)
    const [nameSet, setNameSet] = useState('');
    const [textValue, setTextValue] = useState('');
    const [errorValues, setErrorValues] = useState<any>({})

    const clearErrors = () => {
        setErrorValues({})
        setNameSet('')
        setTextValue('')
    }

    if (error) {
        return (
            <Paper>
                <span className="h1">Не удалось загрузить данные</span>
            </Paper>
        )
    }
    const handleAddSet = async () => {
        if (textValue === '' || nameSet === '') {
            setErrorValues({
                description: {
                    isError: textValue === '' ? true : false,
                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                },
                name: {
                    isError: nameSet === '' ? true : false,
                    text: nameSet === '' ? 'Поле обязательно к заполнению' : null,
                },
            })
        } else {
            try {
                const res: any = await addSet({
                    name: nameSet,
                    description: textValue
                })

                if (res.error) {
                    let errors: any = {}
                    for (let key in res.error.data.errors) {
                        errors[key] = {
                            isError: true,
                            text: res.error.data.errors[key][0]
                        }
                    }
                    setErrorValues(errors)

                } else {
                    setOpenModal(false)
                    clearErrors()
                }

            } catch (e) {
                console.log(e)
            }
        }

    }
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    if (isLoading) {
        return (
            <Paper>
                <Skeleton variant="rounded" width={`100%`} height={433}/>
            </Paper>
        )
    }
    return (
        <Stack spacing={7}>
            <Button
                fullWidth
                onClick={() => setOpenModal(true)}
                startIcon={<IconPlus/>}
                sx={{height: 48, justifyContent: 'flex-start', color: '#BDBDBD'}}
            >
                Добавить сет
            </Button>
            {
                data &&
                data?.data.map((item: any) =>
                    <Set showEyes={true} key={item.id} data={item}/>
                )
            }
            {
                data?.meta?.pagination?.total_pages > 1 &&
                <Pagination
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    count={data?.meta?.pagination?.total_pages}
                    variant="outlined"
                    shape="rounded"
                    sx={{mr: 'auto'}}
                />

            }
            <SimpleModal title="Настройки" openModal={openModal}
                         closeModal={() => {
                             setOpenModal(false)
                             clearErrors()
                         }}>
                <Stack spacing={7}>
                    <TextField
                        fullWidth
                        error={errorValues?.name?.isError || false}
                        helperText={errorValues?.name?.isError && errorValues?.name?.text}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['name']: {
                                    isError: nameSet === '' ? true : false,
                                    text: nameSet === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={nameSet}
                        onChange={(e) => {
                            setNameSet(e.target.value)
                            setErrorValues({
                                ...errorValues, ['name']: {
                                    isError: false
                                }
                            })
                        }}
                        label="Название сета"
                        type="text"
                    />
                    <TextField
                        value={textValue}
                        error={errorValues?.description?.isError || false}
                        helperText={errorValues?.description?.isError && errorValues?.description?.text}
                        onBlur={() => {
                            setErrorValues({
                                ...errorValues, ['description']: {
                                    isError: textValue === '' ? true : false,
                                    text: textValue === '' ? 'Поле обязательно к заполнению' : null,
                                }
                            })
                        }}
                        multiline
                        minRows={10}
                        onChange={(e) => {
                            setTextValue(e.target.value)
                            setErrorValues({
                                ...errorValues, ['description']: {
                                    isError: false
                                }
                            })
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={7}>
                        <Button onClick={() => {
                            setOpenModal(false)
                            clearErrors()
                        }} color="error">Отмена</Button>
                        <Button
                            onClick={handleAddSet}
                            color="success">Добавить</Button>
                    </Stack>
                </Stack>
            </SimpleModal>
        </Stack>
    );
};

export default AdminSetsList;
