import React, {FC} from 'react';

interface T {
    visible?: any
}

const IconPassword: FC<T> = ({visible}) => {
    return (
        <>
            {
                visible ?
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.5 9.99906C6.26991 10.0136 5.05253 9.76084 3.9405 9.25989C3.07852 8.85939 2.30449 8.3063 1.65975 7.63014C0.976824 6.93104 0.439101 6.11513 0.075 5.22551L0 4.99983L0.07875 4.77415C0.443111 3.88531 0.979677 3.0696 1.6605 2.36952C2.30501 1.69342 3.07878 1.14032 3.9405 0.739767C5.05253 0.238836 6.26991 -0.0139661 7.5 0.000595173C8.73009 -0.013941 9.94746 0.23886 11.0595 0.739767C11.9215 1.14023 12.6955 1.69333 13.3403 2.36952C14.0245 3.06766 14.5624 3.88383 14.925 4.77415L15 4.99983L14.9213 5.22551C13.7447 8.14205 10.7806 10.0486 7.5 9.99906ZM7.5 1.42895C4.9469 1.35277 2.60356 2.7681 1.58775 4.99983C2.6034 7.23169 4.94684 8.64709 7.5 8.57071C10.053 8.64669 12.3963 7.23142 13.4122 4.99983C12.3978 2.76701 10.0536 1.35116 7.5 1.42895ZM7.5 7.14235C6.41799 7.14918 5.48203 6.42629 5.26572 5.41674C5.04942 4.40719 5.6132 3.39295 6.61154 2.99561C7.60987 2.59827 8.76393 2.92881 9.36641 3.78466C9.96889 4.64051 9.85153 5.78262 9.08625 6.51102C8.66724 6.91472 8.09607 7.14204 7.5 7.14235Z"
                            fill="#828282"/>
                    </svg>
                    :
                    <svg width="15" height="10" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.4775 13.6L10.9605 11.2267C9.87459 11.6929 8.69309 11.9278 7.5 11.9148C6.26991 11.9292 5.05253 11.6789 3.9405 11.1829C3.07851 10.7863 2.30448 10.2386 1.65975 9.56908C0.975369 8.87736 0.437473 8.0687 0.075 7.1866L0 6.96455L0.07875 6.74108C0.620858 5.43726 1.53319 4.29932 2.71575 3.45201L0.75 1.59851L1.80975 0.599976L14.5365 12.6L13.479 13.6H13.4775ZM3.777 4.45337C2.81844 5.08694 2.06149 5.95521 1.58775 6.96455C2.60356 9.17441 4.9469 10.5759 7.5 10.5004C8.28737 10.5065 9.07017 10.387 9.81525 10.1468L8.46525 8.87392C8.16475 9.01282 7.83466 9.08537 7.5 9.08608C6.26059 9.07874 5.25778 8.13319 5.25 6.96455C5.25036 6.64828 5.32732 6.33626 5.475 6.0523L3.777 4.45337ZM13.389 9.51745L12.345 8.53377C12.7842 8.06556 13.1442 7.53626 13.4122 6.96455C12.3978 4.75362 10.0536 3.35165 7.5 3.42868C7.31475 3.42868 7.12875 3.43504 6.94875 3.44706L5.625 2.19748C6.24122 2.07319 6.86984 2.01178 7.5 2.01433C8.73009 1.99993 9.94746 2.25025 11.0595 2.74625C11.9215 3.14279 12.6955 3.69047 13.3403 4.36003C14.0243 5.05091 14.5622 5.85858 14.925 6.73967L15 6.96455L14.9213 7.18802C14.5701 8.04941 14.0506 8.84101 13.3898 9.5217L13.389 9.51745Z"
                            fill="#828282"/>
                    </svg>
            }
        </>
    );
};

export default IconPassword;
